export const LANG_EN_NAME = 'en';
export const LANG_EN_TRANS = {
	'0030565001AE':'ELEMENTRA® ',
	'003056500368':'INTEGRA®',
	'003056500394':'ROTO-PACKER®',
	'003056500398':'ROTO-PACKER®',
	'0030565011A8':'ROTO-PACKER®',
	'0030565011AE':'ELEMENTRA®',
	'003056501200':'ROTO-PACKER®',
	'00305650121E':'INTEGRA®',
	'003056A98058':'ROTO-PACKER®',
	'057X1to2Rpm':'1X-2X Rpm',
	'057X2to3Rpm':'2.5X - 3X Rpm',
	'A000Key':'Key',
	'A001Country':'English',
	'A002German':'Deutsch',
	'A003English':'English',
	'A004Username':'Email',
	'A005Login':'Login to your account',
	'A006Password':'Password',
	'A007RemPassword':'Keep me signed in',
	'A008Login':'Login',
	'A009Logout':'Logout',
	'A010Production':'Production rate',
	'A011Availability':'Availability',
	'A012Maintenance':'Machine maintenance',
	'A013RemainingUnits':'Remaining units',
	'A014ProductionTime':'Total time',
	'A015ProductionTodayGeneral':'Production today',
	'A015ProductionToday':'Production today',
	'A016Units':'Units',
	'A017RemProduction':'Total production',
	'A018LastReset':'Last reset',
	'A019Faults':'Interruptions today',
	'A019FaultsBatch':'Interruptions',
	'A020Count':'Count',
	'A021FaultDuration':'Fault duration today',
	'A022Minutes':'min',
	'A023BagCounterPerFS':'Bag counter today ',
	'A023BagCounterPerFSBatch':'Bag counter',
	'A024FillingStation':'Filling station',
	'A025FSDifference':'Spread bag counter',
	'A026AtLeast1Caps':'at least 1 capital letter',
	'A026FSworstspout':'and worst spout',
	'A027Percent':'Percent',
	'A028RejectionsToday':'Rejects ',
	'A028RejectionsTodayBatch':'Rejects',
	'A029FilterStop':'Filter plant',
	'A030RotaryDriveStop':'ROTO-PACKER®',
	'A031ProductFeedStop':'No product',
	'A032RadimatStop':'RADIMAT®',
	'A033PalletizerStop':'Foll. belt plant ',
	'A034CompAirFault':'Compressed air',
	'A035TypeChange':'Type changes',
	'A035TypeChangeBatch':'Type changes',
	'A036ProductionRate':'Speed related production',
	'A037SpeedRelated':'Bags / hour',
	'A038UnitsPerHour':'Units / hour',
	'A039GoodWeights':'Good production',
	'A040ScissorHose':'Scissor hose',
	'A041ConnectingHose':'Connecting hose',
	'A042ConeGasket':'Cone gasket',
	'A043AerationPlates':'Aearation plates',
	'A043ScissorHose':'Scissor hose',
	'A044ConnectingHose':'Connecting hose',
	'A044RegulationValve':'Regulation valve conveying air',
	'A045ConeFeeding':'Cone product feeding',
	'A045ConeGasket':'Cone gasket',
	'A046AerationPlates':'Aearation plates',
	'A046BagTransBelt':'Ribbed belt bag transport',
	'A047BagTransBearing':'Insert bearing bag transport',
	'A047RegulationValve':'Regulation valve ',
	'A048ConeFeeding':'Cone product feeding',
	'A048SuctionCup':'Suction cup',
	'A049BagTransBelt':'Bag transport belt',
	'A049BagTransCyl':'Cylinder bag transport',
	'A050BagTransBearing':'Insert bearing ',
	'A050SucRailCyl':'Cylinder suction rail',
	'A051SuctionCup':'Suction cup',
	'A051VacuumPump':'Vacuum pump /-generator',
	'A052BagTransCyl':'Cylinder bag transport',
	'A052BagTransCylinde':'Cylinder bag transport',
	'A052CableHightAdj':'Cable height adjustment',
	'A053HullMinSensor':'Hull MIN-Sensor',
	'A053SucRailCyl':'Cylinder suction rail',
	'A053SucRailCylinder':'Cylinder suction rail',
	'A054Last48Hours':'Last 48 hours',
	'A054VacuumPump':'Vacuum pump',
	'A055CableHightAdj':'Dryer compressed air',
	'A055Last14Days':'Last 14 days',
	'A056HullMinSensor':'Pisten blower',
	'A056off':'off',
	'A057InOperation':'In operation',
	'A058Faulty':'Faulty',
	'A059InMaintenance':'In maintenance',
	'A060HourlyValues':'Hourly rates for',
	'A061DischargesTotal':'Total discharges',
	'A062CreationDate':'Creation date',
	'A062Period':'Period',
	'A063To':'to',
	'A064BatchValue':'Sort values',
	'A064DailyValue':'Daily values',
	'A064DailyValues':'Daily values for',
	'A065supply':'Supply',
	'A066Transport':'Transport',
	'A067Feeding':'Feeding',
	'A068PackagesMissing':'Packages missing',
	'A069NoHistoricalData':'No historical data for the period between ',
	'A070And':'and',
	'A071Available':'available',
	'A072ReallyReset':' Really reset?',
	'A073Details':'Details',
	'A074General':'General',
	'A075Machines':'Machines',
	'A076Dashborad':'Dashboard',
	'A077Administration':'Administration',
	'A078UserRoles':'Users & Roles',
	'A079Companies':'Companies',
	'A080Machines':'Machines (dev)',
	'A081Date':'Date',
	'A082Transport':'Transport',
	'A083Containermissing':'Container is missing',
	'A084Availability':'Availability',
	'A085TypeChanges':'Type changes today',
	'A086EmptyRejects':'Empty bag rejects today',
	'A086EmptyRejectsBatch':'Empty bag rejects',
	'A087FullRejects':'Full bags rejects today',
	'A087FullRejectsBatch':'Full bags rejects',
	'A088OpRelease':'Starting release',
	'A089ProdFeedStop':'Product feed STOP',
	'A090AuxDrive':'Auxiliary drive',
	'A091Consumables':'Consumables',
	'A092DischLineStop':'Discharge line STOP',
	'A093TypeChange':'Type changeover',
	'A094DailyProduction':'Production today',
	'A094DailyProductionBatch':'Production',
	'A095Energy':'Energy',
	'A096EffectivePower':'Effective power',
	'A097ApparentPower':'Apparent power',
	'A098CompAirFlow':'Compressed air flow rate',
	'A099ResetFailed':'Failed to reset metric.',
	'A1000Spanish':'Español',
	'A1000UploadMaintenanceAid':'Upload maintenance aids',
	'A1001UploadMaintenanceAidSuccess':'Maintenance aids uploaded successfully.',
	'A1002UploadMaintenanceAidFailure':'Error in uploading maintenance aids.',
	'A1005MachineServiceRequest':'Service request',
	'A1006RequestService':'Request for service',
	'A1007ServiceContract':'service contract',
	'A1008ServiceRequestSuccesful':'Service request processed successfully.',
	'A1009ServiceRequestFailure':'Service request failed',
	'A100AcessDenied':'Access denied. ',
	'A100AlertmailSubject':'QSI Alert: MACHINE_NAME',
	'A1010LogBook':'Logbook',
	'A1011CreateLogBook':'Create log book item',
	'A1012EditLogBook':'Edit log book item',
	'A1013LogBookItemType':'Log book item type',
	'A1014LogBookItem':'Log book item',
	'A1015LogBookItemSaved':'Log book item saved',
	'A1016ErrorInSavingLogBookItem':'Error in saving log book item',
	'A1017ErrorInRetrievingLogBookItem':'Error while retrieving Log book',
	'A1018ErrorInDeletingLogBookItem':'Error while deleting log book item',
	'A1019LogBookItemDeleted':'Log book item deleted',
	'A101MailSalutation':'Hello USER_NAME,',
	'A101ResetSuccessful':'Metric has been reset',
	'A1020LogBookItemIsDone':'Done',
	'A1021LogBookItemType':'Item type',
	'A1022ServiceEmail':'Service email',
	'A1023CannotMakeServieRequest':'You cannot request for service as this machine does not have any service email',
	'A1024MaxUsers':'Maximum Users',
	'A1024MaxUsersReached':'The maximum user limit of the company has reached.',
	'A1025AtLeast8Characters':'at least 8 characters',
	'A1027AtLeast1Lowercase':'at least 1 lower case letter',
	'A1028AtLeast1Number':'at least 1 number (0-9)',
	'A1029AtLeast1SpecialChar':'at least 1 special character (!,.[])',
	'A102CompanyAddressNewtec':'NEWTEC BAG PALLETIZING <br>32 avenue de Suisee <br>ZI Ile Napoléon - BP 256 <br>68315 Illzach cedex - France',
	'A102CompanyAddressAventus':'AVENTUS GmbH & Co. KG <br>Katzheide 33 <br>D-48231 Warendorf <br>Germany / Deutschland',
	'A102CompanyAddressNiagaraCanada':'HAVER & BOECKER Niagara Canada <br>225 Ontario St. <br>St. Catharines, ON, L2R 7B6 <br>Canada',
	'A102CompanyAddressNiagaraChile':'HAVER & BOECKER Andina <br>Av. El salto 4001, piso 7, of. 71 Comuna Huechuraba <br>8580641 – Santiago <br>Chile',
	'A102CompanyAddressNiagaraBrazil':'HAVER & BOECKER NIAGARA Latinoamericana <br>Av. Dra. Celia Marli S. Salgado Matos, 192 <br>Pedro Leopoldo - MG - 33600-000 <br>Brazil',
	'A102CompanyAddressNiagara':'HAVER & BOECKER NIAGARA <br>Robert-Bosch-Straße 6 <br>D-48153 Münster <br>Germany / Deutschland',
	'A102CompanyAddressFeige':'Feige FILLING GmbH <br> Rogen 6a <br> D-23843 Bad Oldesloe <br> Germany / Deutschland',
	'A102CompanyAddressH_B':'HAVER & BOECKER OHG <br>Carl-Haver-Platz 3 <br>D-59302 Oelde <br>Germany / Deutschland',
	'A102CompanyAddressBehnBates':'Behn Bates Maschinenfabrik GmbH & Co. KG <br> Robert-Bosch Str. 6 <br> 48153 Münster <br> Germany',
	'A102CompanyEmailAventus':'hotline@aventus.global',
	'A102CompanyEmailNiagaraCanada':'info@haverniagara.ca',
	'A102CompanyEmailNiagaraChile':'haverandina@haverniagara.cl',
	'A102CompanyEmailNiagaraBrazil':'info@haverniagara.com.br',
	'A102CompanyEmailNiagara':'info@haverniagara.com',
	'A102CompanyEmailNewtec':'service@newtecbag.com',
	'A102CompanyEmailFeige':'hotline@feige.com',
	'A102CompanyEmailH_B':'hotline@haverboecker.com',
	'A102CompanyEmailBehnBates':'service@behnbates.com',
	'A102CompanyPhoneNumberNewtec':'Phone: +33 389633753',
	'A102CompanyPhoneNumberFeige':'Phone: +49 4531 89 09 222',
	'A102CompanyPhoneNumberAventus':'Phone: +49 2581 4591 2222',
	'A102CompanyPhoneNumberNiagaraCanada':'Phone: +1 800-325-5993',
	'A102CompanyPhoneNumberNiagaraChile':'Telefon: +56 (2) 2307-0440',
	'A102CompanyPhoneNumberNiagaraBrazil':'Telefon: +55 (31) 3661-1371',
	'A102CompanyPhoneNumberNiagara':'Phone: +49 251 9793 164',
	'A102CompanyPhoneNumberH_B':'Phone: +49 2522 30 371 ',
	'A102CompanyPhoneNumberBehnBates':'Phone: +49 251 979- 0',
	'A102CompanyWebsiteNewtec':'www.newtecbag.com',
	'A102CompanyWebsiteFeige':'www.feige.com',
	'A102CompanyWebsiteAventus':'www.aventus.global',
	'A102CompanyWebsiteNiagaraCanada':'https://haverniagara.com',
	'A102CompanyWebsiteNiagaraChile':'https://haverniagara.com',
	'A102CompanyWebsiteNiagaraBrazil':'www.brazilniagara.com',
	'A102CompanyWebsiteNiagara':'www.haverniagara.com',
	'A102CompanyWebsiteH_B':'www.haverboecker.com',
	'A102CompanyWebsiteBehnBates':'www.behnbates.com',
	'A102MailContent':'<p>There occured an alert in one of your machines: <br> <br>Machine name: <b>MACHINE_NAME</b> <br>Alert message: <b>ALERT_TYPE</b> <br> Machine link:  <a href=\"MACHINE_LINK_TO_DETAIL\">MACHINE_LINK</a> <br> Alert value: ALERT_VALUE_TEXT <em>ALERT_VALUE_SUMMARY  </em><br> Alert time: ALERT_TIME_TEXT<br>ALERT_TIP</p><p style=\'font-size: 12px;\'>This e-mails was generated automatically because of a subscription at <a href=\'TYP_WEBSITE\' style=\'text-decoration: none; font-color: blue;\'>TYP_WEBSITE</a>. To unsubscribe login on the page and select <b>Settings</b> below the menu <b>Alerting</b>. <br>Please do not reply to this mail. For questions please contact <a href=\"mailto:COMPANY_EMAIL\">COMPANY_EMAIL</a>. <br> <br></p><p style=\'font-size: 12px;\'>Diese E-Mail wurde aufgrund eines Abos automatisch von <a href=\'TYP_WEBSITE\' style=\'text-decoration: none; font-color: blue;\'>TYP_WEBSITE</a> generiert. Zum Beenden des Abos loggen Sie sich auf der Seite ein und klicken unter <b>Alarmmeldungen</b> auf den Menüpunkt <b>Einstellungen</b>. <br>Bitte Antworten Sie nicht auf diese Mail. Bei Fragen können Sie sich an <a href=\"mailto:COMPANY_EMAIL\">COMPANY_EMAIL</a> wenden. <br> <br></p><p> Best regards, <br> <br>COMPANY_ADDRESS <br> <br>COMPANY_PHONE_NUMBER <br>E-Mail: <a href=\"mailto:COMPANY_EMAIL\">COMPANY_EMAIL</a> <br>Internet: <a href=\'COMPANY_WEBSITE\'>COMPANY_WEBSITE</a> <br><small style=\"color:white;\">Alert Source Id: ALERT_SOURCE_ID</small></p>',
	'A102TypeChanges':'Product Changes Today',
	'A102TypeChangesBatch':'Product Changes',
	'A102TYPWebsite':'https://trackyourplant.com',
	'A1030Description':'Description',
	'A103EndOfBatch':'End of batch',
	'A103ResetmailSubject':'Reset : MACHINE_NAME',
	'A104FullPalletCount':'Full pallets',
	'A104ResetMailContent':'<p>The occurred alert is resolved: </p><p>Machine name: <b>MACHINE_NAME</b><br>Alert message: <b>ALERT_TYPE</b><br>Machine link: MACHINE_LINK<br><p style=\'font-size: 12px;\'>This e-mails was generated automatically because of a subscription at <a href=\'TYP_WEBSITE\' style=\'text-decoration: none; font-color: blue;\'>TYP_WEBSITE</a>. To unsubscribe login on the page and select <b>Settings</b> below the menu <b>Alerting</b><br>Please do not reply to this mail. For questions please contact COMPANY_EMAIL.</p><p style=\'font-size: 12px;\'>Diese E-Mail wurde aufgrund eines Abos automatisch von <a href=\'TYP_WEBSITE\' style=\'text-decoration: none; font-color: blue;\'>TYP_WEBSITE</a> generiert. Zum Beenden des Abos loggen Sie sich auf der Seite ein und klicken unter Alarmmeldungen auf den Menüpunkt Einstellungen.<br>Bitte Antworten Sie nicht auf diese Mail. Bei Fragen können Sie sich an COMPANY_EMAIL wenden.</p><p>Best regards,<br>COMPANY_ADDRESS<br></p><p>COMPANY_PHONE_NUMBER<br>E-Mail: COMPANY_EMAIL<br>Internet: COMPANY_WEBSITE <br></p><small style=\"color:white;\">Alert Source Id: ALERT_SOURCE_ID</small>',
	'A105ConnectivityMailSubject':'Connection Error : MACHINE_NAME',
	'A105LoadEmptyPallets':'Feed of empty pallets',
	'A106Fillings':'Fillings',
	'A106PLCMailSubject':'PLC Alert : MACHINE_NAME',
	'A107FillingsPerHour':'Fillings / hour',
	'A107PLCResolvedMailContent':'<p>The following alert was resolved  at machine MACHINE_NAME:.<br><br> ALERT_TYPE <br><br> MACHINE_LINK <br><br> This e-mails was generated automaticly because of a subscription at <a href=\'TYP_WEBSITE\' style=\'text-decoration: none; font-color: blue;\'>TYP_WEBSITE</a>. To unsubscribe login on the page and select <b>Settings</b> below the menu <b>Alerting</b>.<br><p>Please do not reply to this mail. For questions please contact COMPANY_EMAIL.</p><br><br>Best regards,<br><br>COMPANY_ADDRESS<br><br>COMPANY_PHONE_NUMBER<br>E-Mail: COMPANY_EMAIL<br>Internet: COMPANY_WEBSITE ',
	'A108Availability':'Availability',
	'A108AvailabilityMailSubject':'Availability Alert : MACHINE_NAME',
	'A109FillingsRemain':'Fillings remaining',
	'A110StartOfOrder':'Batch starting time',
	'A111ProductID':'Product ID',
	'A112TargetWeight':'Target weight',
	'A113Kg':'Kilogrammes',
	'A114CurrentOrder':'Current order',
	'A115FillingCount':'Actual batch',
	'A116CycleTime':'Cycle times machine',
	'A117Total':'Total',
	'A118CentreStation':'Centering station',
	'A119N2Station':'N2 station',
	'A120FillingStation':'Filling station',
	'A121SealingStation':'Closing station',
	'A122Seconds':'Seconds',
	'A123FaultLength':'Fault machine',
	'A124Minutes':'Minutes',
	'A124Minute':'Minutes',
	'A125ExtFaultLength':'Faults external',
	'A126FillingCount':'Total number of fillings',
	'A127WithinTolerance':'Fillings within tolerance',
	'A128OutsideTolerance':'Fillings out of tolerance',
	'A129BagManufacture':'Good production',
	'A130BagsPerHour':'Bags / hour',
	'A131BagQuality':'Bag quality',
	'A132CorrPlacedbags':'Correctly placed bags',
	'A133TwoAttempts':'2nd attempt to open',
	'A134ThreeAttempts':'3rd attempt to open',
	'A135DiscardeBags':'Discarded bags',
	'A136Faults':'Interruptions',
	'A137CompAir':'Compressed air',
	'A138ProductFeed':'Product feed (weigher)',
	'A139EmptyBagMag':'Empty-bag magazine',
	'A140Separation':'Separation',
	'A141Vacuum':'Vacuum',
	'A142FillingStation':'Filling station',
	'A143FullBagTransport':'Full bag transport',
	'A144BagSealing':'Bag sealing',
	'A145Disrupt':'Disruptions',
	'A145AccessDoor':'Access Door',
	'A145DSafetyLightBarrier':'Safety Light Barrier',
	'A145UpstreamSupply':'Upstream Supply',
	'A145EmergencyStop':'Emergency stop',
	'A146BunChangeAbs':'Number of bundle changes total',
	'A147BunChangeToday':'Number of bundle changes today',
	'A147BunChangeTodayBatch':'Number of bundle changes',
	'A148BagsPerBundle':'Number of bags per bundle',
	'A149TypeChanges':'Type changes today',
	'A149TypeChangesBatch':'Type changes',
	'A150FillingTime':'Filling time',
	'A151DosingUnit1':'Product feed',
	'A152DosingUnit2':'Product feed 2',
	'A153AerInletBox1':'Aeration inlet box',
	'A154AerInletBox2':'Aeration inlet box 2',
	'A154DosingUnit1':'Product feed',
	'A155DosingUnit2':'Product feed 2',
	'A155RotaryDrive':'Rotary drive ',
	'A156AerInletBox1':'Aeration inlet box',
	'A156DischargeBelt':'Discharge belt',
	'A157AerInletBox2':'Aeration inlet box 2',
	'A157AlignmentBelt':'Bag alignment belt',
	'A158BagCleaning':'Bag cleaning station',
	'A158RotaryDrive':'Rotary drive ',
	'A159AccelerationBelt':'Acceleration belt',
	'A159DischargeBelt':'Discharge belt',
	'A160AlignmentBelt':'Bag alignment belt',
	'A160CheckBelt':'Checkweigher belt',
	'A161BagCleaning':'Bag cleaning station',
	'A161FlatBelt':'Flat belt conveyor',
	'A162AccelerationBelt':'Acceleration belt',
	'A162RejectionBelt':'Rejection belt',
	'A163CheckBelt':'Checkweigher belt',
	'A163RejectionDrive':'Hoisting drive rejector',
	'A164FlatBelt':'Flat belt conveyor',
	'A164RotaryCutter':'Bag destroyer',
	'A165RejectionBelt':'Rejection belt',
	'A165ScreenDrum':'Screen drum',
	'A166RejectionDrive':'Hoisting drive rejector',
	'A166Welding':'Welding device',
	'A167Cutter':'Cutter',
	'A167RotaryCutter':'Bag destroyer',
	'A168ScreenDrum':'Screen drum',
	'A168SuctionCup':'Suction cups',
	'A169Brakes':'Brakes',
	'A170VacuumPump':'Vacuum pump',
	'A171Filter':'Filter extraction',
	'A172BagDisConveyor':'Bag distribution conv.',
	'A173BagTurnConveyor':'Bag turning conveyor',
	'A174BagTurnCross':'Bag turning cross',
	'A175BagDisConveyor':'Bag distribution conv.',
	'A175BagTurnClamp':'Bag turning clamp',
	'A176BagTurnConveyor':'Bag turning conveyor',
	'A176GroupingBelt':'Grouping belt',
	'A177BagTurnCross':'Bag turning cross',
	'A177RowPusher':'Row pusher',
	'A178DoubleStripPlate':'Double stripper plate',
	'A179ConformingPlate':'Conforming plate',
	'A179GroupingBelt':'Grouping belt',
	'A180GantrySafety':'Gantry safety',
	'A180RowPusher':'Row pusher',
	'A181DoubleStripPlate':'Double stripper plate',
	'A181Gantry':'Frame',
	'A182ConformingPlate':'Conforming plate',
	'A182PalletAlignment':'Empty pallet alignment',
	'A183GantrySafety':'Frame safety',
	'A183PalletRoller1':'Pallet roller conveyor',
	'A184Gantry':'Gantry',
	'A184PalletDispenser':'Pallet dispenser',
	'A185PalletAlignment':'Empty pallet alignment',
	'A185PalletRoller2':'Pallet roller conveyor 2',
	'A186PalletRoller1':'Pallet roller conveyor 1',
	'A186PalletRoller3':'Pallet roller conveyor 3',
	'A187CyclesChain':'Cycles chain',
	'A187PalletDispenser':'Pallet dispenser',
	'A188BundlePusher':'Cylinder bundle pusher',
	'A188PalletRoller2':'Pallet roller conveyor',
	'A189BundlePartition':'Cylinder bundle partition',
	'A189PalletRoller3':'Pallet roller conveyor',
	'A190BagLifter':'Cylinder bag lifter',
	'A190CyclesChain':'Cycles chain',
	'A191BundlePusher':'Cylinder bundle pusher',
	'A191PressRolls':'Cylinder press rolls',
	'A192BundlePartition':'Cylinder bundle partition',
	'A192OpeningStation':'Cylinder opening station',
	'A193BagApplicator':'Cylinder bag applicator',
	'A193BagLifter':'Cylinder bag lifter',
	'A194BagSupport':'Cylinder bag support',
	'A194PressRolls':'Cylinder press rolls',
	'A195OpeningStation':'Cylinder opening station',
	'A195Pusher':'Cylinder pusher',
	'A196BagApplicator':'Cylinder bag applicator',
	'A196StretchPliers':'Cylinder stretch pliers',
	'A197BagSupport':'Cylinder bag support',
	'A197FillingStation':'Cylinder filling station',
	'A198FilledBagGripper':'Cylinder filled bag gripper',
	'A198Pusher':'Cylinder pusher',
	'A199StretchPliers':'Cylinder stretch pliers',
	'A199SucBagSeperation':'Suction cup bag separation',
	'A200FillingStation':'Cylinder filling station',
	'A200SucOpenStation':'Suction cup opening station',
	'A201FilledBagGripper':'Cylinder filled bag gripper',
	'A201Remaininghours':'Remaining hours',
	'A202PriceTotal':'Piece in total',
	'A202SucBagSeperation':'Suction cup bag separation',
	'A203RemainingNumberFillings':'Remaining fillings',
	'A203SucOpenStation':'Suction cup opening station',
	'A204SafetyDoor':'Safety switch door',
	'A205SafetyLightBarrier':'Safety light curtain',
	'A206BagTransport':'Bag transport',
	'A207EmptyPalletTrans':'Empty pallet transport',
	'A208Palletizer':'Palletizer',
	'A209FullPalletTrans':'Full pallet transport',
	'A210NumberTodayPiece':'Number today / piece',
	'A211Programme':'Program',
	'A212Efficiency':'Efficiency',
	'A213Loginsuccessful':'Login successful',
	'A214CurrentPerformance':'Current performance',
	'A215CyclesToday':'Total cycles today',
	'A215CyclesTodayBatch':'Total cycles',
	'A216RejectsToday':'Rejects today',
	'A216RejectsTodayBatch':'Rejects',
	'A217MetalDetectorToday':'Metal detector today',
	'A217MetalDetectorTodayBatch':'Metal detector',
	'A218BrokenBagsToday':'Broken bag detections today',
	'A218BrokenBagsTodayBatch':'Broken bag detections',
	'A219FaultPerShift':'Current shift',
	'A220FaultBagManufacture':'Bag manufacture',
	'A221FaultBagFilling':'Bag filling',
	'A222FaultBagTransport':'Bag transport',
	'A223FaultSealing':'Bag sealing',
	'A224FaultWeigher':'Filling station',
	'A225FaultPeriphery':'Other periphery',
	'A226Shift':'Shift',
	'A227Container':'Container',
	'A228Hours':'Hours',
	'A229NoPackage':'No container',
	'A230NoProduct':'No product',
	'A231NoOutlet':'No discharge',
	'A232GoodPerformance':'Production',
	'A233GoodWeights':'Production good bags',
	'A234NotPlacedBags':'Not placed bags',
	'A234NotPlacedBagsAutomatic':'Not placed bags automatic',
	'A235CollectiveFaultsToday':'Collective faults ',
	'A235CollectiveFaultsTodayBatch':'Collective faults',
	'A236Applicator':'Applicator',
	'A237NetWeigher':'Net weigher',
	'A238IncorrectWeight':'Incorrect weight',
	'A239Hours':'Hours',
	'A240QrCode':'QR Code',
	'A241OpenBags':'Open bags today',
	'A242FaultCheckWeigher':'Checkweigher',
	'A243DashboardAllMachines':'Dashboard / All machines',
	'A244DashboardState':'State',
	'A245DashboardMachineStateRunning':'Switched on',
	'A246DashboardMachineStateOffline':'Switched off',
	'A247DashboardMachineStateMaintenance':'Maintenance necessary',
	'A248DashboardMachineStateError':'Failure',
	'A249SortChangeToday':'Type changes today',
	'A249SortChangeTodayBatch':'Type changes',
	'A250EmptyBagMagazineToday':'Refills magazine',
	'A250EmptyBagMagazineTodayBatch':'Refills magazine',
	'A251FaultCheckWeigher':'Faults today',
	'A252IVTSTOP':'INTEGRA®',
	'A253N/A':'N/A',
	'A254CyclePumpFS1':'Cycle pump FS 1',
	'A255CyclePumpFS2':'Cycle pump FS 2',
	'A256CyclePumpFS3':'Cycle pump FS 3',
	'A257ConHoseFS1':'Connecting hose FS 1',
	'A258ConHoseFS2':'Connecting hose FS 2',
	'A259ConHoseFS3':'Connecting hose FS 3',
	'A260InfSleeveFS1':'Inflatable sleeve FS 1',
	'A261InfSleeveFS2':'Inflatable sleeve FS 2',
	'A262InfSleeveFS3':'Inflatable sleeve FS 3',
	'A263BundlePusher':'Bundle pusher',
	'A264VacuumUnit':'Vacuum unit',
	'A265TravellingDrive':'Travelling drive',
	'A266CenteringUnit':'Centring unit',
	'A267EmptyBagMagazine':'Empty bag magazine',
	'A268BeltPlant':'Belt plant',
	'A269IvtTurbine':'Filling turbine',
	'A270IvtTripSaddle':'Tripper saddle',
	'A271IvtCoarseFeed':'Dosing coarse feed',
	'A272IvtBagHolder':'Bag holder',
	'A273IvtFillBoxAir':'Filling box aeration lid',
	'A274IvtFillChanAir':'Filling channel aeration',
	'A275IvtInflSleeve':'Inflatable sleeve',
	'A276IvtInletBoxAir':'Aeration inlet box',
	'A277IvtFillTubeBlow':'Filling tube blow out',
	'A278IvtOutlChanAir':'Outlet channel',
	'A279IvtPusher':'Pusher',
	'A280IvtSaddleHeight':'Saddle height adjustment',
	'A281IvtCloseAnvil':'Close anvil',
	'A282IvtBagSeal':'Sealing device',
	'A283IvtFineFeedAdj':'Fine feed adjustment',
	'A284Interrupts':'Interruptions ',
	'A284InterruptsGeneralPage':'Interruptions ',
	'A284InterruptsBatch':'Interruptions',
	'A285BagCounterShift':'Bag counter per shift',
	'A286WrongBags':'Not placed bags',
	'A287ReelChanges':'Reel changes',
	'A287ReelChangesBatch':'Reel changes',
	'A288VibrationDuration':'Extended duration of vibration',
	'A289Total':'Total',
	'A290HopperMin':'by hopper minimum',
	'A291ManualActivation':'by manual activation',
	'A292FillingRelease':'Filling release',
	'A293EGF':'EGF®',
	'A294PalletTrasport':'Pallet transport',
	'A295SafetyCircuit':'Safety circuit',
	'A296FltCompAir':'Faults of compressed air today',
	'A296FltCompAirBatch':'Faults of compressed air',
	'A297StrokesPump':'Strokes powder pump today',
	'A297StrokesPumpBatch':'Strokes powder pump',
	'A298MaxStrokeDur':'Maximum stroke duration today',
	'A298MaxStrokeDurBatch':'Maximum stroke duration',
	'A299AvgStrokes':'Avg. strokes per bag today',
	'A299AvgStrokesBatch':'Avg. strokes per bag',
	'A300InternalPressure':'Internal pressure',
	'A301Max':'max',
	'A301MessageAdministration':'Message administration',
	'A302ErrorNumber':'Error number',
	'A302Millibar':'Millibar',
	'A303ActivateAlertMail':'Activate alert Email.',
	'A303SetValueMax':'Set maximum value',
	'A304AlertText':'Alert text',
	'A304SetValueMin':'Set minimum value',
	'A305ActivateResolvedMail':'Activate resolved Email',
	'A305SetValueChanged':'Your changes have been saved.',
	'A305SetValueChangedRefreshWindow':'Your changes have been saved. The changes will be visible after the refresh of the browser window.',
	'A306KW':'kW',
	'A306Resolved Text':'Resolved text',
	'A306ResolvedText':'Resolved text',
	'A307AddMessage':'Add message',
	'A307KVA':'kVA',
	'A308EditMessage':'Edit message',
	'A308Nm3PerH':'Nm³/h',
	'A309KWH':'kWh',
	'A310NM3':'Nm³',
	'A311CompAirVol':'Compressed air volume',
	'A312ActiveEnergy':'Active energy',
	'A313ReactivePower':'Reactive power',
	'A314ChartConfiguration':'Card configuration',
	'A315SetDateValueMax':'Set maximum date',
	'A316SetDateValueMin':'Set minimum date',
	'A317InvalidData':'Invalid data',
	'A318DeleteUser':'Do you really want to delete the user?',
	'A319EnterAllFields':'Please fill out all data fields!',
	'A320EnterValidEmail':'Please enter valid e-mail-address!',
	'A321DeleteCompany':'Do you really want to delete the company?',
	'A322CouldNotDeleteUser':'The admin-user could not be deleted! Please change to standard-user first.',
	'A323LoginSuccess':'Login successful!',
	'A324Selected':'selected',
	'A325Total':'total',
	'A326Reset':'Reset',
	'A327Reporting':'Reporting',
	'A328ReportingActive':'Active',
	'A329ReportingActiveHelpPrefix':'Activating will send regular reports to',
	'A330ReportingActiveHelpSuffix':'sent.',
	'A331ReportingFrequency':'Frequency',
	'A332ReportingFrequencyDaily':'daily',
	'A333ReportingFrequencyWeekly':'weekly',
	'A334ReportingFrequencyMonthly':'monthly',
	'A335ReportingDynContent':'Dynamic content',
	'A336ReportingDynContIncludeElapsedMI':'elapsed maintenance intervals',
	'A337ReportingLanguage':'Language',
	'A338ReportingProdDayEnd':'Production day end',
	'A339ReportingFirstReport':'Date of first report',
	'A340CardCtxMenuReport':'Include in report',
	'A341CardCtxMenuHistoReport':'Include as histogram in report',
	'A342CardCtxMenuReset':'Reset counter',
	'A343CardCtxMenuSettings':'Settings',
	'A344CardLabelReport':'Report',
	'A345CardLabelHistoReport':'Histogram Report',
	'A346CardLabelMaintenReport':'Maintenance Report',
	'A347ReportSettings':'Settings',
	'A348Alerting':'Alerting',
	'A349AlertingSettings':'Settings',
	'A350AlertingActive':'Active',
	'A351AlertingActiveHelpPrefix':'Activating will send alert e-mails to',
	'A352AlertingActiveHelpSuffix':'for any of the selected alert types below.',
	'A353AlertingTypes':'Notify on',
	'A354AlertTypeMainten':'Maintenance interval elapsed by:',
	'A355AlertTypeAvail':'Availability',
	'A356AlertTypePLC':'PLC trigger',
	'A357AlertTypeConError':'Connection error',
	'A358DeleteAdminUser':'The admin-user could not be deleted.',
	'A359MaintAids':'Maintenance aids',
	'A360MaintInt':'Maintenance intervals',
	'A361ChooseMaschine':'Choose machine',
	'A362Video':'Video',
	'A363InstructionManual':'Instruction manual',
	'A364ExplodedviewDrawing':'Exploded view',
	'A364SparePartCatalog':'Spare part catalog',
	'A365OpenVideoHelp':'Open video help',
	'A366OpenInstructionManual':'Open instruction manual',
	'A367OpenExploadedView':'Open exploded view',
	'A368ResetMaschineData':'Reset maschine data',
	'A369BotWeld':'Bottom seam welding ',
	'A370BotWeldTef':'Teflon bottom seal welding ',
	'A371TopWeld':'Top seam welding ',
	'A372TopWeldTef':'Teflon seam welding ',
	'A373LeftWeld':'Corner welding left ',
	'A374LefWeldTef':'Teflon corner welding left ',
	'A375RigWeld':'Corner welding system right ',
	'A376RigWeldTef':'Teflon corner welding right ',
	'A377CutKnife':'Cutting knife ',
	'A378ReallyClearReport':'This action removes all items from the report and cannot be undone. Do you really like to remove all items from the report?',
	'A379ProdCurrShift':'Production current shift',
	'A380ReallyDeleteItemReport':'This action removes the item from the report and cannot be undone. Do you really like to remove the item from the report?',
	'A380TypeCurrent':'Current type',
	'A381SampleBags':'Rejected sample bags',
	'A382OpenBags':'Rejected open bags',
	'A383IncorrectBags':'Rejected incorrect weights',
	'A384NotPlacedBagsMan':'Not placed bags manual',
	'A385Spout1':'Maintenance filling spout 1',
	'A386LastUpdate':'Last updated:',
	'A386Spout2':'Maintenance filling spout 2',
	'A387Spout3':'Maintenance filling spout 3',
	'A388Spout4':'Maintenance filling spout 4',
	'A389LastUpdate':'Last updated:',
	'A390FilledBagTrans':'Filled bag transport',
	'A391ConveyerSystem':'Discharge belts',
	'A392BeltBundleTrans':'Belt bundle transport',
	'A393BagHolderBuffer':'Bag holder buffer',
	'A394SlidePlates':'Slide plates',
	'A395ImpellerShaft':'Impeller shaft assembly',
	'A396ShaftAssembly':'Lower shaft assembly',
	'A397ThreePosCylinder':'Three-position cylinder',
	'A398DateFormatNoSeconds':'MM-DD-YYYY h:mm a',
	'A399DateFormat':'MM-DD-YYYY h:mm:ss a',
	'A399DateFormatAngularPipe':'MM-dd-yyyy h:mm:ss a',
	'A400ConveyorSystem':'Conveyor system',
	'A400History':'History',
	'A401ReportHistory':'Report history',
	'A402ReportId':'Report ID',
	'A403ReportGeneratedTime':'Report generated time',
	'A404DosingFeeder':'Dosing Feeder',
	'A404ReportGeneratedBy':'Report generated by',
	'A405DownloadReport':'Download report',
	'A406TriggeredHistoryReport':'Report generation started',
	'A407ViewReport':'View report',
	'A408BagHolder':'Bag holder',
	'A408BagHolder ':'Bag holder',
	'A414BundleTransport':'Bundle transport',
	'A415HoistingDevice':'Hoisting drive',
	'A416PlacerArm':'Placer Arm',
	'A417CrossProcess':'Cross process',
	'A418OpeningStation':'Opening station',
	'A421Claw':'Claw',
	'A422WeightData':'Mean value ',
	'A423StdDevData':'Standard deviation',
	'A430kg':'kg',
	'A431lb':'lb',
	'A432t':'t',
	'A433klb':'klb',
	'A434g':'g',
	'A435BagManufacture':'Empty bag manufacture',
	'A436Aux_Pump':'Vacuum pump',
	'A437TopWelding':'Top seam welding',
	'A438PunchingDeviceWelding':'Welding handle hole punch',
	'A439Aux_Vibrator1':'Vibrator 1',
	'A440Aux_Vibrator2':'Vibrator 2',
	'A441Aux_Vibrator3':'Vibrator 3',
	'A442Truck_Loading':'Truck loading',
	'A443Truck_Available':'No truck available today',
	'A444FS1':'Filling Station 1',
	'A445FS2':'Filling Station 2',
	'A446FS3':'Filling Station 3',
	'A447FS4':'Filling Station 4',
	'A448FS5':'Filling Station 5',
	'A449FS6':'Filling Station 6',
	'A450FS7':'Filling Station 7',
	'A451FS8':'Filling Station 8',
	'A452FS9':'Filling Station 9',
	'A453FS10':'Filling Station 10',
	'A454FS11':'Filling Station 11',
	'A455FS12':'Filling Station 12',
	'A456FS13':'Filling Station 13',
	'A457FS14':'Filling Station 14',
	'A458FS15':'Filling Station 15',
	'A459FS16':'Filling Station 16',
	'A460OeeValue':'OEE value',
	'A460Oee':'OEE',
	'A460PushAlert':'<h1>Alert: ALERT_TYPE</h1><p>Machine name: <b>MACHINE_NAME</b> <br>Alert message: <b>ALERT_TYPE</b>',
	'A461OeeAvailable':'Availability factor',
	'A461PushAlertResolved':'<h1>Resolved: ALERT_TYPE</h1><p>Machine name: <b>MACHINE_NAME</b> <br>Alert message: <b>ALERT_TYPE</b>',
	'A462OeePerformance':'Performance factor',
	'A463DowntimePlaned':'Planned downtimes',
	'A464DowntimeMachine':'Machine downtimes',
	'A465DowntimeOther':'Other downtimes',
	'A466OperatingFactor':'Operating factor',
	'A467Throughput':'Throughput factor',
	'A468OeeRejects':'Rejects today',
	'A469OeeQuality':'Quality factor',
	'A470OeeDowntime':'Downtimes',
	'A471RejectsTotal':'Rejcets total',
	'A472RejectsIncorrect':'Rejects wrong weight',
	'A473RejectsOpen':'Rejects open bag',
	'A474RejectsSample':'Rejects sample bag',
	'A475RejectsMetal':'Rejects metal detection ',
	'A476ConfigTable':'Configuration',
	'A477OEERowActive':'Active',
	'A478OEEProduction':'Production',
	'A479OEEBreak':'Break',
	'A480OEEMaint':'Maintenance',
	'A481OEETypeChg':'Type Change',
	'A482OEEOthers':'Other downtimes',
	'A483OEEFrequ':'Frequency',
	'A484OEEUnique':'Unique ',
	'A485OEEDaily':'Daily',
	'A486OEEWeekly':'Weekly',
	'A487Days':'Days',
	'A488Monday':'Monday',
	'A489Tuesday':'Tuesday',
	'A490Wednesday':'Wednesday',
	'A491Thursday':'Thursday',
	'A492Friday':'Friday',
	'A494Saturday':'Saturday',
	'A498Comment':'Comment',
	'A495Sunday':'Sunday',
	'A496From':'From',
	'A497To':'To',
	'A499TabMachineMessages':'Machine message',
	'A500MMLive':'Messages live',
	'A500VbeltDrive':'V-belt rotary drive',
	'A501MMStatistic':'Messages statistic',
	'A501VbeltTurbine':'V-belt turbine',
	'A502Impeller':'Impeller',
	'A502MMLive':'Machine messages live',
	'A503FillingTube':'Filling tube',
	'A503MMStatistic':'Machine messages statistic',
	'A504BagGuide':'Bag guide plate',
	'A504Type':'Type',
	'A505Control':'Control',
	'A505Saddle':'Grate saddle',
	'A506BoltBagChair':'Bolt bag chair',
	'A506Number':'Number',
	'A507BushBagChair':'Bush bag chair',
	'A507Message':'Message',
	'A508Amount':'Amount',
	'A508BearingPusher':'Bearing pusher',
	'A509Duration':'Duration in minutes ',
	'A509RearSlidePlate':'Rear slide plate',
	'A510SlideValve':'Slide plate',
	'A510TonsPerHour':'t/h',
	'A511FrontSlidePlate':'Front slide plate',
	'A511Tons':'tons',
	'A512RepairKitSlidePlates':'Repair kit slide plates',
	'A512RPM':'Rounds per minute ',
	'A513BagChairChange':'Bag chair change',
	'A513BagTransBeltServo':'Ribbed belt Servo',
	'A514InfeedBelt':'Infeed belt',
	'A515FlattBelt':'Flattener belt',
	'A516BagTakingBelt':'Bag Taking belt',
	'A517RollerConveyor':'Roller conveyor',
	'A518LiftingGantry':'Lifting gantry',
	'A519LinearDrive':'Linear drive',
	'A520SwivelJoint':'Swivel joint',
	'A521LiftingGantry':'Roller conveyor lifting gantry',
	'A522FullPallets':'Roller conveyor full pallets',
	'A523EvacConveyor':'Evacuation conveyor',
	'A524SafetyBarrier':'Safety barriers / sensors',
	'A525BasicCleaning':'Basic cleaning',
	'A526SafetyDevices':'Safety devices',
	'A530CylSucBar':'Cylinder suction bar close',
	'A531FilledBagGripper1':'Cylinder filled bag grippers 1',
	'A532FilledBagGripper2':'Cylinder filled bag grippers 2',
	'A533CylCutKnife':'Cylinder cutting knife',
	'A534CylBotWelding':'Cylinder  bottom welding system',
	'A535SpoutLift':'Spout lifting',
	'A536CylClampTopWeld':'Cylinder clamp bar top welding',
	'A537CylTopWeld':'Cylinder top welding system',
	'A538CylCornerWeld':'Cylinder corner welding',
	'A539AdjHeightDischargebelt':'Height adjustment discharge belt',
	'A540EmptyBagTransport':'Empty bag transport',
	'A541Virbator':'Operating hours vibrator',
	'A542CylCoolPlatesCornerWeld':'Cylinder cooler plates corner welding',
	'A543CylBagGuide':'Cylinder bag guiding plate',
	'A544CylClampBotWeld':'Cylinder clamp bar bottom  welding',
	'A545CylCoolBotWeld':'Cylinder contact cooling bottom seam',
	'A546CylFilledBagGripper3':'Cylinder filled bag grippers 3',
	'A547CylStatBagGripper':'Cylinder stationary bag grippers',
	'A548CylEmptyBagGripper1':'Cylinder empty bag gripper 1',
	'A549CylStretching':'Cylinder  stretching',
	'A550CylOpenSpout':'Cylinder filling spout open',
	'A551CylBagAplicatorLift':'Cylinder bag applicator lift',
	'A552CylStretcherOpen':'Cylinder stretcher pliers open',
	'A553CylStretcherInwards':'Cylinder stretcher pliers inwards',
	'A554FoilTrackingDevice':'Foil tracking device',
	'A555DischargeBelt2':'Operating hours discharge belt BP2',
	'A556CylStretching':'Cylinder stretching device',
	'A557CylEmptyBagGripper2':'Cylinder empty bag gripper 2',
	'A558UnwindingDevice':'Operating hours unwinding rollers',
	'A559BagLenghtAdj':'Bag length adjustment',
	'A560CylVibratorLift':'Vibrator lift',
	'A561CylFilmGuide':'Cylinder film guiding',
	'A562CylPushFlaps':'Cylinder push flaps',
	'A563CylWeldReelChanger':'Welding reel changer',
	'A564CylWeldReelChangerTef':'Teflon welding reel changer',
	'A565DriveReelChanger':'Drive reel changer',
	'A566DriveAdjCornerWeld':'Drive width adjustment corner welding',
	'A567CylThreadingInwards':'Cylinder threading device succers inwards',
	'A568CylThreadingOutwards':'Cylinder threading device limit succers outwards',
	'A569CylThreadingDownwards':'Cylinder threading device separating plate downwards',
	'A570CylSucRailClose':'Cylinder suction bar close',
	'A571DischargeBelt2':'Discharge belt BP2',
	'A572CylSpoutOpen':'Cylinder filling spout open',
	'A573Drives':'Drives',
	'A574Cylinders':'Cylinders',
	'A575LowAttrition':'Low wear and tear',
	'A576HighAttrition':'High wear and tear',
	'A577SetUpTimeAvBatch':'Setup time Ø',
	'A578SetUpTimeAv':'Setup time Ø today',
	'A579GrossTimeBatch':'Gross time ',
	'A580GrossTime':'Gross time today',
	'A581SetUpTime':'Setup time',
	'A582Downtime':'Downtime',
	'A583MonthBestPerf':'Monthly best performance',
	'A584PressureChamperSeal':'Seal pressure chamber',
	'A585Aeration':'Aeration',
	'A586SpillageRejector':'Spillage rejector',
	'A587VibrationBottle':'Vibrator bottle',
	'A588VibrationTable':'Vibrator table',
	'A589BagPlacing':'Bag placing',
	'A590RotaryFeeder':'Rotary feeder',
	'A591TopSeamCleaning':'Top seam cleaning',
	'A592Beltplant1':'Belt plant 1',
	'A593Beltplant2':'Belt plant 2',
	'A594HydraulikPump':'Hydraulic pump',
	'A595DriveEBTS':'Drive empty bag transfer',
	'A596HostingEBTS':'Hosting device empty bag transfer',
	'A597Ventilator':'Fan',
	'A598RD':'Rotary part',
	'A599SpoutCoarse':'Filling spout coarse feed',
	'A600RotaryGateCoarse':'Rotary gate coarse feed',
	'A601HostingVIC':'Hosting device inital compression',
	'A602SpoutFine':'Filling spout fine feed',
	'A603RotaryGateFine':'Rotary gate fine feed ',
	'A604HostingVSC1':'Hosting device 2 secondary compression',
	'A605HostingVSC2':'Hosting device 3 secondary compression',
	'A606FBTransfer':'Filled bag transfer',
	'A607FBTHost':'Host full bag transfer',
	'A608FBTswivel':'Swivel full bag transfer',
	'A609FBTClamp':'Clamp full bag transfer',
	'A610FBTransport':'Filled bag transport ',
	'A611FBTransportClamp':'Clamp filled bag transport ',
	'A612HostBFS':'Hosting device bag forming station ',
	'A613SwivelBFS':'Swivel bag forming station',
	'A614SideGussetblade':'Side gusset blade ',
	'A615HostTopwelding':'Hosting device top sealing ',
	'A616CloseTopWelding':'Close top welding ',
	'A617Folding1':'Folding 1',
	'A618Folding2':'Folding 2',
	'A619Folding3 ':'Folding 3',
	'A620PUD':'Punching device ',
	'A621PUDWeld':'Welding punching device ',
	'A622BeltReject':'Belt plant rejecting device ',
	'A623RejectingDevice':'Rejecting device ',
	'A624BagLenght':'Bag length',
	'A624PerfToday':'Performance today',
	'A625PerfTodayBatch':'Performance',
	'A626Output':'Output today',
	'A627OutputBatch':'Output',
	'A628UnitperH':'Bags/h',
	'A629Percent':'%',
	'A630BagJunction':'Bag junction plate',
	'A631ToothedBelt':'Tension toothed belt',
	'A632LevellingFoot':'Check levelling foot RAD',
	'A633PolymerGuide':'Polymer guide + roller',
	'A634BallBushing':'Ball bushing coupler',
	'A635ScissorHoseReplace':'Replace scissor hose',
	'A636ConnectingHoseReplace':'Replace connecting hose',
	'A637Bellow':'Bellow height adjustment',
	'A638TipperFrame':'Check tipper frame',
	'A639HosePressureChamber':'Check hose pressure chamber',
	'A640FlapFillingBox':'Check flap filling box',
	'A641RotoLock':'ROTO-LOCK®',
	'A642Hours':'Hours',
	'A643Temp':'°C',
	'A644Millisecond':'ms',
	'A645KgPerSecond':'kg/s',
	'A646RoundsperMinute':'rpm',
	'A647Meter':'m',
	'A648Millimeter':'mm',
	'A649Cubicmeter':'m³',
	'A650Liter':'l',
	'A651Counts':'PCS',
	'A652WeldingTemperature':'Welding temperature',
	'A653WeldingDuration':'Welding duration',
	'A654FillingSystem':'Filling system',
	'A655EmailSignature':'Email Signature',
	'A656NumberWasteBags':'Number of waste bags',
	'A657FreqConverter':'Frequency converter',
	'A658ServoDrives':'Servo drives',
	'A656Voltage':'Voltage',
	'A657Volt':'V',
	'A658U1':'U1',
	'A659U2':'U2',
	'A660U3':'U3',
	'A661U12':'U12',
	'A662U23':'U23',
	'A663U31':'U31',
	'A664Current':'Current',
	'A665Amps':'A',
	'A666I1':'I1',
	'A667I2':'I2',
	'A668I3':'I3',
	'A669Frequency':'Frequency',
	'A670Hertz':'Hz',
	'A671Pressure':'Pressure',
	'A672Bar':'bar',
	'A673Moisture':'Dew point ',
	'A674AirFlowUnit':'m³',
	'A675AnnualConsum':'Annual consumption',
	'A676MonthlyConsum':'Monthly consumption',
	'A677FreeConsum':'Consumption',
	'A678PowerEfficiency':'Power efficiency',
	'A679BagsPerKWH':'Bags per kWh',
	'A681SinceReset':'Since last reset',
	'A682LastTimeSortUse':'Since last time in use',
	'A683SortNumber':'Type number',
	'A684Overall':'Overall',
	'A685AirEfficiency':'Air efficiency',
	'A686BagsPerAirFlowUnit':'Bags per m³',
	'A687Jan':'January',
	'A688Feb':'February',
	'A689Mar':'March',
	'A690Apr':'April',
	'A691Mai':'May',
	'A692Jun':'June',
	'A693Jul':'July',
	'A694Aug':'August',
	'A695Sep':'September',
	'A696Oct':'October',
	'A697Nov':'November',
	'A698Dec':'December',
	'A699Year':'Year',
	'A700EnterDateRange':'Enter date range',
	'A701PowerConsumption':'Power consumption',
	'A702AirConsumption':'Air consumption',
	'A703FlowControlGate':'Flow Control Gate',
	'A704BagDirectionConv':'Bag Direction Conveyor',
	'A705BottomBearing':'Bottom Bearing',
	'A706PressureSwitch':'Pressure switch',
	'A707ElecModules':'Electronic modules',
	'A708SpoutWeight':'Components scale',
	'A709Search':'Search',
	'A710Overweight':'Reject overweight bags',
	'A711Underweight':'Reject underweight bags',
	'A712RotaryDriveFreqConLoad':'Freq.-converter rotary drive',
	'A713MeanValueLoadInPercent':'Mean value / Load in percent',
	'A714EmptyBagDischarge':'Empty bag discharge',
	'A715Bellow1':'Bellows seal',
	'A716TotalCounter':'Total counter',
	'A717Spout0Counter':'Fillingstation 0',
	'A718LSdirtyCounter':'light barrier CW dirty',
	'A719Peripherie':'periphery',
	'A720Robot':'robot ',
	'A721DepositBelt':'deposit belt',
	'A722RollerConveyor1':'roller conveyor 1',
	'A723PalletCentering1':'pallet centering 1',
	'A724RollerConveyor2':'roller conveyor 2',
	'A725PalletCentering2':'pallet centering 2',
	'A726GripperClamp':'clamp gripper',
	'A727GripperMotor':'motor gripper ',
	'A728AdjustmentCamera':'adjustment camera ',
	'A729BagSize':'bag size ',
	'A730PalletSupply':'pallet supply',
	'A731Length':'lenght',
	'A732Width':'width',
	'A733DepalPallets':'depalleted pallets',
	'A734DepalBundles':'depalleted bundles',
	'A735RejectedBundles':'rejected bundles',
	'A736BagsPerBundle':'bags per bundle',
	'A737CountsOfDataPoints':'counts of data points',
	'A738EmptyKey':' ',
	'A739TrendMeanWeight':'Mean value Trend',
	'A740TrendSD':'Standard deviation Trend',
	'A741PistonBlower1':'Piston blower 1',
	'A742PistonBlower2':'Piston blower 2',
	'A743RotaryFlap':'Rotary flap',
	'A744CurrentLayer':'Current layer',
	'A745CurrentBag':'Current bag',
	'A746Days':'Days',
	'A747LayerNo':'Layer No.',
	'A748BagNo':'Bag No.',
	'A749AnnualMaint':'Annual maintenance',
	'A750GPerSecond':'g/s',
	'A751SortName':'Type name',
	'A752PressureTemp':'Compressed air temperature',
	'A901MachineCustomerOverview':'Machine / Customer overview',
	'A902Machines':'Machines',
	'A903Permissions':'Permissions',
	'A904CompanyName':'Company name',
	'A905MachineName':'Machine name',
	'A906MachineType':'Machine type',
	'A907Order':'Order',
	'A908Action':'Action',
	'A909Remove':'Remove',
	'A910ChooseCustomer':'Choose customer',
	'A911ConnectMachines':'Connect machines',
	'A912User':'User',
	'A913FirstName':'First name',
	'A914Surname':'Surname',
	'A915Login':'Login',
	'A916Roles':'Roles',
	'A917Company':'Company',
	'A918CreateUser':'Create user',
	'A919Username':'Username',
	'A920Password':'Password',
	'A921Name':'Name',
	'A922ParentCompany':'Parent company',
	'A923Create':'Create',
	'A924Edit':'Edit',
	'A925SubCompany':'Sub company',
	'A926Companies':'Companies',
	'A927EditMachine':'Edit machine',
	'A928CompaniesFunctionality':'Companies/Functionality',
	'A930SelectAll':'Select all',
	'A931DetailView':'Detail view',
	'A932EditUser':'Edit user',
	'A933Delete':'Delete',
	'A934Cancel':'Cancel',
	'A935Save':'Save',
	'A936LoginFailed':'Log in failed. Bad credentials.',
	'A936Malayalam':'Malayalam',
	'A937DisplayName1':'Display name 1',
	'A938DisplayName2':'Display name 2',
	'A939DisplayName3':'Display name 3',
	'A940ForgotPassword':'Forgot password',
	'A941ForgotText':'No problem. Enter your username and you will receive a mail with a link that you can use to reset your password.',
	'A942Submit':'Submit',
	'A943EmailAddress':'Email address',
	'A944ChangePassword':'Change password',
	'A945CurrentPassword':'Current password',
	'A946NewPassword':'New password',
	'A947ConfirmPassword':'Confirm new password',
	'A948Update':'Update',
	'A949IncorrectPassword':'Incorrect password',
	'A950Metrics':'Metricspecs',
	'A950PasswordsNotMatch':'New passwords does not match',
	'A951EmailNotFound':'Email address not found',
	'A952InvalidEmail':'Enter valid email address',
	'A953EmailSent':'If your email exists in our database, an email will be send to your mail id with the reset link.',
	'A954ForgetPasswordMailSubject':'Reset your password',
	'A955ForgetPasswordMailTitle':'Hello USER_NAME,',
	'A956ForgetPasswordMailBody':'<p>We received a request to reset your password.<br>Use the link below to set up a new password for your account. If you did not request to reset your password, ignore this email and the link will expire in 24 hours on its own.<p><a href=\'RESET_LINK\' style=\'text-decoration: none; font-color: white;\'><span style=\'padding: 10px; display: inline; border-radius: 2px; border: 0; margin: 0 10px; background: #09174a; color:white; line-height: 15px; width: auto; height: auto; box-sizing: content-box;\'>Set new password</span></a><br><p>Best regards from the TrackYourPlant-Team</p>',
	'A957DateFormat':'dd/MM/yyyy',
	'A958D3DateFormat':'%d/%m/%Y',
	'A959DashboardMachineStateStandBy':'StandBy',
	'A959MessageUpdateSuccess':'Message successfully updated',
	'A960MessageSaveSuccess':'Message successfully saved',
	'A961Yes':'Yes',
	'A962No':'No',
	'A963ReportOverview':'Report overview',
	'A964Machine':'Machine',
	'A965Card':'Card',
	'A966ReportType':'Report type',
	'A967HistogramReport':'Histogram report',
	'A968Report':'Report',
	'A969Day':'1 day',
	'A969French':'Français',
	'A970Week':'1 week',
	'A971Month':'1 month',
	'A972HistoryChart':'History chart',
	'A972HistoryChartBacth':'History chart cur. batch',
	'A973Unit':'[Unit]',
	'A974Time':'Time',
	'A975ShowBagCounterStackChart':'Stack chart',
	'A976TestReportStarted':'Test report generation started',
	'A977ActiveSettingsRequired':'Active Report settings required to generate the Test Report',
	'A978FirstDayRequired':'Date of first report required.',
	'A979CompanyDeleted':'Company is deleted.',
	'A980CompanyDeleteError':'Company could not be deleted:',
	'A981CompanyParentShouldDifferent':'Company and parent company must be different',
	'A982CompanyCreated':'Company saved.',
	'A983CompanyCreateError':'Company could not be saved:',
	'A984CompanyAllFields':'Enter all fields',
	'A985UserCreated':'User created',
	'A986UserExists':'Email already exists',
	'A987UserDeleted':'User deleted',
	'A988IncludedInReport':'Included in report',
	'A989IncludedInHistogram':'Included as histogram in report',
	'A990DateFormat':'DD/MM/YYYY',
	'A991MachineTime':'Machine time',
	'A992LocalTime':'Local time',
	'A993NoMachines':'No Machines to show',
	'A994Imprint':'Imprint',
	'A995PrivacyPolicy':'Privacy policy',
	'A996Chinese':'中文',
	'A997Portuguese':'Português',
	'A998Vietnamese':'Tiếng Việt ',
	'A999CompanyNameExists':'Company name already exists.',
	'B100ActivatingReports':'Activating will send regular reports to the following email-id(s):',
	'B101EnterValidEmailIds':'Enter valid email ID.',
	'B101ValidFrom':'Valid from',
	'B102AtleastOneEmailIdRequired':'At-least one email ID required',
	'B102ValidTo':'Valid to',
	'B103MaximumLimitReached':'Maximum limit reached',
	'C001FetchingWait':'Fetching data. Please wait.',
	'C002PreparingWait':'Preparing data. Please wait.',
	'C003RenderingWait':'Rendering chart. Please wait.',
	'C004PixelScale':'1 pixel ≈ {{timeInterval}} seconds.',
	'C005ChartError':'Error occured!',
	'C006ChartOk':'OK',
	'D001PortalType':'Portal',
	'D002CompanyDomain':'Look and Feel',
	'E001AlertSettingSavedSuccessfully':'Alerting settings saved successfully.',
	'E002SelectAtLeastOneAlertTypeToActivateAlerting':'Select at least one alert type to activate alerting.',
	'E003ErrorNumberMustBePositive':'Error number must be positive',
	'E004AlertEmailNeedsToBeActive':'Alert Email needs to be active.',
	'E005AnErrorHasOccured':'An error has occurred.',
	'E005ErrorMessageAlreadyExists':'Message already exists',
	'E005OperationSuccesful':'Operation successful',
	'E006ErrorMessageSave':'Error occured. Message not saved',
	'E006ErrorOccurredSettingsWasNotSaved':'Error occurred. Settings was not saved',
	'E007ReportSettingsSuccessfullySaved':'Report settings successfully saved.',
	'E008ErrorSettingsDoNotExists':'Error settings do not exists.',
	'E009ReportSettingsSuccessfullyUpdated':'Report settings successfully updated.',
	'E010InvalidPassword':'Invalid password',
	'E011UserUpdated':'User updated',
	'E012InvalidPassword':'The password does not satisfy the password criteria.',
	'E013UserUpdated':'The user updated successfully.',
	'E015CompanyMaxUsersLowerThanActiveUsers':'The company has already more active users than the maximum users set.',
	'E016PasswordChangedSuccessfully':'Password was changed successfully.',
	'F001_CurrentOrder':'Current order',
	'F002_ProductID':'Product ID',
	'F003_BatchStart':'Batch starting time ',
	'F004_Targetweight':'Target weight',
	'F005_RemainingFill':'Remaining filling operations ',
	'F006_CurrentProduction':'Current production',
	'F007_FillStatistic':'Fill statitics',
	'F010_Sum':'Total',
	'F011_Centering':'Centering',
	'F012_Debunging':'De-bunging',
	'F013_Inert':'Purging',
	'F014_Filling_1':'Filling 1',
	'F015_Filling_2':'Filling 2',
	'F016_Filling_3':'Filling 3',
	'F017_Filling_4':'Filling 4',
	'F018_Bunging':'Bunging',
	'F019_Sealing':'Sealing',
	'F020_Position':'Positioning',
	'F021_TransportCycle':'Transport cycle',
	'F022_CheckWeigher':'Checkweigher ',
	'F023_Closing':'Closing',
	'F024_Palletizing':'Palletizing ',
	'F025_EmptyPaletMagazin':'Empty pallet magazine',
	'F026_DePalletizing':'De-palletizing',
	'F027_Transport':'Transport',
	'F028_PailSeperator':'Pail denester',
	'F029_HangUpCap':'Lid placer',
	'F030_PushInCap':'Lid presser ',
	'F031_TestConsoles':'Test station',
	'F050_TU2':'TU 2',
	'F051_TU1':'TU 1 ',
	'F052_LowerTol':'Below tolerance',
	'F053_InTol':'Within tolerance',
	'F054_UpperTol':'Above tolerance ',
	'F055_TO1':'TO 1',
	'F056_TO2':'TO 2',
	'F060_ProductInfeed':'Product feed',
	'F061_EmptyTruss':'Empty container feed ',
	'F062DetailedDateFormat':'MM.DD.YYYY hh:mm:ss',
	'F090_Minuten':'Minutes ',
	'F091_Stunden':'Hours ',
	'F092_Woche':'Week',
	'F100_Eichung':'Next calibration ',
	'F101_TransKette':'Conveyor chain ',
	'F102_Hubtor':'Lifting gates',
	'F103_ZentRollen':'Centering rollers',
	'F104_SpundSuch':'Bunghole locator ',
	'F105_Aushub':'Container lift ',
	'F106_WerkSchlitten':'Tool carriage',
	'F107_Aufschrauber':'Runtime de-bunging',
	'F108_VakuSys':'Vacuum system de-bunging',
	'F109_HubAufSchraub':'Cylinder de-bunging',
	'F110_OelAufSchraub':'Lubrication de-bunging',
	'F111_Inertgas':'Inerting',
	'F112_Aushub_F1':'Container lift ',
	'F113_HE_F1':'Lifting unit',
	'F114_TFE_F1':'Drip scoop ',
	'F115_Aushub_F2':'Container lift ',
	'F116_HE_F2':'Lifting unit',
	'F117_TFE_F2':'Drip scoop ',
	'F118_ZentDorn':'Centering mandrel',
	'F119_Schiebetisch':'Sliding table ',
	'F120_Kappenband':'Plug conveyor ',
	'F121_VakuKap':'Vacuum system plug separation',
	'F122_KapSort':'Sorting pot',
	'F123_VerKap':'Plug separation',
	'F124_KapZu':'Plug feed',
	'F125_Schrauber':'Bunging',
	'F126_VakuSchrauber':'Vacuum system bunging ',
	'F127_HubSchrauber':'Cylinder bunging',
	'F128_OelZuSchraub':'Lubrication bunging',
	'F129_Dichtung':'Seal bunging head',
	'F130_HubClinch':'Cylinder sealer',
	'F131_Clinchen':'Sealing',
	'F132_OelClincher':'Lubrication sealing',
	'F133_VakuClincher':'Vacuum system sealing',
	'F140_Startphase':'Start phase',
	'F141_Grobstromphase':'Coarse fill phase',
	'F142_Feinstromphase':'Fine fill phase',
	'F143_DurchflussF1':'Flow rate F1',
	'F144_DurchflussF2':'Flow rate F2',
	'G001BatchReport':'Batch reporting',
	'G002ActivateBatchReport':'activate batch reporting',
	'Hülsen MIN-Melder':'Hull MIN-Sensor',
	'IVT-Simulation':'INTEGRA® ',
	'Kabel Sattelhöhenv':'Cable saddle height adjust.',
	'MACHINE_AVAILABILITY_RED_ZONE':'Machine availability in red zone',
	'MACHINE_AVAILABILITY_YELLOW_ZONE':'Machine availability in yellow zone',
	'Maint Interval 0':'Maint Interval 0',
	'Maint Interval 1':'Maint Interval 1',
	'Maint Interval 10':'Maint Interval 10',
	'Maint Interval 11':'Maint Interval 11',
	'Maint Interval 12':'Maint Interval 12',
	'Maint Interval 13':'Maint Interval 13',
	'Maint Interval 14':'Maint Interval 14',
	'Maint Interval 2':'Maint Interval 2',
	'Maint Interval 3':'Maint Interval 3',
	'Maint Interval 4':'Maint Interval 4',
	'Maint Interval 5':'Maint Interval 5',
	'Maint Interval 6':'Maint Interval 6',
	'Maint Interval 7':'Maint Interval 7',
	'Maint Interval 8':'Maint Interval 8',
	'Maint Interval 9':'Maint Interval 9',
	'miniADAMS-Messe':'ROTO-PACKER®',
	'N042BrazAlertGlobalAccL0':'Global acceleration crossed L0',
	'N042BrazAlertGlobalAccL1':'Global acceleration crossed L1',
	'N042BrazAlertGlobalAccL2':'Global acceleration crossed L2',
	'N042BrazAlertGlobalAccL3':'Global acceleration crossed L3',
	'N042BrazAlertGlobalAccL4':'Global acceleration crossed L4',
	'N042BrazAlertGlobalAmplL0':'Global amplitude crossed L0',
	'N042BrazAlertGlobalAmplL1':'Global amplitude crossed L1',
	'N042BrazAlertGlobalAmplL2':'Global amplitude crossed L2',
	'N042BrazAlertGlobalAmplL3':'Global amplitude crossed L3',
	'N042BrazAlertGlobalAmplL4':'Global amplitude crossed L4',
	'N042BrazAlertMaxRmsAccL0':'Maximum rms acceleration of LB,CB and RB sensors crossed L0',
	'N042BrazAlertMaxRmsAccL1':'Maximum rms acceleration of LB,CB and RB sensors crossed L1',
	'N042BrazAlertMaxRmsAccL2':'Maximum rms acceleration of LB,CB and RB sensors crossed L2',
	'N042BrazAlertMaxTempBearingSensL0':'Maximum temperature of bearing sensors crossed L0',
	'N042BrazAlertMaxTempBearingSensL1':'Maximum temperature of bearing sensors crossed L1',
	'N042BrazAlertMaxTempBearingSensL2':'Maximum temperature of bearing sensors crossed L2',
	'N042BrazAlertMaxZAxisSensorsL0':'Maximum acceleration of z axis of lfs, rfs lds and red sensors crossed L0',
	'N042BrazAlertMaxZAxisSensorsL1':'Maximum acceleration of z axis of lfs, rfs lds and red sensors crossed L1',
	'N042BrazAlertMaxZAxisSensorsL2':'Maximum acceleration of z axis of lfs, rfs lds and red sensors crossed L2',
	'N042BrazAlertRotFreqL0':'Rotation frequency crossed L0',
	'N042BrazAlertRotFreqL1':'Rotation frequency crossed L1',
	'N042BrazAlertRotFreqL2':'Rotation frequency crossed L2',
	'N042BrazAlertRotFreqL3':'Rotation frequency crossed L3',
	'N042BrazAlertRotFreqL4':'Rotation frequency crossed L4',
	'N042BrazAlertType':'Brazilian alert',
	'N042GlobalAcceleration':'Global acceleration',
	'N042GlobalAmplitude':'Global amplitude',
	'N042GlobalFrequency':'Global frequency',
	'N043CardCtxMenuShowConfigScreen':'Configure card',
	'N043ConfigSaveFailure':'Configuration updation failed',
	'N043ConfigSaveSuccess':'Configuration updated successfully',
	'N044BrazilAlertMailSubject':'Pulse CM Alert : MACHINE_NAME',
	'N045AlertWhenThresholdCrossed':'Notify me if this limit is exceeded.',
	'N046RawDataTab':'Raw Data',
	'N047SensorL1':'Sensor L 1',
	'N047SensorL4':'Sensor L 4',
	'N047SensorR1':'Sensor R 1',
	'N047SensorR4':'Sensor R 4',
	'N048Acceleration':'Acceleration',
	'N048AccelerationLocal':'Local acceleration',
	'N048AccelerationX':'Acceleration X',
	'N048AccelerationY':'Acceleration Y',
	'N048AccelerationZ':'Acceleration Z',
	'N048Amplitude':'Amplitude',
	'N048AmplitudeLocal':'Amplitude local',
	'N048AmplitudeX':'Amplitude X',
	'N048AmplitudeY':'Amplitude Y',
	'N048AmplitudeZ':'Amplitude Z',
	'N048ChooseEndDate':'Choose an end date',
	'N048ChooseStartDate':'Choose a start date',
	'N048Constant':'Constant',
	'N048CourseAngle':'Course angle',
	'N048DeltaTemperature':'Delta temperature',
	'N048Frequency':'Frequency',
	'N048LastSeen':'Last seen',
	'N048MovementAngle':'Movement angle',
	'N048OffsetX':'X Offset',
	'N048OffsetY':'Y Offset',
	'N048OffsetZ':'Z Offset',
	'N048PhaseAngle':'Phase angle',
	'N048MotionAngle':'Motion Angle',
	'N048RMS':'RMS',
	'N048Rpm':'RPM',
	'N048SensorDescription':'Sensor description',
	'N048SensorId':'Sensor ID',
	'N048SensorTemperature':'Sensor temperature',
	'N048Side':'Side',
	'N048SiteName':'Side name',
	'N048UpdatedTime':'Updated time',
	'N049accelFDCompLeft':'Accel.FDComp.left',
	'N049accelFDCompRight':'Accel.FDComp.right',
	'N049accelLRCompDisch':'Accel.LRComp.disch',
	'N049accelLRCompFeed':'Accel.LRComp.feed',
	'N049Date':'Date',
	'N049ErrorFetchingData':'Error when retrieving the data',
	'N049FrequencyDaily':'Day',
	'N049FrequencyHourly':'Hours',
	'N049FrequencyWeekly':'Week',
	'N049Length':'Length',
	'N049ModelName':'Model name',
	'N049pitch':'Pitch',
	'N049roll':'Roll',
	'N049Rpm':'Rpm',
	'N049strokeDeparture':'Stroke departure',
	'N049TuningWizard':'Tuning wizard',
	'N049twistG':'Twist.G',
	'N049twistXG':'Twist.XG',
	'N049twistYG':'Twist.YG',
	'N049twistZG':'Twist.ZG',
	'N049Width':'Width',
	'N049xgFDCompLeft':'X.G.FDComp.Left',
	'N049xgFDCompRight':'X.G.FDComp.Right',
	'N049xgLrCompDisch':'X.G.LRComp.Disch',
	'N049xgLrCompFeed':'X.G.LRComp.Feed',
	'N049ygFDCompLeft':'Y.G.FDComp.Left',
	'N049ygFDCompRight':'Y.G.FDComp.Right',
	'N049ygLrCompDisch':'Y.G.LRComp.Disch',
	'N049ygLrCompFeed':'Y.G.LRComp.Feed',
	'N049zgFDCompLeft':'Z.G.FDComp.Left',
	'N049zgFDCompRight':'Z.G.FDComp.Right',
	'N049zgLrCompDisch':'Z.G.LRComp.Disch',
	'N049zgLrCompFeed':'Z.G.LRComp.Feed',
	'N049zgMax':'ZG.Max',
	'N050AnalystCommentary':'Analyst commentary',
	'N050mAvgLong':'Mavg long',
	'N050mAvgShort':'Mavg short',
	'N050TrendAnalysis':'Trend analysis',
	'N051SensorL1':'Sensor L1',
	'N051SensorL2':'Sensor L2',
	'N051SensorL3':'Sensor L3',
	'N051SensorL4':'Sensor L4',
	'N051SensorMovement':'Sensor movement',
	'N051SensorR1':'Sensor R1',
	'N051SensorR2':'Sensor R2',
	'N051SensorR3':'Sensor R3',
	'N051SensorR4':'Sensor R4',
	'N052Anomaly':'Anomaly',
	'N052AnomalyDetectionTab':'Anomaly Detection',
	'N052BrazAlertTypeSL1LocalAmplitudeL0':'Sensor L1 local amplitude crossed L0',
	'N052BrazAlertTypeSL1LocalAmplitudeL1':'Sensor L1 local amplitude crossed L1',
	'N052BrazAlertTypeSL1LocalAmplitudeL2':'Sensor L1 local amplitude crossed L2',
	'N052BrazAlertTypeSL1LocalAmplitudeL3':'Sensor L1 local amplitude crossed L3',
	'N052BrazAlertTypeSL1LocalAmplitudeL4':'Sensor L1 local amplitude crossed L4',
	'N052BrazAlertTypeSL1LocalAmplitudeL5':'Sensor L1 local amplitude crossed L5',
	'N052BrazAlertTypeSL1SensorTempL0':'Sensor L1 sensor Temperature crossed L0',
	'N052BrazAlertTypeSL1SensorTempL1':'Sensor L1 sensor Temperature crossed L1',
	'N052BrazAlertTypeSL1SensorTempL2':'Sensor L1 sensor Temperature crossed L2',
	'N052BrazAlertTypeSL1SensorTempL3':'Sensor L1 sensor Temperature crossed L3',
	'N052ObservedValue':'Observed',
	'N053Angle':'Angle (0)',
	'N053BrazAlertTypeSL1LocalAccelerationL0':'Sensor L1 local acceleration crossed L0',
	'N053BrazAlertTypeSL1LocalAccelerationL1':'Sensor L1 local acceleration crossed L1',
	'N053BrazAlertTypeSL1LocalAccelerationL2':'Sensor L1 local acceleration crossed L2',
	'N053BrazAlertTypeSL1LocalAccelerationL3':'Sensor L1 local acceleration crossed L3',
	'N053BrazAlertTypeSL1LocalAccelerationL4':'Sensor L1 local acceleration crossed L4',
	'N053BrazAlertTypeSL1LocalAccelerationL5':'Sensor L1 local acceleration crossed L5',
	'N053BrazAlertTypeSl1RotationFreqL0':'Sensor L1 local rotation frequency crossed L0',
	'N053BrazAlertTypeSl1RotationFreqL1':'Sensor L1 local rotation frequency crossed L1',
	'N053BrazAlertTypeSl1RotationFreqL2':'Sensor L1 local rotation frequency crossed L2',
	'N053BrazAlertTypeSl1RotationFreqL3':'Sensor L1 local rotation frequency crossed L3',
	'N053BrazAlertTypeSl1RotationFreqL4':'Sensor L1 local rotation frequency crossed L4',
	'N053BrazAlertTypeSl1RotationFreqL5':'Sensor L1 local rotation frequency crossed L5',
	'N053BrazAlertTypeSL1ZAxisL0':'Sensor L1 z-axis acceleration crossed L0',
	'N053BrazAlertTypeSL1ZAxisL1':'Sensor L1 z-axis acceleration crossed L1',
	'N053BrazAlertTypeSL1ZAxisL2':'Sensor L1 z-axis acceleration crossed L2',
	'N053BrazAlertTypeSL1ZAxisL3':'Sensor L1 z-axis acceleration crossed L3',
	'N053Displacement':'Displacement',
	'N053G':'g',
	'N053LocalAmplitude':'Local amplitude',
	'N053LocalRotationFrequency':'Local rotation (frequency)',
	'N053MM':'mm',
	'N053XAxis':'X-axis',
	'N053YAxis':'Y-axis',
	'N053ZAxis':'Z-axis',
	'N053ZAxisAcceleration':'Z-axis acceleration',
	'N054Higher':'Upper',
	'N054Lower':'Lower',
	'N054StructuralAnalysis':'Structural analysis',
	'N054TWvarsForecasts':'TW vars forecasts',
	'N054TWvarsFull':'TW vars full',
	'N054Value':'Value',
	'N0551On0Off':'1 = ON : 0 = OFF',
	'N055BrazTabGeneral':'General',
	'N055ChooseStartEndDate':'Choose start date and end date',
	'N055Close':'Close',
	'N055Custom':'Custom',
	'N055MaterialFlow':'Material flow',
	'N055MAxBearingTemperature':'Maximum bearing temperature',
	'N055MaxRmsAccelBearingSensors':'Max. vert. RMS acceleration',
	'N055MazAccelZAxis':'Max acceleration of the Z-axis of structure sensors',
	'N055Off':'OFF',
	'N055On':'ON',
	'N055RDCenter':'Machine view',
	'N055ShowHistory':'Show history',
	'N055StartDateCannotBeAfterEndDate':'Start date cannot be after end date',
	'N056Commentary':'Commentary',
	'N056EvaluateEquipment':'Conditions to evaluate on the equipment',
	'N056High80':'High 80',
	'N056High95':'High 95',
	'N056Importance':'Meaning',
	'N056Low80':'Low 80',
	'N056Low95':'Low 90',
	'N056MaxLatAccelZgMax':'Maximum lateral acceleration ZG.Max',
	'N056Pred':'Pred',
	'N056Recommendations':'Recommendations',
	'N056Type':'Type',
	'N056Report':'Report',
	'N0571Year':'1 Year',
	'N057AccelGms':'Acceleration (g)',
	'N057AccelRms':'Acceleration RMS',
	'N057AccelSpectrum':'Acceleration spectrum',
	'N057AccelTimeWForm':'Acceleration time wave form',
	'N057Axial':'Axial',
	'N057CrestFactor':'Crest factor',
	'N057DegreeCelcius':'°C',
	'N057FreqHz':'Frequency (Hz)',
	'N057FullSpectrum':'Full spectrum',
	'N057FundBearingDefectFreq':'Fund, bearing, defect frequencies',
	'N057HighHarBearFreq':'Higher harmonic bearing frequencies',
	'N057HighRes':'High resolution',
	'N057HistoricTemperature':'Historic temperature',
	'N057Horizontal':'Horizontal',
	'N057Latest':'Latest',
	'N057LowHarBearFreq':'Lower harmonic bearing frequencies',
	'N057mms':'mm/s',
	'N057PeakToPeak':'Peak to peak',
	'N057PeakToPeakMg':'Peak to peak (mg)',
	'N057PowerBands':'Power bands',
	'N057RedAlert':'Red alert',
	'N057Rmsmg':'RMS (mg)',
	'N057Rmsmms':'RMS (mm/s)',
	'N057SensorLB1':'Sensor LB1',
	'N057SensorLB2':'Sensor LB2',
	'N057SensorRB1':'Sensor RB1',
	'N057SensorRB2':'Sensor RB2',
	'N057SensorCB1':'Sensor CB1',
	'N057SensorCB2':'Sensor CB2',
	'N057Spectrum':'Spectrum',
	'N057Temperature':'Temperature',
	'N057TimeMs':'Time (ms)',
	'N057Trending':'Trending',
	'N057VelocityRms':'Velocity RMS',
	'N057Vertical':'Vertical',
	'N057X1Rpm':'1X Rpm',
	'N057YellowAlert':'Yellow alert',
	'N058ImageUploadedFailure':'Image uploaded failure',
	'N058ImageUploadedSuccesfully':'Image uploaded successfully',
	'N058UploadImage':'Upload image',
	'N059Print':'Print',
	'N060MachineHistoricalData':'Machine historical data',
	'N061HistoricalBearingData':'Historical bearing data',
	'N062HistoricalSpectrumData':'Historical spectrum data',
	'N063GlobalAcceleration':'Global acceleration',
	'N064RotationFrequency':'Rotation (frequency)',
	'N065GlobalBreadth':'Global breadth',
	'N066LocalAccelerationsEachStructureSensor':'Local accelerations of each structure sensor',
	'N067MotionAngleEachStructureSensor':'Motion angle of each structure sensor',
	'N067PhaseAngleEachStructureSensor':'Phase angle of each structure sensor',
	'N067LocalAmplitudesEachStructureSensor':'Local amplitudes of each structure sensor',
	'N068LateralAccelerationsStructureSensor':'Lateral accelerations (Z-axis) of each structure sensor',
	'N069TemperatureEachBearingSensor':'Temperature of each bearing sensor',
	'N070PeakToPeakEachBearingSensor':'Peak to peak of each bearing sensor',
	'N071AccelerationRMS':'Acceleration RMS',
	'N072VelocityRMS':'Velocity RMS',
	'N073CrestFactor':'Crest factor',
	'N074PowerBandsSensorLB1':'Power bands - Sensor LB1',
	'N075PowerBandsSensorRB1':'Power bands - Sensor RB1',
	'N076PowerBandsSensorLB2':'Power bands - Sensor LB2',
	'N077PowerBandsSensorRB2':'Power bands - Sensor RB2',
	'N077PowerBandsSensorCB1':'Power bands - Sensor CB1',
	'N077PowerBandsSensorCB2':'Power bands - Sensor CB2',
	'N078TimeWaveformDataAcceleration':'Time waveform data - acceleration',
	'N079FrequencyDomainAcceleration':'Frequency Domain - acceleration',
	'N080Select':'Select',
	'N081SelectedPeriod':'Selected period',
	'N100Capacity':'Capacity',
	'N101Product':'Product',
	'N102Undersized':'Undersized',
	'N103Oversized':'Oversized',
	'N104Speed':'Speed',
	'N105Inclination':'Inclination',
	'N106BoardHeigh':'Board height',
	'N107Moisture':'Moisture',
	'N108WaterAdd':'Water adition ',
	'N109OpMode':'Operationmode',
	'N110Auto':'Automatic mode',
	'N110Hand':'Hand mode',
	'N111OpHours':'Operating hours',
	'N112Temp':'Temperature',
	'N113BearingTemp1':'Bearing temperature 1',
	'N114BearingTemp2':'Bearing temperature 2',
	'N115DriveTemp':'Drive temperature',
	'N116ConvTemp':'Converter temperature',
	'N117PowerInput':'Power input',
	'New001BagTransArea':'Bag transport area',
	'New002EmptyPalArea':'Empty pallet area',
	'New003FullPalArea':'Full pallet area',
	'New004SheetDispArea':'Sheet dispenser area',
	'New005PneuFalut':'Pneumatic fault',
	'New006ElectricFault':'Electrical fault',
	'New007Introduction':'Introduction',
	'New008Orientation':'Orientation ',
	'New009PreGrouping':'Pre-grouping',
	'New010Grouping':'Grouping',
	'New011RowPusher':'Row pusher',
	'New012SlideTable':'Sliding table & conforming plate',
	'New013Elevator':'Elevator',
	'New014Gantry':'Gantry',
	'New015Gripper':'Gripper',
	'New016FilmDispenser':'Film dispenser',
	'New017FullPalTrans':'Full pallet transport',
	'New018EmpPalDisp':'Empty pallet dispenser',
	'New019FilmDisp':'Film dispenser',
	'New020EmpCardDisp':'Cardboard dispenser',
	'New021EmpPalTrans':'Empty pallet transport',
	'New022EjectingDev':'Ejecting device',
	'New023MetDetector':'Metal detector',
	'New024MarkingDev':'Marking device',
	'New025LabellingDev':'Labelling device',
	'New026PalletStracker':'Pallet stracker',
	'New027PalletPress':'Pallet press',
	'New028Wrapper':'Wrapping / hooding device',
	'New029Fault':'Fault',
	'New030StandBy':'Standby',
	'New031BagProduction':'Bag production',
	'New032WeightDetection':'Weight detection',
	'New033LeakingDetection':'Leaking detection',
	'New034PrintingFault':'Printing fault',
	'New035BagProduced':'Bag produced',
	'New036SamplingEjection':'Sampling ejection',
	'New037PalletProduction':'Pallet production',
	'New038PalletInfeed':'Pallet infeed',
	'New039PalletOutfeed':'Pallet outfeed',
	'New040PalletIncomplete':'Incomplete pallet',
	'New041Checkweigher':'Checkweigher ',
	'New041PalledProduced':'Pallet produced',
	'New042ProgramChngsToday':'Program changes today',
	'New042ProgramChngs':'Program changes',
	'New043EndofBatch':'End of batch',
	'New044ProductonTime':'Production time',
	'RVT8-Messe':'ROTO-PACKER® ',
	'RVT8-Simulation':'ROTO-PACKER® ',
	'TERAM2-Messe':'TERRAM®',
	'TERAM-Messe':'TERRAM®',
	'Typ84-Messe':'INTEGRA® ',
	'Waagenabgleich':'Scale calibration',
	'Zyklen':'Cycles',
	'N082AlertMe':'Notify me when this card contains notifications.',
	'N083TimeFormat':'HH:mm',
	'N090Synced':'Synchronized',
	'N091NotSynced':'Not synchronized',
	'N084New':'New',
	'N085ConfigSettingSavedSuccessfully':'Config settings saved successfully.',
	'N86ErrorOccurredSettingsWasNotSaved':'Error while saving config settings.',
	'A496OEEWeek':'Every second week',
	'A497OEEMonthly':'Monthly',
	'N87ServiceRequestUploadFiles':'Upload files',
	'N88ServiceRequestFileSizeError':'File is too big',
	'N89ServiceRequestFileTypeError':'File types are not supported',
	'N90FilesUploadedSuccesfully':'File uploaded succesfully',
	'N91FilesUploadedFailure':'Error while uploading files',
	'N932IncludeExcelReport':'Include excel reporting',
	'N94ServiceRequestFileLimitError':'File over limit',
	'N055MaxHAxisRmsAccelBearingSensors':'Max. horiz. RMS Acceleration',
	'N055MaxAxialRmsAccelBearingSensors':'Max. axial RMS Acceleration',
	'N053MG':'mg',
	'N056DummyMachine':'Dummy machines',
	'N057NiagaraAlertYellow':'Yellow Alert',
	'N058NiagaraAlertOrange':'Orange Alert',
	'N059NiagaraAlertRed':'Red Alert',
	'N060AddDummyMachine':'Add Dummy Machine',
	'N96ConfigureValue':'Config Value',
	'N97NoDataAvailable':'No data available',
	'N98TimeStamp':'Timestamps',
	'N99Documentation':'Documentation',
	'N100ErrorFileHeader':'Error messages',
	'N101DocumentationHeader':'Documentation header',
	'N102DownloadMachineTypeErrorMessageFile':'Default machine type',
	'N102DownloadMachineErrorMessageFile':'Machine error file',
	'N103ErrorInRetrievingErrorMessages':'Error while retrieving error messages.',
	'E011MaintIntervalSubmitSuccess':'Maintanance interval saved successfully.',
	'A368OpenPartsShop':'Open spare parts shop',
	'N104ExtSparePartCatalog':'HAVER spare parts shop',
	'N105OpenExtSparePartCatalog':'Open HAVER parts shop',
	'N106LperH':'l/h',
	'N107Degree':'°',
	'N107DegreeText':'° (Degree)',
	'N108UseRelativeValues':'Use relative values',
	'N109PartsShopUsername':'Spare parts shop username',
	'N110Tons':'t',
	'AX_VibratingBodyCondition':'Vibrating body condition',
	'AX_BearingCondition':'Bearing condition',
	'AX_AlarmsCount':'Alarms count',
	'AX_Last24Hours':'Over the last 24h',
	'D001TransBearingCondition':'Good',
	'D002TransBearingCondition':'Good | Decreasing',
	'D003TransBearingCondition':'Good | Stable',
	'D004TransBearingCondition':'Good | Increasing',
	'D005TransBearingCondition':'Acceptable',
	'D006TransBearingCondition':'Acceptable | Decreasing',
	'D007TransBearingCondition':'Acceptable | Stable',
	'D008TransBearingCondition':'Acceptable | Increasing',
	'D009TransBearingCondition':'Serious',
	'D010TransBearingCondition':'Serious | Decreasing',
	'D011TransBearingCondition':'Serious | Stable',
	'D012TransBearingCondition':'Serious | Increasing',
	'D013TransBearingCondition':'Critical',
	'D014TransBearingCondition':'Critical | Decreasing',
	'D015TransBearingCondition':'Critical | Stable',
	'D016TransBearingCondition':'Critical | Increasing',
	'D001TransVBCondition':'Good',
	'D002TransVBCondition':'Good | Decreasing',
	'D003TransVBCondition':'Good | Stable',
	'D004TransVBCondition':'Good | Increasing',
	'D005TransVBCondition':'Acceptable',
	'D006TransVBCondition':'Acceptable | Decreasing',
	'D007TransVBCondition':'Acceptable | Stable',
	'D008TransVBCondition':'Acceptable | Increasing',
	'D009TransVBCondition':'Serious',
	'D010TransVBCondition':'Serious | Decreasing',
	'D011TransVBCondition':'Serious | Stable',
	'D012TransVBCondition':'Serious | Increasing',
	'D013TransVBCondition':'Critical',
	'D014TransVBCondition':'Critical | Decreasing',
	'D015TransVBCondition':'Critical | Stable',
	'D016TransVBCondition':'Critical | Increasing',
	'F01CardExpProduction':'***Coming Soon***Explanation for the card production',
	'F01CardExpAvailability':'***Coming Soon***Explanation for the card availbility',
	'F01CardExpMaintenance':'***Coming Soon***Explanation for the card machine maintenance',
	'F01CardExpProductionTime':'***Coming Soon***Explanation for the card production time',
	'F01CardExpProductionTimeBaumit':'***Coming Soon***Explanation for the card production',
	'F01CardExpProductionToday':'***Coming Soon***Explanation for the card production today',
	'F01CardExpRemProduction':'***Coming Soon***Explanation for the card production time',
	'F01CardExpLastReset':'***Coming Soon***Explanation for the card last reset',
	'F01CardExpFaults_C':'***Coming Soon***Explanation for the card faults counts',
	'F01CardExpFaults_M':'***Coming Soon***Explanation for the card faults minutes',
	'F01CardExpEnergy':'***Coming Soon***Explanation for the card energy measurement',
	'F01CardExpFillingTime':'***Coming Soon***Explanation for the card filling time ',
	'F01CardExpBagManufacture':'***Coming Soon***Explanation for the card bag manufacture',
	'F01CardExpWeightData':'***Coming Soon***Explanation for the card weight data',
	'F01CardExpStdDevData':'***Coming Soon***Explanation for the card standard deviation ',
	'F01CardExpIncorrectWeight':'***Coming Soon***Explanation for the card rejects incorrect weights',
	'F01CardExpInterrupts':'***Coming Soon***Explanation for the card interruptions',
	'F01CardExpTypeChange':'***Coming Soon***Explanation for the card type changes',
	'F01CardExpBagCounterPerFS':'***Coming Soon***Explanation for the card bag counter',
	'F01CardExpNotPlacedBags':'***Coming Soon***Explanation for the card not placed bags',
	'F01CardExpEmptyBagMagazineToday':'***Coming Soon***Explanation for the card refills empty bag magazine',
	'F01CardExpRejectsToday':'***Coming Soon***Explanation for the card rejects ',
	'F01CardExpCollectiveFaultsToday':'***Coming Soon***Explanation for the card collective faults today ',
	'F01CardExpGoodWeights':'***Coming Soon***Explanation for the card good weights',
	'F01CardExpFltCompAir':'***Coming Soon***Explanation for the card fault compressed air ',
	'F01CardExpStrokesPump':'***Coming Soon***Explanation for the card stroke pump',
	'F01CardExpMaxStrokeDur':'***Coming Soon***Explanation for the card maximum stroke duration',
	'F01CardExpAvgStrokes':'***Coming Soon***Explanation for the card average stroke duration',
	'F01CardExpInternalPressure':'***Coming Soon***Explanation for the card internal pressure',
	'F01CardExpFillings':'***Coming Soon***Explanation for the card fillings',
	'F01CardExpStartOfOrder':'***Coming Soon***Explanation for the card start of order',
	'F01CardExpProductID':'***Coming Soon***Explanation for the card product ID',
	'F01CardExpTargetWeight':'***Coming Soon***Explanation for the card target weight',
	'F01CardExpFillingCount':'***Coming Soon***Explanation for the card counter fillings',
	'F01CardExpRemainingNumberFillings':'***Coming Soon***Explanation for the card remaining number of fillings',
	'F01CardExpCycleTime':'***Coming Soon***Explanation for the card cycle time',
	'F01CardExpFaultLength':'***Coming Soon***Explanation for the card fault duration',
	'F01CardExpExtFaultLength':'***Coming Soon***Explanation for the card external fault duration',
	'F01CardExpWithinTolerance':'***Coming Soon***Explanation for the card within tolerance ',
	'F01CardExpOutsideTolerance':'***Coming Soon***Explanation for the card outside tolerance',
	'F01CardExpCurrentProduction':'***Coming Soon***Explanation for the card current production',
	'F01CardExpFillStatistic':'***Coming Soon***Explanation for the card fill statisctic',
	'F01CardExpRDCenter':'***Coming Soon***Explanation for the card RD center',
	'F01CardExpGlobalAcceleration':'***Coming Soon***Explanation for the card global acceration',
	'F01CardExpGlobalFrequency':'***Coming Soon***Explanation for the card global frequency ',
	'F01CardExpGlobalAmplitude':'***Coming Soon***Explanation for the card global amplitude',
	'F01CardExpMazAccelZAxis':'***Coming Soon***Explanation for the card max Z axis accleration',
	'F01CardExpMaxHAxisRmsAccelBearingSensors':'***Coming Soon***Explanation for the card max H axis accleration',
	'F01CardExpMaxRmsAccelBearingSensors':'***Coming Soon***Explanation for the card maximal RMS accleration',
	'F01CardExpMaxAxialRmsAccelBearingSensors':'***Coming Soon***Explanation for the card max axial RMS accleration',
	'F01CardExpMAxBearingTemperature':'***Coming Soon***Explanation for the card max temp bearing sensor',
	'F01CardExpSensorMovement':'***Coming Soon***Explanation for the card sensor movement',
	'F01CardExpAccelerationLocal':'***Coming Soon***Explanation for the card local accleration',
	'F01CardExpAcceleration':'***Coming Soon***Explanation for the card accleration',
	'F01CardExpAxisAcceleration':'***Coming Soon***Explanation for the card Axis Acceleration',
	'F01CardExpLocalRotationFrequency':'***Coming Soon***Explanation for the card local rotation frequency',
	'F01CardExpLocalAmplitude':'***Coming Soon***Explanation for the card local amplitude',
	'F01CardExpDisplacement':'***Coming Soon***Explanation for the card dsiplacement',
	'F01CardExpPhaseAngle':'***Coming Soon***Explanation for the card phase angle ',
	'F01CardExpMotionAngle':'***Coming Soon***Explanation for the card phase angle ',
	'F01CardExpSensorTemperature':'***Coming Soon***Explanation for the card sensor temperature',
	'F01CardExpBagQuality':'***Coming Soon***Explanation for the card bag quality',
	'F01CardExpFaults':'***Coming Soon***Explanation for the card Faults',
	'F01CardExpBunChangeAbs':'***Coming Soon***Explanation for the card bundle changes absolut',
	'F01CardExpBunChangeToday':'***Coming Soon***Explanation for the card bundle changes today',
	'F01CardExpBagsPerBundle':'***Coming Soon***Explanation for the card bags per bundle',
	'F01CardExpTypeChanges':'***Coming Soon***Explanation for the card type changes',
	'F01CardExpSortChangeToday':'***Coming Soon***Explanation for the card type changes today',
	'F01CardExpCyclesToday':'***Coming Soon***Explanation for the card cycles today',
	'F01CardExpMetalDetectorToday':'***Coming Soon***Explanation for the card metal detections',
	'F01CardExpIncorrectBags':'***Coming Soon***Explanation for the card incorrect bags',
	'F01CardExpOpenBags':'***Coming Soon***Explanation for the card open bags',
	'F01CardExpSampleBags':'***Coming Soon***Explanation for the card sample bags',
	'F01CardExpRejectionsToday':'***Coming Soon***Explanation for the card rejections ',
	'F01CardExpContainer':'***Coming Soon***Explanation for the card containers',
	'F01CardExpEmptyRejects':'***Coming Soon***Explanation for the card empty bag rejects',
	'F01CardExpFullRejects':'***Coming Soon***Explanation for the card full bag rejects',
	'F01CardExpProductionRate':'***Coming Soon***Explanation for the card production rate',
	'F01CardExpDailyProduction':'***Coming Soon***Explanation for the card daily production',
	'F01CardExpFillingStation':'***Coming Soon***Explanation for the card filling station',
	'F01CardExpPalletizer':'***Coming Soon***Explanation for the card palletizer',
	'F01CardExpEmptyPalArea':'***Coming Soon***Explanation for the card empty pallet area',
	'F01CardExpBagTransArea':'***Coming Soon***Explanation for the card bag transport area',
	'F01CardExpFullPalArea':'***Coming Soon***Explanation for the card full pallet area',
	'F01CardExpSheetDispArea':'***Coming Soon***Explanation for the card sheet dispender area',
	'F01CardExpProductonTime':'***Coming Soon***Explanation for the card production time',
	'F01CardExpProgramChngsToday':'***Coming Soon***Explanation for the card programm changes today',
	'F01CardExpEndofBatch':'***Coming Soon***Explanation for the card end of batch',
	'F01CardExpEmptyPallets':'***Coming Soon***Explanation for the card empty pallets',
	'F01CardExpBagProduction':'***Coming Soon***Explanation for the card production',
	'F01CardExpPalletProduction':'***Coming Soon***Explanation for the card pallet production',
	'F01CardExpFSDifference':'***Coming Soon***Explanation for the card filling spout difference',
	'F01CardExpProduct':'***Coming Soon***Explanation for the card product',
	'F01CardExpCapacity':'***Coming Soon***Explanation for the card capacity',
	'F01CardExpUndersized':'***Coming Soon***Explanation for the card undersized',
	'F01CardExpOversized':'***Coming Soon***Explanation for the card oversized',
	'F01CardExpSpeed':'***Coming Soon***Explanation for the card speed',
	'F01CardExpInclination':'***Coming Soon***Explanation for the card inclination',
	'F01CardExpBoardHeigh':'***Coming Soon***Explanation for the card board heigh',
	'F01CardExpMoisture':'***Coming Soon***Explanation for the card moisture',
	'F01CardExpWaterAdd':'***Coming Soon***Explanation for the card water addition',
	'F01CardExpOpMode':'***Coming Soon***Explanation for the card operation mode',
	'F01CardExpOpHours':'***Coming Soon***Explanation for the card operation hours',
	'F01CardExpTemp':'***Coming Soon***Explanation for the card temperature',
	'F01CardExpPowerInput':'***Coming Soon***Explanation for the card power input',
	'F01CardExpFullPalletCount':'***Coming Soon***Explanation for the card count of full pallets',
	'F01CardExpLoadEmptyPallets':'***Coming Soon***Explanation for the card empty pallets loaded',
	'F01CardExpOeeValue':'***Coming Soon***Explanation for the card OEE-Value',
	'F01CardExpOeeAvailable':'***Coming Soon***Explanation for the card OEE availbilty',
	'F01CardExpOeePerformance':'***Coming Soon***Explanation for the card OEE performance',
	'F01CardExpOeeQuality':'***Coming Soon***Explanation for the card OEE quality',
	'F01CardExpOeeDowntime':'***Coming Soon***Explanation for the card OEE downtime',
	'F01CardExpOperatingFactor':'***Coming Soon***Explanation for the card operation factor',
	'F01CardExpThroughput':'***Coming Soon***Explanation for the card Thoughtput',
	'F01CardExpOeeRejects':'***Coming Soon***Explanation for the card OEE rejects',
	'F01CardExpMachineDetailPage':'***Coming Soon***Explanation for the card Machine detail',
	'F01CardExpReportSettingsPage':'***Coming Soon***Explanation for the card Report settings',
	'F01CardExpReportOverviewPage':'***Coming Soon***Explanation for the card Report Overview',
	'F01CardExpCompanyOverviewPage':'***Coming Soon***Explanation for the card Company Overview',
	'F01CardExpAlertSettingsPage':'***Coming Soon***Explanation for the card Alert Settings',
	'F01CardExpMsgAdminPage':'***Coming Soon***Explanation for the card Msg Admin',
	'F01CardExpUserRolesPage':'***Coming Soon***Explanation for the card User Roles',
	'F01CardExpCompaniesPage':'***Coming Soon***Explanation for the card Companies',
	'F01CardExpDummyMachinePage':'***Coming Soon***Explanation for the card Dummy Machine',
	'F01CardExpPermissionsPage':'***Coming Soon***Explanation for the card Permissions',
	'F01CardExpMaintenanceAidPage':'***Coming Soon***Explanation for the card Maintenance Aid',
	'F01CardExpMachinePage':'***Coming Soon***Explanation for the card Machine',
	'F01CardExpLogBookTab':'***Coming Soon***Explanation for the card LogBook',
	'F01CardExpServiceRequestTab':'***Coming Soon***Explanation for the card Service Request',
	'F01CardExpMessagesTab':'***Coming Soon***Explanation for the card Messages',
	'F01CardExpDocumentSTab':'***Coming Soon***Explanation for the card Document Download',
	'F01CardExpOeeTab':'***Coming Soon***Explanation for the card OEE',
	'F01CardExpMaintenanceTab':'***Coming Soon***Explanation for the card Maintenance Tab',
	'F01CardExpMachineSpecificTab':'Explanation for Machine specific Tab',
	'F01CardExpGeneralTab':'Explanation for General Tab',
	'F01CardExpGoodPerformance':'Explanation for Good performance',
	'F01CardExpWrongApplBags':'Explanation for Wrong applicated bags',
	'F01CardExpReelChanges':'Explanation for reel changes',
	'F01CardExpTypeCurrent':'Explanation for type currents',
	'F01CardExpInterruptsFeigeGeneral':'***Coming Soon***',
	'F01CardExpInterruptsAdams':'***Coming Soon***',
	'F01CardExpInterruptsISF':'***Coming Soon***',
	'F01CardExpInterruptsIVT':'***Coming Soon***',
	'F01CardExpInterruptsMiniAdams':'***Coming Soon***',
	'F01CardExpInterruptsRVT':'***Coming Soon***',
	'F01CardExpInterruptsNewtecTerram':'***Coming Soon***',
	'A-AlertTip-main_acc-ORANGE':'Main Acceleration Orange Alert',
	'A-AlertTip-main_acc-YELLOW':'Main Acceleration Yellow Alert',
	'A-AlertTip-main_acc-RED':'Main Acceleration Red Alert',
	'A-AlertTip-u8MachAvail-AVAIL':'Availability Alert',
	'A905MachineNameNotAllowedCharacter':'Following characters are not allowed: !, @, #, $, %, ^, &, *, (, ), [, ], {, }, |, ~, \\, =, +, ,, ;, <, >, ?, /',
	'A1000ReportSystemMessage':'System Messages',
	'A1001ReportSystemMessageOffline':'The card CARDNAME for machine MACHINENAME is not displayed because it has not produced anything in the last 96 hours',
	'A1002ReportOverviewHeaderOrder':'Order',
	'A1003ReportSystemMessageCardNotForReportType':'The card CARDNAME for machine MACHINENAME is not shown in REPORTTYP-report',
};