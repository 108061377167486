export const LANG_PT_NAME = 'pt';
export const LANG_PT_TRANS = {
	'0030565001AE':'ELEMENTRA® ',
	'003056500368':'INTEGRA®',
	'003056500394':'ROTO-PACKER®',
	'003056500398':'ROTO-PACKER®',
	'0030565011A8':'ROTO-PACKER®',
	'0030565011AE':'ELEMENTRA®',
	'003056501200':'ROTO-PACKER®',
	'00305650121E':'INTEGRA®',
	'003056A98058':'ROTO-PACKER®',
	'057X1to2Rpm':'1X-2X Rpm',
	'057X2to3Rpm':'2.5X - 3X Rpm',
	'A000Key':'Chave',
	'A001Country':'Português',
	'A002German':'Deutsch',
	'A003English':'English',
	'A004Username':'E-mail',
	'A005Login':'Faça login na sua conta',
	'A006Password':'Senha',
	'A007RemPassword':'Mantenha-me conectado',
	'A008Login':'Login',
	'A009Logout':'Sair',
	'A010Production':'Produtividade',
	'A011Availability':'Disponibilidade',
	'A012Maintenance':'Manutenção Programada da máquina',
	'A013RemainingUnits':'Unidades restantes',
	'A014ProductionTime':'Tempo total',
	'A015ProductionTodayGeneral':'Produção hoje',
	'A015ProductionToday':'Produção hoje',
	'A016Units':'Unidades',
	'A017RemProduction':'Produção total',
	'A018LastReset':'Último Reset',
	'A019Faults':'Falhas hoje',
	'A019FaultsBatch':'Falhas',
	'A020Count':'Contagem',
	'A021FaultDuration':'Duração da falha hoje',
	'A022Minutes':'min',
	'A023BagCounterPerFS':'Quantidade total de sacos hoje',
	'A023BagCounterPerFSBatch':'Quantidade total de sacos',
	'A024FillingStation':'Estação de enchimento',
	'A025FSDifference':'Dispersão entre Melhor e a Pior Estação de Enchimento',
	'A026AtLeast1Caps':'pelo menos 1 letra maiúscula',
	'A026FSworstspout':'e pior bico',
	'A027Percent':'Por cento',
	'A028RejectionsToday':'Rejeitos hoje',
	'A028RejectionsTodayBatch':'Rejeitos',
	'A029FilterStop':'Filtro',
	'A030RotaryDriveStop':'ROTO-PACKER®',
	'A031ProductFeedStop':'Alimentação de produtos',
	'A032RadimatStop':'RADIMAT®',
	'A033PalletizerStop':'Paletizador',
	'A034CompAirFault':'Ar comprimido',
	'A035TypeChange':'Mudanças de Tipos hoje',
	'A035TypeChangeBatch':'Mudanças de Tipos',
	'A036ProductionRate':'Produtividade (teórica)',
	'A037SpeedRelated':'Sacos / Hora',
	'A038UnitsPerHour':'Unidades / hora',
	'A039GoodWeights':'Boa produção',
	'A040ScissorHose':'Mangote da tesoura',
	'A041ConnectingHose':'Mangote de Ligação',
	'A042ConeGasket':'Junta de Cone',
	'A043AerationPlates':'Placas de Aeração',
	'A043ScissorHose':'Mangote da tesoura',
	'A044ConnectingHose':'Mangote de Ligação',
	'A044RegulationValve':'Válvula de Regulação Ar de Transporte',
	'A045ConeFeeding':'Alimentação da válvula do cone',
	'A045ConeGasket':'Junta de Cone',
	'A046AerationPlates':'Placas de Aeração',
	'A046BagTransBelt':'Correia de transporte de Sacos',
	'A047BagTransBearing':'Roldanas do Canhão ',
	'A047RegulationValve':'Válvula de Regulação',
	'A048ConeFeeding':'Alimentação da válvula do cone',
	'A048SuctionCup':'Ventosas do Aplicador de Sacos',
	'A049BagTransBelt':'Correia de Transporte de Sacos',
	'A049BagTransCyl':'Cilindro do Transporte do saco',
	'A050BagTransBearing':'Rolamento de inserção',
	'A050SucRailCyl':'Cilindro do Trilho de Sucção',
	'A051SuctionCup':'Ventosas do Aplicador de Sacos',
	'A051VacuumPump':'Bomba de vácuo',
	'A052BagTransCyl':'Cilindro do Transporte do saco',
	'A052BagTransCylinde':'Cilindro do Transporte do saco',
	'A052CableHightAdj':'Cabo de ajuste de altura da sela',
	'A053HullMinSensor':'Hull MIN-Sensor',
	'A053SucRailCyl':'Cilindro do Trilho de Sucção',
	'A053SucRailCylinder':'Cilindro do Trilho de Sucção',
	'A054Last48Hours':'Últimas 48 horas',
	'A054VacuumPump':'Bomba de vácuo',
	'A055CableHightAdj':'Secador de ar comprimido',
	'A055Last14Days':'Últimos 14 dias',
	'A056HullMinSensor':'Soprador de Pisten',
	'A056off':'fora',
	'A057InOperation':'Em operação',
	'A058Faulty':'Com defeito',
	'A059InMaintenance':'Em manutenção',
	'A060HourlyValues':'Taxas horárias para',
	'A061DischargesTotal':'Descarga Total',
	'A062CreationDate':'Data de criação',
	'A062Period':'Período',
	'A063To':'para',
	'A064BatchValue':'Valores de lote',
	'A064DailyValue':'Valores Diários',
	'A064DailyValues':'Valores diários para',
	'A065supply':'fornecem',
	'A066Transport':'Transporte',
	'A067Feeding':'Alimentando',
	'A068PackagesMissing':'Pacotes ausentes',
	'A069NoHistoricalData':'Não há dados históricos para o período entre',
	'A070And':'e',
	'A071Available':'Disponivel',
	'A072ReallyReset':' Realmente resetar?',
	'A073Details':'Detalhes',
	'A074General':'Geral',
	'A075Machines':'Máquinas',
	'A076Dashborad':'painel de controle',
	'A077Administration':'Administração',
	'A078UserRoles':'Usuários e Funções',
	'A079Companies':'Empresas',
	'A080Machines':'Máquinas (dev)',
	'A081Date':'Data',
	'A082Transport':'Transporte',
	'A083Containermissing':'Contêiner está faltando',
	'A084Availability':'Disponibilidade',
	'A085TypeChanges':'Mudanças de Tipos hoje',
	'A086EmptyRejects':'Rejeitador de Sacos Vazio Hoje',
	'A086EmptyRejectsBatch':'Rejeitador de Sacos Vazio',
	'A087FullRejects':'Sacos cheios rejeitados hoje',
	'A087FullRejectsBatch':'Sacos cheios rejeitados',
	'A088OpRelease':'Liberação inicial',
	'A089ProdFeedStop':'Feed de produto STOP',
	'A090AuxDrive':'Drive Auxiliar',
	'A091Consumables':'Consumíveis',
	'A092DischLineStop':'Linha de Descarga STOP',
	'A093TypeChange':'Tipo de mudança',
	'A094DailyProduction':'Produção hoje',
	'A094DailyProductionBatch':'Produção',
	'A095Energy':'Energia',
	'A096EffectivePower':'Potência Efetiva',
	'A097ApparentPower':'Potencia aparente',
	'A098CompAirFlow':'Taxa de fluxo de ar comprimido',
	'A099ResetFailed':'Falha ao redefinir métrica',
	'A1000Spanish':'Español',
	'A1000UploadMaintenanceAid':'Carregar ajudas de manutenção',
	'A1001UploadMaintenanceAidSuccess':'Auxílios de suporte enviados com sucesso',
	'A1002UploadMaintenanceAidFailure':'Erro ao carregar ajudas de manutenção',
	'A1005MachineServiceRequest':'Requisiçao de serviço',
	'A1006RequestService':'Solicitação de serviço',
	'A1007ServiceContract':'Contrato de serviço',
	'A1008ServiceRequestSuccesful':'Solicitação de serviço processada com sucesso',
	'A1009ServiceRequestFailure':'Erro na solicitação de serviço',
	'A100AcessDenied':'Acesso negado.',
	'A100AlertmailSubject':'Alerta de QSI: MACHINE_NAME',
	'A1010LogBook':'Livro de registro',
	'A1011CreateLogBook':'Criar item no livro de registro',
	'A1012EditLogBook':'Editar item do livro de registro',
	'A1013LogBookItemType':'Tipo de item do livro de registro',
	'A1014LogBookItem':'Item do livro de registro',
	'A1015LogBookItemSaved':'Item do diário salvo',
	'A1016ErrorInSavingLogBookItem':'Erro ao salvar o item do livro de registro',
	'A1017ErrorInRetrievingLogBookItem':'Erro ao recuperar o log',
	'A1018ErrorInDeletingLogBookItem':'Erro ao excluir o item do livro de log',
	'A1019LogBookItemDeleted':'Item do livro de registro excluído',
	'A101MailSalutation':'Olá USER_NAME,',
	'A101ResetSuccessful':'O sistema métrico foi redefinido ',
	'A1020LogBookItemIsDone':'Feita',
	'A1021LogBookItemType':'Tipo de item',
	'A1022ServiceEmail':'E-mail de serviço',
	'A1023CannotMakeServieRequest':'Você não pode solicitar serviços, pois esta máquina não possui nenhum e-mail de serviço',
	'A1024MaxUsers':'Número máximo de utilizadores',
	'A1024MaxUsersReached':'O limite máximo de usuários da empresa atingiu',
	'A1025AtLeast8Characters':'pelo menos 8 caracteres',
	'A1027AtLeast1Lowercase':'pelo menos 1 letra minúscula',
	'A1028AtLeast1Number':'pelo menos 1 número (0-9)',
	'A1029AtLeast1SpecialChar':'pelo menos 1 caractere especial (!,. [])',
	'A102CompanyAddressNewtec':'NEWTEC BAG PALLETIZING <br>32 avenue de Suisee <br>ZI Ile Napoléon - BP 256 <br>68315 Illzach cedex - France',
	'A102CompanyAddressAventus':'AVENTUS GmbH & Co. KG <br>Katzheide 33 <br>D-48231 Warendorf <br>Germany / Deutschland',
	'A102CompanyAddressNiagaraCanada':'HAVER & BOECKER Niagara Canada <br>225 Ontario St. <br>St. Catharines, ON, L2R 7B6 <br>Canada',
	'A102CompanyAddressNiagaraChile':'HAVER & BOECKER Andina <br>Av. El salto 4001, piso 7, of. 71 Comuna Huechuraba <br>8580641 – Santiago <br>Chile',
	'A102CompanyAddressNiagaraBrazil':'HAVER & BOECKER NIAGARA Latinoamericana <br>Av. Dra. Celia Marli S. Salgado Matos, 192 <br>Pedro Leopoldo - MG - 33600-000 <br>Brazil',
	'A102CompanyAddressNiagara':'HAVER & BOECKER NIAGARA <br>Robert-Bosch-Straße 6 <br>D-48153 Münster <br>Germany / Deutschland',
	'A102CompanyAddressFeige':'Feige FILLING GmbH <br> Rogen 6a <br> D-23843 Bad Oldesloe <br> Germany / Deutschland',
	'A102CompanyAddressH_B':'HAVER & BOECKER OHG <br>Carl-Haver-Platz 3 <br>D-59302 Oelde <br>Germany / Deutschland',
	'A102CompanyAddressBehnBates':'Behn Bates Maschinenfabrik GmbH & Co. KG <br> Robert-Bosch Str. 6 <br> 48153 Münster <br> Germany',
	'A102CompanyEmailAventus':'hotline@aventus.global',
	'A102CompanyEmailNiagaraCanada':'info@haverniagara.ca',
	'A102CompanyEmailNiagaraChile':'haverandina@haverniagara.cl',
	'A102CompanyEmailNiagaraBrazil':'info@haverniagara.com.br',
	'A102CompanyEmailNiagara':'info@haverniagara.com',
	'A102CompanyEmailNewtec':'service@newtecbag.com',
	'A102CompanyEmailFeige':'hotline@feige.com',
	'A102CompanyEmailH_B':'hotline@haverboecker.com',
	'A102CompanyEmailBehnBates':'service@behnbates.com',
	'A102CompanyPhoneNumberNewtec':'Phone: +33 389633753',
	'A102CompanyPhoneNumberFeige':'Phone: +49 4531 89 09 222',
	'A102CompanyPhoneNumberAventus':'Phone: +49 2581 4591 2222',
	'A102CompanyPhoneNumberNiagaraCanada':'Phone: +1 800-325-5993',
	'A102CompanyPhoneNumberNiagaraChile':'Telefon: +56 (2) 2307-0440',
	'A102CompanyPhoneNumberNiagaraBrazil':'Telefon: +55 (31) 3661-1371',
	'A102CompanyPhoneNumberNiagara':'Phone: +49 251 9793 164',
	'A102CompanyPhoneNumberH_B':'Phone: +49 2522 30 371 ',
	'A102CompanyPhoneNumberBehnBates':'Phone: +49 251 979- 0',
	'A102CompanyWebsiteNewtec':'www.newtecbag.com',
	'A102CompanyWebsiteFeige':'www.feige.com',
	'A102CompanyWebsiteAventus':'www.aventus.global',
	'A102CompanyWebsiteNiagaraCanada':'https://haverniagara.com',
	'A102CompanyWebsiteNiagaraChile':'https://haverniagara.com',
	'A102CompanyWebsiteNiagaraBrazil':'www.brazilniagara.com',
	'A102CompanyWebsiteNiagara':'www.haverniagara.com',
	'A102CompanyWebsiteH_B':'www.haverboecker.com',
	'A102CompanyWebsiteBehnBates':'www.behnbates.com',
	'A102MailContent':'<p>There occured an alert in one of your machines: <br> <br>Machine name: <b>MACHINE_NAME</b> <br>Alert message: <b>ALERT_TYPE</b> <br> Machine link:  <a href=\"MACHINE_LINK_TO_DETAIL\">MACHINE_LINK</a> <br> Alert value: ALERT_VALUE_TEXT <em>ALERT_VALUE_SUMMARY  </em><br> Alert time: ALERT_TIME_TEXT<br>ALERT_TIP</p><p style=\'font-size: 12px;\'>This e-mails was generated automatically because of a subscription at <a href=\'TYP_WEBSITE\' style=\'text-decoration: none; font-color: blue;\'>TYP_WEBSITE</a>. To unsubscribe login on the page and select <b>Settings</b> below the menu <b>Alerting</b>. <br>Please do not reply to this mail. For questions please contact <a href=\"mailto:COMPANY_EMAIL\">COMPANY_EMAIL</a>. <br> <br></p><p style=\'font-size: 12px;\'>Diese E-Mail wurde aufgrund eines Abos automatisch von <a href=\'TYP_WEBSITE\' style=\'text-decoration: none; font-color: blue;\'>TYP_WEBSITE</a> generiert. Zum Beenden des Abos loggen Sie sich auf der Seite ein und klicken unter <b>Alarmmeldungen</b> auf den Menüpunkt <b>Einstellungen</b>. <br>Bitte Antworten Sie nicht auf diese Mail. Bei Fragen können Sie sich an <a href=\"mailto:COMPANY_EMAIL\">COMPANY_EMAIL</a> wenden. <br> <br></p><p> Best regards, <br> <br>COMPANY_ADDRESS <br> <br>COMPANY_PHONE_NUMBER <br>E-Mail: <a href=\"mailto:COMPANY_EMAIL\">COMPANY_EMAIL</a> <br>Internet: <a href=\'COMPANY_WEBSITE\'>COMPANY_WEBSITE</a> <br><small style=\"color:white;\">Alert Source Id: ALERT_SOURCE_ID</small></p>',
	'A102TypeChanges':'Alterações do produto hoje',
	'A102TypeChangesBatch':'Alterações do produto hoje',
	'A102TYPWebsite':'https://trackyourplant.com',
	'A1030Description':'descrição',
	'A103EndOfBatch':'Fim do lote',
	'A103ResetmailSubject':'Redefinir: MACHINE_NAME',
	'A104FullPalletCount':'Paletes Cheios',
	'A104ResetMailContent':'<p>The occurred alert is resolved: </p><p>Machine name: <b>MACHINE_NAME</b><br>Alert message: <b>ALERT_TYPE</b><br>Machine link: MACHINE_LINK<br><p style=\'font-size: 12px;\'>This e-mails was generated automatically because of a subscription at <a href=\'TYP_WEBSITE\' style=\'text-decoration: none; font-color: blue;\'>TYP_WEBSITE</a>. To unsubscribe login on the page and select <b>Settings</b> below the menu <b>Alerting</b><br>Please do not reply to this mail. For questions please contact COMPANY_EMAIL.</p><p style=\'font-size: 12px;\'>Diese E-Mail wurde aufgrund eines Abos automatisch von <a href=\'TYP_WEBSITE\' style=\'text-decoration: none; font-color: blue;\'>TYP_WEBSITE</a> generiert. Zum Beenden des Abos loggen Sie sich auf der Seite ein und klicken unter Alarmmeldungen auf den Menüpunkt Einstellungen.<br>Bitte Antworten Sie nicht auf diese Mail. Bei Fragen können Sie sich an COMPANY_EMAIL wenden.</p><p>Best regards,<br>COMPANY_ADDRESS<br></p><p>COMPANY_PHONE_NUMBER<br>E-Mail: COMPANY_EMAIL<br>Internet: COMPANY_WEBSITE <br></p><small style=\"color:white;\">Alert Source Id: ALERT_SOURCE_ID</small>',
	'A105ConnectivityMailSubject':'Erro de conexão: MACHINE_NAME',
	'A105LoadEmptyPallets':'Alimentação de paletes vazias',
	'A106Fillings':'Enchimentos',
	'A106PLCMailSubject':'Alerta de PLC: MACHINE_NAME',
	'A107FillingsPerHour':'Enchimentos / Hora',
	'A107PLCResolvedMailContent':'<p>The following alert was resolved  at machine MACHINE_NAME:.<br><br> ALERT_TYPE <br><br> MACHINE_LINK <br><br> This e-mails was generated automaticly because of a subscription at <a href=\'TYP_WEBSITE\' style=\'text-decoration: none; font-color: blue;\'>TYP_WEBSITE</a>. To unsubscribe login on the page and select <b>Settings</b> below the menu <b>Alerting</b>.<br><p>Please do not reply to this mail. For questions please contact COMPANY_EMAIL.</p><br><br>Best regards,<br><br>COMPANY_ADDRESS<br><br>COMPANY_PHONE_NUMBER<br>E-Mail: COMPANY_EMAIL<br>Internet: COMPANY_WEBSITE ',
	'A108Availability':'Disponibilidade',
	'A108AvailabilityMailSubject':'Alerta de disponibilidade: MACHINE_NAME',
	'A109FillingsRemain':'Enchimentos restantes',
	'A110StartOfOrder':'Tempo de partida do lote',
	'A111ProductID':'ID do produto',
	'A112TargetWeight':'Peso alvo',
	'A113Kg':'Quilogramas',
	'A114CurrentOrder':'Ordem atual',
	'A115FillingCount':'Lote Real',
	'A116CycleTime':'Tempos de ciclo Máquina',
	'A117Total':'total',
	'A118CentreStation':'Estação de centragem',
	'A119N2Station':'Estação N2',
	'A120FillingStation':'Estação de enchimento',
	'A121SealingStation':'Estação de Fechamento',
	'A122Seconds':'Segundos',
	'A123FaultLength':'Falha da Maquina',
	'A124Minutes':'Minutos',
	'A124Minute':'Minutos',
	'A125ExtFaultLength':'Falhas Externas',
	'A126FillingCount':'Número Total de Recheios',
	'A127WithinTolerance':'Enchiemntos dentro da tolerância',
	'A128OutsideTolerance':'Enchiementos fora da tolerância',
	'A129BagManufacture':'Boa produção',
	'A130BagsPerHour':'Sacos / Hora',
	'A131BagQuality':'Qualidade do saco',
	'A132CorrPlacedbags':'Sacos corretamente colocados',
	'A133TwoAttempts':'2ª tentativa de abrir',
	'A134ThreeAttempts':'3ª tentativa de abrir',
	'A135DiscardeBags':'Sacos Rejeitados',
	'A136Faults':'Falhas, panes',
	'A137CompAir':'Ar comprimido',
	'A138ProductFeed':'Feed de produtos (pesador)',
	'A139EmptyBagMag':'Magazine de Sacos vazio',
	'A140Separation':'Separação',
	'A141Vacuum':'Vácuo',
	'A142FillingStation':'Estação de enchimento',
	'A143FullBagTransport':'Transporte de sacos cheios',
	'A144BagSealing':'Selagem de sacos',
	'A145Disrupt':'Interrupções',
	'A145AccessDoor':'Porta de acesso',
	'A145DSafetyLightBarrier':'Barreira luminosa de segurança',
	'A145UpstreamSupply':'Fornecimento a montante',
	'A145EmergencyStop':'Parada de emergência',
	'A146BunChangeAbs':'Número de alterações no pacote total',
	'A147BunChangeToday':'Número de alterações no pacote hoje',
	'A147BunChangeTodayBatch':'Número de alterações no pacote',
	'A148BagsPerBundle':'Número de malas por pacote',
	'A149TypeChanges':'Mudanças de Tipos',
	'A149TypeChangesBatch':'Mudanças de Tipos hoje',
	'A150FillingTime':'Tempo de enchimento',
	'A151DosingUnit1':'Feed de produtos',
	'A152DosingUnit2':'Feed de produtos 2',
	'A153AerInletBox1':'Caixa de entrada de aeração',
	'A154AerInletBox2':'Caixa de Entrada de Aeração 2',
	'A154DosingUnit1':'Feed de produtos',
	'A155DosingUnit2':'Feed de produtos 2',
	'A155RotaryDrive':'Drive rotativo',
	'A156AerInletBox1':'Caixa de entrada de aeração',
	'A156DischargeBelt':'Correia Extratora',
	'A157AerInletBox2':'Caixa de Entrada de Aeração 2',
	'A157AlignmentBelt':'Correia de Alinhador de Sacos',
	'A158BagCleaning':'Correia do Limpador de Sacos',
	'A158RotaryDrive':'Drive rotativo',
	'A159AccelerationBelt':'Correia de aceleração',
	'A159DischargeBelt':'Correia de descarga',
	'A160AlignmentBelt':'Correia de Alinhamento de Sacos',
	'A160CheckBelt':'Correia da Check-Weigher',
	'A161BagCleaning':'Limpeza do saco',
	'A161FlatBelt':'Transportador de correia plana',
	'A162AccelerationBelt':'Correia de aceleração',
	'A162RejectionBelt':'Cinto de rejeição',
	'A163CheckBelt':'Correia da Check-Weigher',
	'A163RejectionDrive':'Subir motor do rejeitador',
	'A164FlatBelt':'Transportador de correia plana',
	'A164RotaryCutter':'Triturador de sacos',
	'A165RejectionBelt':'Cinto de rejeição',
	'A165ScreenDrum':'Cilindro de tela',
	'A166RejectionDrive':'Subir motor do rejeitador',
	'A166Welding':'Dispositivo de solda',
	'A167Cutter':'Cortador',
	'A167RotaryCutter':'Triturador de sacos',
	'A168ScreenDrum':'Cilindro de tela',
	'A168SuctionCup':'Ventosas',
	'A169Brakes':'Freios',
	'A170VacuumPump':'Bomba De Vácuo',
	'A171Filter':'Extração de Filtro',
	'A172BagDisConveyor':'Distribuição de Sacos Conv.',
	'A173BagTurnConveyor':'Transportador de giro de Saco',
	'A174BagTurnCross':'Cruz de viragem de saco',
	'A175BagDisConveyor':'Distribuição de Sacos Conv.',
	'A175BagTurnClamp':'Braçadeira Giratória',
	'A176BagTurnConveyor':'Transportador de giro de Saco',
	'A176GroupingBelt':'Correia de agrupamento',
	'A177BagTurnCross':'Cruz de viragem de saco',
	'A177RowPusher':'Cilindro de Expulsao  ',
	'A178DoubleStripPlate':'Placa de Removedor Duplo',
	'A179ConformingPlate':'Placa de conformação',
	'A179GroupingBelt':'Correia de agrupamento',
	'A180GantrySafety':'Segurança do pórtico',
	'A180RowPusher':'Cilindro de Expulsao  ',
	'A181DoubleStripPlate':'Placa de Removedor Duplo',
	'A181Gantry':'Pórtico',
	'A182ConformingPlate':'Placa de conformação',
	'A182PalletAlignment':'Alinhamento de Paletes Vazios',
	'A183GantrySafety':'Segurança do pórtico',
	'A183PalletRoller1':'Transportador de rolos de paletes',
	'A184Gantry':'Pórtico',
	'A184PalletDispenser':'Dispensador de Paletes',
	'A185PalletAlignment':'Alinhamento de Paletes Vazios',
	'A185PalletRoller2':'Transportador de rolos de paletes',
	'A186PalletRoller1':'Transportador de rolos de paletes',
	'A186PalletRoller3':'Transportador de rolos de paletes',
	'A187CyclesChain':'Corrente de Ciclos',
	'A187PalletDispenser':'Dispensador de Paletes',
	'A188BundlePusher':'Cilindro do Empurrador de Sacos',
	'A188PalletRoller2':'Transportador de rolos de paletes',
	'A189BundlePartition':'Cilindro da mesa de Fardo',
	'A189PalletRoller3':'Transportador de rolos de paletes',
	'A190BagLifter':'Cilindro do Levantador de saco',
	'A190CyclesChain':'Corrente de Ciclos',
	'A191BundlePusher':'Cilindro do Empurrador de Sacos',
	'A191PressRolls':'Cilindro do bobina pressionadora',
	'A192BundlePartition':'Cilindro da mesa de Fardo',
	'A192OpeningStation':'Cilindro da Estação de Abertura',
	'A193BagApplicator':'Cilindro aplicador de saco',
	'A193BagLifter':'Cilindro do Levantador de saco',
	'A194BagSupport':'Cilindro do Prende Saco',
	'A194PressRolls':'Cilindro do bobina pressionadora',
	'A195OpeningStation':'Cilindro da Estação de Abertura',
	'A195Pusher':'Cilindro Expulsor',
	'A196BagApplicator':'Cilindro aplicador de saco',
	'A196StretchPliers':'Cilindro das pinças de estiramento',
	'A197BagSupport':'Cilindro do Prende Saco',
	'A197FillingStation':'Cilindros do modulo de Enchimento ',
	'A198FilledBagGripper':'Cilindro da Garra de saco cheio',
	'A198Pusher':'Cilindro Expulsor',
	'A199StretchPliers':'Cilindro das pinças de estiramento',
	'A199SucBagSeperation':'Separação do saco da ventosa',
	'A200FillingStation':'Cilindros do modulo de Enchimento ',
	'A200SucOpenStation':'Estação de abertura da ventosa',
	'A201FilledBagGripper':'Cilindro da Garra de saco cheio',
	'A201Remaininghours':'Horas restantes',
	'A202PriceTotal':'Peça no total',
	'A202SucBagSeperation':'Separação do saco da ventosa',
	'A203RemainingNumberFillings':'Recheios Restantes',
	'A203SucOpenStation':'Estação de abertura da ventosa',
	'A204SafetyDoor':'Porta do interruptor de segurança',
	'A205SafetyLightBarrier':'Cortina de luz de segurança',
	'A206BagTransport':'Transporte de sacos',
	'A207EmptyPalletTrans':'Transporte de paletes vazios',
	'A208Palletizer':'Paletizador',
	'A209FullPalletTrans':'Transporte de paletes cheios',
	'A210NumberTodayPiece':'Número hoje / peça.',
	'A211Programme':'Programa',
	'A212Efficiency':'Eficiência',
	'A213Loginsuccessful':'Login bem sucedido',
	'A214CurrentPerformance':'Performance atual',
	'A215CyclesToday':'Ciclos Totais Hoje',
	'A215CyclesTodayBatch':'Ciclos Totais',
	'A216RejectsToday':'Rejeitos hoje',
	'A216RejectsTodayBatch':'Rejeitos',
	'A217MetalDetectorToday':'Detector De Metal Hoje',
	'A217MetalDetectorTodayBatch':'Detector De Metal',
	'A218BrokenBagsToday':'Detecções de sacos rasgados hoje',
	'A218BrokenBagsTodayBatch':'Detecções de sacos rasgados hoje',
	'A219FaultPerShift':'Mudança Atual',
	'A220FaultBagManufacture':'Fabrico de sacos',
	'A221FaultBagFilling':'Enchimento de sacos',
	'A222FaultBagTransport':'Transporte de sacos',
	'A223FaultSealing':'Selagem de sacos',
	'A224FaultWeigher':'Estação de enchimento',
	'A225FaultPeriphery':'OutrosPeriferia',
	'A226Shift':'Mudança',
	'A227Container':'Recipiente',
	'A228Hours':'Horas',
	'A229NoPackage':'Falta de Sacos para Aplicação',
	'A230NoProduct':'Falta de Produto',
	'A231NoOutlet':'Máquina Girando Sem Expulsão de Sacos',
	'A232GoodPerformance':'Produtividade',
	'A233GoodWeights':'Boa produção',
	'A234NotPlacedBags':'Sacos Não Aplicados',
	'A234NotPlacedBagsAutomatic':'Sacos Não Aplicados Automatic',
	'A235CollectiveFaultsToday':'Falhas Coletivas Hoje',
	'A235CollectiveFaultsTodayBatch':'Falhas Coletivas',
	'A236Applicator':'Aplicador',
	'A237NetWeigher':'Pesador Líquido',
	'A238IncorrectWeight':'Peso Incorreto',
	'A239Hours':'Horas',
	'A240QrCode':'Código QR',
	'A241OpenBags':'Sacos Abertos',
	'A242FaultCheckWeigher':'Correia da Check Weigher',
	'A243DashboardAllMachines':'Painel de controle / todas as máquinas',
	'A244DashboardState':'Estado',
	'A245DashboardMachineStateRunning':'Ligado',
	'A246DashboardMachineStateOffline':'Desligado',
	'A247DashboardMachineStateMaintenance':'Manutenção necessária',
	'A248DashboardMachineStateError':'Falha',
	'A249SortChangeToday':'Mudanças de Tipos hoje',
	'A249SortChangeTodayBatch':'Mudanças de Tipos',
	'A250EmptyBagMagazineToday':'Recarregar Emp. Bag Mag.Hoje',
	'A250EmptyBagMagazineTodayBatch':'Recarregar Emp. Bag',
	'A251FaultCheckWeigher':'Falhas hoje',
	'A252IVTSTOP':'INTEGRA®',
	'A253N/A':'N / D',
	'A254CyclePumpFS1':'Bomba de Ciclo FS 1',
	'A255CyclePumpFS2':'Bomba de Ciclo FS 2',
	'A256CyclePumpFS3':'Bomba de Ciclo FS 3',
	'A257ConHoseFS1':'Mangueira de conexão FS 1',
	'A258ConHoseFS2':'Mangueira de conexão FS 2',
	'A259ConHoseFS3':'Mangueira de conexão FS 3',
	'A260InfSleeveFS1':'Luva Inflável FS 1',
	'A261InfSleeveFS2':'Luva inflável FS 2',
	'A262InfSleeveFS3':'Luva inflável FS 3',
	'A263BundlePusher':'Empurrador de sacos',
	'A264VacuumUnit':'Unidade de vácuo',
	'A265TravellingDrive':'Unidade de viagem',
	'A266CenteringUnit':'Unidade de Centralização',
	'A267EmptyBagMagazine':'Magazine de Sacos vazio',
	'A268BeltPlant':'Planta de correia',
	'A269IvtTurbine':'Turbina De Enchimento',
	'A270IvtTripSaddle':'Sela de Tripper',
	'A271IvtCoarseFeed':'Dosagem de ração grossa',
	'A272IvtBagHolder':'Cilindro Prendedor de Sacos',
	'A273IvtFillBoxAir':'Aeração de caixa de enchimento na tampa',
	'A274IvtFillChanAir':'Fluidização da Caixa de Enchimento',
	'A275IvtInflSleeve':'Luva inflável',
	'A276IvtInletBoxAir':'Caixa de entrada de aeração',
	'A277IvtFillTubeBlow':'Sopro no Bico de Enchimento',
	'A278IvtOutlChanAir':'Ventilação do canal de saída',
	'A279IvtPusher':'Cilindro de Expulsao  ',
	'A280IvtSaddleHeight':'Ajuste de altura de sela',
	'A281IvtCloseAnvil':'Fechar bigorna',
	'A282IvtBagSeal':'Selagem de sacos',
	'A283IvtFineFeedAdj':'Ajuste fino de alimentação',
	'A284Interrupts':'Interrupções hoje',
	'A284InterruptsGeneralPage':'Interrupções hoje',
	'A284InterruptsBatch':'Interrupções',
	'A285BagCounterShift':'Contador de sacos por turno',
	'A286WrongBags':'Sacos Não Aplicados',
	'A287ReelChanges':'Carretel muda',
	'A287ReelChangesBatch':'Carretel muda',
	'A288VibrationDuration':'Duração Estendida da Vibração',
	'A289Total':'Total',
	'A290HopperMin':'pelo mínimo do funil',
	'A291ManualActivation':'por ativação manual',
	'A292FillingRelease':'Liberação de Enchimento',
	'A293EGF':'EGF®',
	'A294PalletTrasport':'Transporte de paletes',
	'A295SafetyCircuit':'Circuito de segurança',
	'A296FltCompAir':'Falhas do ar comprimido hoje',
	'A296FltCompAirBatch':'Falhas do ar comprimido',
	'A297StrokesPump':'Strokes Pó Bomba Hoje',
	'A297StrokesPumpBatch':'Strokes Pó Bomba',
	'A298MaxStrokeDur':'Duração máxima do curso hoje',
	'A298MaxStrokeDurBatch':'Duração máxima do curso',
	'A299AvgStrokes':'Média Golpes por saco hoje',
	'A299AvgStrokesBatch':'Média Golpes por saco',
	'A300InternalPressure':'Pressão interna',
	'A301Max':'máximo',
	'A301MessageAdministration':'Administração de Mensagens',
	'A302ErrorNumber':'Número do erro',
	'A302Millibar':'Millibar',
	'A303ActivateAlertMail':'Ativar e-mail de alerta',
	'A303SetValueMax':'Definir valor máximo',
	'A304AlertText':'Texto de alerta',
	'A304SetValueMin':'Definir valor mínimo',
	'A305ActivateResolvedMail':'Ativar e-mail resolvido',
	'A305SetValueChanged':'Suas alterações foram salvas.',
	'A305SetValueChangedRefreshWindow':'Suas alterações foram salvas. As alterações serão visíveis após a atualização da janela do navegador.',
	'A306KW':'kW',
	'A306Resolved Text':'Texto Resolvido',
	'A306ResolvedText':'Texto Resolvido',
	'A307AddMessage':'Adicionar mensagem',
	'A307KVA':'kVA',
	'A308EditMessage':'Editar mensagem',
	'A308Nm3PerH':'Nm³ / h',
	'A309KWH':'kWh',
	'A310NM3':'Nm³',
	'A311CompAirVol':'Volume de ar comprimido',
	'A312ActiveEnergy':'Energia ativa',
	'A313ReactivePower':'Potência reativa',
	'A314ChartConfiguration':'Configuração de cartão',
	'A315SetDateValueMax':'Definir data máxima',
	'A316SetDateValueMin':'Definir data mínima',
	'A317InvalidData':'Dados inválidos',
	'A318DeleteUser':'Você realmente quer apagar o usuário?',
	'A319EnterAllFields':'Por favor, preencha todos os campos de dados!',
	'A320EnterValidEmail':'Por favor insira o endereço de e-mail válido!',
	'A321DeleteCompany':'Você realmente quer excluir a empresa?',
	'A322CouldNotDeleteUser':'O usuário administrador não pôde ser excluído! Por favor, mude para o usuário padrão primeiro.',
	'A323LoginSuccess':'Login bem sucedido!',
	'A324Selected':'selecionado',
	'A325Total':'Total',
	'A326Reset':'Restabelecer',
	'A327Reporting':'Relatórios',
	'A328ReportingActive':'Ativo',
	'A329ReportingActiveHelpPrefix':'A ativação enviará relatórios regulares para',
	'A330ReportingActiveHelpSuffix':'enviado.',
	'A331ReportingFrequency':'Freqüência',
	'A332ReportingFrequencyDaily':'diariamente',
	'A333ReportingFrequencyWeekly':'semanal',
	'A334ReportingFrequencyMonthly':'por mês',
	'A335ReportingDynContent':'Conteúdo Dinâmico',
	'A336ReportingDynContIncludeElapsedMI':'Incluir intervalos de manutenção decorridos',
	'A337ReportingLanguage':'Linguagem',
	'A338ReportingProdDayEnd':'Final do dia da produção',
	'A339ReportingFirstReport':'Data do primeiro relatório',
	'A340CardCtxMenuReport':'Incluir no relatório',
	'A341CardCtxMenuHistoReport':'Incluir como histograma no relatório',
	'A342CardCtxMenuReset':'Redefinir dados de métrica',
	'A343CardCtxMenuSettings':'Definições',
	'A344CardLabelReport':'Relatório',
	'A345CardLabelHistoReport':'Relatório histograma',
	'A346CardLabelMaintenReport':'Relatório de manutenção',
	'A347ReportSettings':'Definições',
	'A348Alerting':'Alerta',
	'A349AlertingSettings':'Definições',
	'A350AlertingActive':'Ativo',
	'A351AlertingActiveHelpPrefix':'A ativação enviará e-mails de alerta para',
	'A352AlertingActiveHelpSuffix':'para qualquer um dos tipos de alerta selecionados abaixo.',
	'A353AlertingTypes':'Notificar em',
	'A354AlertTypeMainten':'intervalo de manutenção decorrido por:',
	'A355AlertTypeAvail':'Disponibilidade',
	'A356AlertTypePLC':'Acionador PLC',
	'A357AlertTypeConError':'erro de conexão',
	'A358DeleteAdminUser':'O usuário administrador não pôde ser excluído.',
	'A359MaintAids':'Auxílios de manutenção',
	'A360MaintInt':'Intervalos de manutenção',
	'A361ChooseMaschine':'Escolha a máquina',
	'A362Video':'Vídeo',
	'A363InstructionManual':'Manual de instruções',
	'A364ExplodedviewDrawing':'Desenho Vista Explodida',
	'A364SparePartCatalog':'Catálogo de peças de reposição',
	'A365OpenVideoHelp':'Abra o Video Help',
	'A366OpenInstructionManual':'Abra o manual de instruções',
	'A367OpenExploadedView':'Vista explodida aberta',
	'A368ResetMaschineData':'Redefinir dados da maquina',
	'A369BotWeld':'soldadura de costura inferior',
	'A370BotWeldTef':'soldagem de vedação de fundo de teflon',
	'A371TopWeld':'solda de costura superior',
	'A372TopWeldTef':'solda de costura de teflon',
	'A373LeftWeld':'canto de soldagem esquerda',
	'A374LefWeldTef':'canto de solda de teflon à esquerda',
	'A375RigWeld':'sistema de soldadura de canto direito',
	'A376RigWeldTef':'direito canto de solda de teflon',
	'A377CutKnife':'faca de corte',
	'A378ReallyClearReport':'Esta ação remove todos os itens do relatório e não pode ser desfeita. Você realmente gosta de remover todos os itens do relatório?',
	'A379ProdCurrShift':'Production Current Shift',
	'A380ReallyDeleteItemReport':'Esta ação remove o item do relatório e não pode ser desfeito. Você realmente gosta de remover o item do relatório?',
	'A380TypeCurrent':'Tipo atual',
	'A381SampleBags':'Sacos de Amostra Rejeitados',
	'A382OpenBags':'Sacos Abertos Rejeitados',
	'A383IncorrectBags':'Pesos incorretos Rejeitados',
	'A384NotPlacedBagsMan':'Manual de malas não colocadas',
	'A385Spout1':'bico de enchimento de manutenção 1',
	'A386LastUpdate':'Ultima atualização:',
	'A386Spout2':'bico de enchimento de manutenção 2',
	'A387Spout3':'bico de enchimento de manutenção 3',
	'A388Spout4':'bico de enchimento de manutenção 4',
	'A389LastUpdate':'última atualização',
	'A390FilledBagTrans':'Transporte de sacos cheios',
	'A391ConveyerSystem':'Sistema transportador',
	'A392BeltBundleTrans':'Correias do Aplicador de Sacos',
	'A393BagHolderBuffer':'Amortecedor do prendedor de saco',
	'A394SlidePlates':'placas deslizantes',
	'A395ImpellerShaft':'Turbina de Enchimento Completa',
	'A396ShaftAssembly':'Mancal Inferior',
	'A397ThreePosCylinder':'cilindro de três posições',
	'A398DateFormatNoSeconds':'MM-DD-YYYY h: mm ',
	'A399DateFormat':'MM-DD-YYYY hh: mm: ss ',
	'A399DateFormatAngularPipe':'MM-dd-yyyy hh: mm: ss ',
	'A400ConveyorSystem':'Sistema de transporte',
	'A400History':'História',
	'A401ReportHistory':'Comunicar histórico',
	'A402ReportId':'ID do relatório',
	'A403ReportGeneratedTime':'Relatório gerado pelo tempo',
	'A404DosingFeeder':'Alimentador de Dosagem',
	'A404ReportGeneratedBy':'Relatório gerado por',
	'A405DownloadReport':'Download do relatório',
	'A406TriggeredHistoryReport':'Geração de relatório iniciada',
	'A407ViewReport':'Visualizar relatório',
	'A408BagHolder':'Suporte de sacos',
	'A408BagHolder ':'Suporte de sacos',
	'A414BundleTransport':'Transporte de pacote',
	'A415HoistingDevice':'Içando a unidade',
	'A416PlacerArm':'Braço Placer',
	'A417CrossProcess':'Processo cruzado',
	'A418OpeningStation':'Estação de Abertura',
	'A421Claw':'garra',
	'A422WeightData':'Dados de peso Ø',
	'A423StdDevData':'Dados de desvio padrão',
	'A430kg':'kg',
	'A431lb':'Libra',
	'A432t':'t',
	'A433klb':'klb',
	'A434g':'g',
	'A435BagManufacture':'Fabricação de sacos',
	'A436Aux_Pump':'Bomba de vácuo',
	'A437TopWelding':'Solda de topo',
	'A438PunchingDeviceWelding':'Punho de perfuração',
	'A439Aux_Vibrator1':'Vibrador 1',
	'A440Aux_Vibrator2':'Vibrador 2',
	'A441Aux_Vibrator3':'Vibrador 3',
	'A442Truck_Loading':'Carregamento de caminhão',
	'A443Truck_Available':'Nenhum caminhão disponível hoje',
	'A444FS1':'Estação de enchimento 1',
	'A445FS2':'Estação de enchimento 2',
	'A446FS3':'Estação de enchimento 3',
	'A447FS4':'Estação de enchimento 4',
	'A448FS5':'Estação de enchimento 5',
	'A449FS6':'Estação de enchimento 6',
	'A450FS7':'Estação de enchimento 7',
	'A451FS8':'Estação de enchimento 8',
	'A452FS9':'Estação de enchimento 9',
	'A453FS10':'Estação de enchimento 10',
	'A454FS11':'Estação de enchimento 11',
	'A455FS12':'Estação de enchimento 12',
	'A456FS13':'Estação de enchimento 13',
	'A457FS14':'Estação de enchimento 14',
	'A458FS15':'Estação de enchimento 15',
	'A459FS16':'Estação de enchimento 16',
	'A460OeeValue':'Valor OEE',
	'A460Oee':'OEE',
	'A460PushAlert':'<h1>Alert: ALERT_TYPE</h1><p>Machine name: <b>MACHINE_NAME</b> <br>Alert message: <b>ALERT_TYPE</b>',
	'A461OeeAvailable':'Fator de Disponibilidade',
	'A461PushAlertResolved':'<h1>Resolved: ALERT_TYPE</h1><p>Machine name: <b>MACHINE_NAME</b> <br>Alert message: <b>ALERT_TYPE</b>',
	'A462OeePerformance':'Fator de Desempenho',
	'A463DowntimePlaned':'Tempos de inatividade planejados',
	'A464DowntimeMachine':'Tempos de inatividade da máquina',
	'A465DowntimeOther':'Outros tempos de inatividade',
	'A466OperatingFactor':'Fator Operacional',
	'A467Throughput':'Fator de rendimento',
	'A468OeeRejects':'Rejeitos Hoje',
	'A469OeeQuality':'Fator de qualidade',
	'A470OeeDowntime':'Tempos de inatividade',
	'A471RejectsTotal':'Rejeitos total',
	'A472RejectsIncorrect':'Rejeitos peso errado',
	'A473RejectsOpen':'Rejeitos saco aberto',
	'A474RejectsSample':'Rejeitos saco de amostra',
	'A475RejectsMetal':'Rejeitos detecção de metal',
	'A476ConfigTable':'Configuração',
	'A477OEERowActive':'Ativo',
	'A478OEEProduction':'Produção',
	'A479OEEBreak':'Pausa',
	'A480OEEMaint':'Manutenção',
	'A481OEETypeChg':'Mudança de tipo',
	'A482OEEOthers':'Outros tempos de inatividade',
	'A483OEEFrequ':'Frequência',
	'A484OEEUnique':'Único',
	'A485OEEDaily':'Diariamente',
	'A486OEEWeekly':'Semanalmente',
	'A487Days':'Dias',
	'A488Monday':'Segunda-feira',
	'A489Tuesday':'Terça-feira',
	'A490Wednesday':'Quarta-feira',
	'A491Thursday':'Quinta-feira',
	'A492Friday':'Sexta-feira',
	'A494Saturday':'Sábado',
	'A498Comment':'Comente',
	'A495Sunday':'Domigo',
	'A496From':'A partir de',
	'A497To':'Para',
	'A499TabMachineMessages':'Mensagem da máquina',
	'A500MMLive':'Mensagens ao Vivo',
	'A500VbeltDrive':'Correia de Transmissao em \"V\" rotativo',
	'A501MMStatistic':'Estatística de mensagens',
	'A501VbeltTurbine':'Correia de Transmissao em \"V\" turbina',
	'A502Impeller':'Rotor da turbina',
	'A502MMLive':'Mensagens de máquina ao vivo',
	'A503FillingTube':'Bico de Enchimento',
	'A503MMStatistic':'Estatística de mensagens da máquina',
	'A504BagGuide':'Chapa de Encosto',
	'A504Type':'Tipo',
	'A505Control':'Ao controle',
	'A505Saddle':'Sela de Sacos',
	'A506BoltBagChair':'Pino da Cadeira',
	'A506Number':'Número',
	'A507BushBagChair':'Bucha DU',
	'A507Message':'Mensagem',
	'A508Amount':'Resultar',
	'A508BearingPusher':'Fixação oscilante + Cavalete',
	'A509Duration':'Duração em minutos',
	'A509RearSlidePlate':'Placa Deslizante traseira',
	'A510SlideValve':'Faca de Corte',
	'A510TonsPerHour':'t/h',
	'A511FrontSlidePlate':'Place Deslizante frontal',
	'A511Tons':'Toneladas',
	'A512RepairKitSlidePlates':'Kit de Reparo do Registro',
	'A512RPM':'Rodadas por minuto',
	'A513BagChairChange':'Mudança de cadeira de sacos',
	'A513BagTransBeltServo':'Correia de transporte de sacos Servo',
	'A514InfeedBelt':'Correia de alimentação',
	'A515FlattBelt':'Correia do achatador',
	'A516BagTakingBelt':'Correia  de entrada de saco',
	'A517RollerConveyor':'Transportador de roletes',
	'A518LiftingGantry':'Torre de elevação',
	'A519LinearDrive':'Movimentação linear',
	'A520SwivelJoint':'Articulação giratória',
	'A521LiftingGantry':'Torre de elevação do transportador de roletes',
	'A522FullPallets':'Transportador de roletes de palet cheio',
	'A523EvacConveyor':'Trasportador de saída',
	'A524SafetyBarrier':'Barreiras de segurança / Sensores',
	'A525BasicCleaning':'Limpeza básica',
	'A526SafetyDevices':'Dispositivos de segurança',
	'A530CylSucBar':'cilindro da barra de sucção fechada',
	'A531FilledBagGripper1':'cilindro das pinças de saco cheio 1',
	'A532FilledBagGripper2':'cilindro das pinças de saco cheio 2',
	'A533CylCutKnife':'cilindro da faca de corte',
	'A534CylBotWelding':'cilindro sistema de solda de fundo',
	'A535SpoutLift':'levantamento de bico',
	'A536CylClampTopWeld':'Cilindro da barra de fixação soldagem superior',
	'A537CylTopWeld':'cilindro do sistema de soldagem do topo',
	'A538CylCornerWeld':'cilindro da soldagem de canto',
	'A539AdjHeightDischargebelt':'Correia de descarga de ajuste de altura',
	'A540EmptyBagTransport':'Transporte de saco vazio',
	'A541Virbator':'Horas de operação do vibrador',
	'A542CylCoolPlatesCornerWeld':'Cilindro de soldagem de canto de placas de resfriamento ',
	'A543CylBagGuide':'Cilindro da placa guia de saco ',
	'A544CylClampBotWeld':'Cilindro da braçadeira de soldagem do fundo da barra  ',
	'A545CylCoolBotWeld':'Cilindro de contato costura inferior de resfriamento  ',
	'A546CylFilledBagGripper3':'cilindro 3 pinças de saco cheio ',
	'A547CylStatBagGripper':'cilíndro pinças estacionárias de saco ',
	'A548CylEmptyBagGripper1':'cilindro 1 garra de saco vazio',
	'A549CylStretching':'cilindro do esticamento',
	'A550CylOpenSpout':'cilindro aberto bico de enchimento',
	'A551CylBagAplicatorLift':'cilindro elevador aplicador de sacos',
	'A552CylStretcherOpen':'cilindro  alicate esticador aberto',
	'A553CylStretcherInwards':'cilindro alicate esticador para dentro',
	'A554FoilTrackingDevice':'dispositivo de rastreamento de folha',
	'A555DischargeBelt2':'horas de operação da correia de descarga BP2',
	'A556CylStretching':'cilindro do dispositivo de esticamento',
	'A557CylEmptyBagGripper2':'cilindro pinça de saco vazio 2',
	'A558UnwindingDevice':'horas de operação desenrolando rolos',
	'A559BagLenghtAdj':'ajuste de comprimento de bolsa',
	'A560CylVibratorLift':'elevador vibratório',
	'A561CylFilmGuide':' cilindro do guia de filme',
	'A562CylPushFlaps':'cilindro abas de pressão',
	'A563CylWeldReelChanger':'trocador de bobina de solda',
	'A564CylWeldReelChangerTef':'trocador de bobina de solda de teflon',
	'A565DriveReelChanger':'trocador de bobina de acionamento',
	'A566DriveAdjCornerWeld':'soldagem de canto de ajuste de largura de unidade',
	'A567CylThreadingInwards':'cilindro dispositivo de rosca ventosas para dentro',
	'A568CylThreadingOutwards':'Cilindro dispositivo de rosca que limita as ventosas para fora',
	'A569CylThreadingDownwards':'Cilindro da placa de separação do dispositivo de rosqueamento para baixo',
	'A570CylSucRailClose':'cilindro da barra de sucção fechada',
	'A571DischargeBelt2':'correia de descarga BP2',
	'A572CylSpoutOpen':'cilindro aberto bico de enchimento',
	'A573Drives':'Drives',
	'A574Cylinders':'cilindros',
	'A575LowAttrition':'Baixo desgaste e rasgo',
	'A576HighAttrition':'Alto desgaste',
	'A577SetUpTimeAvBatch':'Tempo de configuração Ø',
	'A578SetUpTimeAv':'Tempo de configuração Ø hoje',
	'A579GrossTimeBatch':'Tempo bruto',
	'A580GrossTime':'Tempo bruto hoje',
	'A581SetUpTime':'Tempo de preparação',
	'A582Downtime':'Tempo de inatividade',
	'A583MonthBestPerf':'Melhor performance mensal',
	'A584PressureChamperSeal':'Câmara de pressão de vedação',
	'A585Aeration':'Aeração',
	'A586SpillageRejector':'Rejeitador de derramamento',
	'A587VibrationBottle':'garrafa vibradora',
	'A588VibrationTable':'vibrador ',
	'A589BagPlacing':'aplicação de saco',
	'A590RotaryFeeder':'eclusa celular',
	'A591TopSeamCleaning':'Limpeza da costura superior',
	'A592Beltplant1':'Planta de correia 1',
	'A593Beltplant2':'Planta de correia 2',
	'A594HydraulikPump':'bomba hidráulica',
	'A595DriveEBTS':' ',
	'A596HostingEBTS':' ',
	'A597Ventilator':' ',
	'A598RD':' ',
	'A599SpoutCoarse':' ',
	'A600RotaryGateCoarse':' ',
	'A601HostingVIC':' ',
	'A602SpoutFine':' ',
	'A603RotaryGateFine':' ',
	'A604HostingVSC1':' ',
	'A605HostingVSC2':' ',
	'A606FBTransfer':' ',
	'A607FBTHost':' ',
	'A608FBTswivel':' ',
	'A609FBTClamp':' ',
	'A610FBTransport':' ',
	'A611FBTransportClamp':' ',
	'A612HostBFS':' ',
	'A613SwivelBFS':' ',
	'A614SideGussetblade':' ',
	'A615HostTopwelding':' ',
	'A616CloseTopWelding':' ',
	'A617Folding1':' ',
	'A618Folding2':' ',
	'A619Folding3 ':' ',
	'A620PUD':' ',
	'A621PUDWeld':' ',
	'A622BeltReject':' ',
	'A623RejectingDevice':' ',
	'A624BagLenght':' ',
	'A624PerfToday':'Desempenho Hoje',
	'A625PerfTodayBatch':'Desempenho',
	'A626Output':'Produção Hoje',
	'A627OutputBatch':'Saída',
	'A628UnitperH':'S/h',
	'A629Percent':'%',
	'A630BagJunction':'Placa de junção de bolsas',
	'A631ToothedBelt':'Cinto dentado de tensão',
	'A632LevellingFoot':'Verificar pé de nivelamento RAD',
	'A633PolymerGuide':'Guia de polímero + rolo',
	'A634BallBushing':'Acoplador de bucha esférica',
	'A635ScissorHoseReplace':'Mangote da tesoura',
	'A636ConnectingHoseReplace':'Mangote de Ligação',
	'A637Bellow':'Proteção sanfonada de ajuste de altura da sela',
	'A638TipperFrame':'Quadro basculante ',
	'A639HosePressureChamber':'Verificar a câmara de pressão de vedação',
	'A640FlapFillingBox':'Verificar caixa de enchimento da aba',
	'A641RotoLock':'ROTO-LOCK®',
	'A642Hours':'Horas',
	'A643Temp':'°C',
	'A644Millisecond':'ms',
	'A645KgPerSecond':'kg/s',
	'A646RoundsperMinute':'rpm',
	'A647Meter':'m',
	'A648Millimeter':'mm',
	'A649Cubicmeter':'m³',
	'A650Liter':'l',
	'A651Counts':'Unidades ',
	'A652WeldingTemperature':'Temperatura de soldagem',
	'A653WeldingDuration':'Duração da soldagem',
	'A654FillingSystem':'Sistema de preenchimento',
	'A655EmailSignature':'Assinatura por e-mail',
	'A656NumberWasteBags':'Numero de saco rejeitado',
	'A657FreqConverter':'Conversor de freqüência',
	'A658ServoDrives':'Servo drives',
	'A656Voltage':'Voltagem',
	'A657Volt':'V',
	'A658U1':'U1',
	'A659U2':'U2',
	'A660U3':'U3',
	'A661U12':'U12',
	'A662U23':'U23',
	'A663U31':'U31',
	'A664Current':'Corrente elétrica',
	'A665Amps':'A',
	'A666I1':'I1',
	'A667I2':'I2',
	'A668I3':'I3',
	'A669Frequency':'Frequência',
	'A670Hertz':'Hz',
	'A671Pressure':'Pressão',
	'A672Bar':'bar',
	'A673Moisture':'ponto de orvalho de pressão',
	'A674AirFlowUnit':'m³',
	'A675AnnualConsum':'Consumo anual',
	'A676MonthlyConsum':'Consumo mensal',
	'A677FreeConsum':'Consumo',
	'A678PowerEfficiency':'Eficiência energética',
	'A679BagsPerKWH':'Sacos por kWh',
	'A681SinceReset':'Desde o reinício',
	'A682LastTimeSortUse':'Desde a última utilização',
	'A683SortNumber':'Número da variedade',
	'A684Overall':'Visão Geral',
	'A685AirEfficiency':'Eficiência de ar',
	'A686BagsPerAirFlowUnit':'Sacos por m³',
	'A687Jan':'Janeiro',
	'A688Feb':'Fevereiro',
	'A689Mar':'Março',
	'A690Apr':'Abril',
	'A691Mai':'Maio',
	'A692Jun':'Junho',
	'A693Jul':'Julho',
	'A694Aug':'Agosto',
	'A695Sep':'Setembro',
	'A696Oct':'Outubro',
	'A697Nov':'Novembro',
	'A698Dec':'Dezembro',
	'A699Year':'Ano',
	'A700EnterDateRange':'Introduza o intervalo de datas',
	'A701PowerConsumption':'Consumo de energia',
	'A702AirConsumption':'Consumo de ar',
	'A703FlowControlGate':'Porta de controle de flux',
	'A704BagDirectionConv':'Transportador de direção de bolsas',
	'A705BottomBearing':'Rolamentos inferiores',
	'A706PressureSwitch':'Interruptor de pressão',
	'A707ElecModules':'Módulos eletrônicos',
	'A708SpoutWeight':'Escala de componentes',
	'A709Search':'Busca',
	'A710Overweight':'Sacos com excesso de peso',
	'A711Underweight':'Sacos abaixo do peso',
	'A712RotaryDriveFreqConLoad':'Acionamento rotativo de conversores de freqüência',
	'A713MeanValueLoadInPercent':'Valor médio / Carga em porcentagem',
	'A714EmptyBagDischarge':'Descarga de sacos vazios',
	'A715Bellow1':'Folhetos',
	'A716TotalCounter':'Contador total',
	'A717Spout0Counter':'Posto de enchimento 0',
	'A718LSdirtyCounter':'barreira de luz CW sujo',
	'A719Peripherie':' ',
	'A720Robot':' ',
	'A721DepositBelt':' ',
	'A722RollerConveyor1':' ',
	'A723PalletCentering1':' ',
	'A724RollerConveyor2':' ',
	'A725PalletCentering2':' ',
	'A726GripperClamp':' ',
	'A727GripperMotor':' ',
	'A728AdjustmentCamera':' ',
	'A729BagSize':' ',
	'A730PalletSupply':' ',
	'A731Length':' ',
	'A732Width':' ',
	'A733DepalPallets':' ',
	'A734DepalBundles':' ',
	'A735RejectedBundles':' ',
	'A736BagsPerBundle':' ',
	'A737CountsOfDataPoints':' ',
	'A738EmptyKey':' ',
	'A739TrendMeanWeight':'Dados de peso Ø Tendência',
	'A740TrendSD':'Dados de desvio padrão Tendência',
	'A741PistonBlower1':'Soprador de Pisten 1',
	'A742PistonBlower2':'Soprador de Pisten 2',
	'A743RotaryFlap':'undefined',
	'A744CurrentLayer':'undefined',
	'A745CurrentBag':'undefined',
	'A746Days':'undefined',
	'A747LayerNo':'undefined',
	'A748BagNo':'undefined',
	'A749AnnualMaint':'undefined',
	'A750GPerSecond':'g/s',
	'A751SortName':'Número de tipo',
	'A752PressureTemp':'Temperatura do ar comprimido',
	'A901MachineCustomerOverview':'Visão geral de máquina / cliente',
	'A902Machines':'Máquinas',
	'A903Permissions':'Permissões',
	'A904CompanyName':'Nome da empresa',
	'A905MachineName':'Nome da maquina',
	'A906MachineType':'Tipo de máquina',
	'A907Order':'Ordem',
	'A908Action':'Açao',
	'A909Remove':'Remover',
	'A910ChooseCustomer':'Escolha o cliente',
	'A911ConnectMachines':'Conecte Máquinas',
	'A912User':'Do utilizador',
	'A913FirstName':'Primeiro nome',
	'A914Surname':'Sobrenome',
	'A915Login':'Login',
	'A916Roles':'Papéis',
	'A917Company':'Empresa',
	'A918CreateUser':'Criar usuário',
	'A919Username':'Nome de usuário',
	'A920Password':'Senha',
	'A921Name':'Nome',
	'A922ParentCompany':'Empresa-mãe',
	'A923Create':'Criar ',
	'A924Edit':'Editar',
	'A925SubCompany':'Subempresa',
	'A926Companies':'Empresas',
	'A927EditMachine':'Editar máquina',
	'A928CompaniesFunctionality':'Empresas / Funcionalidade',
	'A930SelectAll':'Selecionar tudo',
	'A931DetailView':'Visualização de detalhes',
	'A932EditUser':'Editar usuário',
	'A933Delete':'Excluir',
	'A934Cancel':'Cancelar',
	'A935Save':'Salve',
	'A936LoginFailed':'Logon com falha de credenciais ruins',
	'A936Malayalam':'Malayalam',
	'A937DisplayName1':'Nome de exibição 1',
	'A938DisplayName2':'Nome de exibição 2',
	'A939DisplayName3':'Nome de exibição 3',
	'A940ForgotPassword':'Esqueceu a senha',
	'A941ForgotText':'Sem problemas. Digite seu nome de usuário e você receberá um e-mail com um link que você pode usar para redefinir sua senha.',
	'A942Submit':'Enviar',
	'A943EmailAddress':'Endereço de e-mail',
	'A944ChangePassword':'Mudar senha',
	'A945CurrentPassword':'Senha atual',
	'A946NewPassword':'Nova senha',
	'A947ConfirmPassword':'Confirme a nova senha',
	'A948Update':'Atualizar',
	'A949IncorrectPassword':'Senha incorreta',
	'A950Metrics':'Metricspecs',
	'A950PasswordsNotMatch':'Novas senhas não coincidem',
	'A951EmailNotFound':'Endereço de email não encontrado',
	'A952InvalidEmail':'Digite um endereço de email válido',
	'A953EmailSent':'Se o seu email existir em nosso banco de dados, um email será enviado para o seu ID de email com o link de redefinição.',
	'A954ForgetPasswordMailSubject':'Redefinir sua senha',
	'A955ForgetPasswordMailTitle':'Olá USER_NAME,',
	'A956ForgetPasswordMailBody':'<p>We received a request to reset your password.<br>Use the link below to set up a new password for your account. If you did not request to reset your password, ignore this email and the link will expire in 24 hours on its own.<p><a href=\'RESET_LINK\' style=\'text-decoration: none; font-color: white;\'><span style=\'padding: 10px; display: inline; border-radius: 2px; border: 0; margin: 0 10px; background: #09174a; color:white; line-height: 15px; width: auto; height: auto; box-sizing: content-box;\'>Set new password</span></a><br><p>Best regards from the TrackYourPlant-Team</p>',
	'A957DateFormat':'dd/MM/yyyy',
	'A958D3DateFormat':'% d /% m /% a',
	'A959DashboardMachineStateStandBy':'Espera',
	'A959MessageUpdateSuccess':'Mensagem atualizada com sucesso',
	'A960MessageSaveSuccess':'Mensagem salva com sucesso',
	'A961Yes':'sim',
	'A962No':'Não',
	'A963ReportOverview':'Visão geral do relatório',
	'A964Machine':'Máquina',
	'A965Card':'Cartão',
	'A966ReportType':'Tipo de relatório',
	'A967HistogramReport':'Relatório histograma',
	'A968Report':'Relatório',
	'A969Day':'1 dia',
	'A969French':'Français',
	'A970Week':'1 semana',
	'A971Month':'1 mês',
	'A972HistoryChart':'Gráfico histórico',
	'A972HistoryChartBacth':'Gráfico de histórico cur. lote',
	'A973Unit':'[Unidade]',
	'A974Time':'Tempo',
	'A975ShowBagCounterStackChart':'Veja o gráfico de pilha',
	'A976TestReportStarted':'Geração do relatório de teste iniciada',
	'A977ActiveSettingsRequired':'Configurações do relatório ativo necessárias para gerar o relatório de teste',
	'A978FirstDayRequired':'Data do primeiro relatório exigido.',
	'A979CompanyDeleted':'Empresa excluída.',
	'A980CompanyDeleteError':'Empresa não pôde ser excluída:',
	'A981CompanyParentShouldDifferent':'Empresa e empresa-mãe devem ser diferentes',
	'A982CompanyCreated':'Empresa salva.',
	'A983CompanyCreateError':'A empresa não pôde ser salva:',
	'A984CompanyAllFields':'Insira todos os campos',
	'A985UserCreated':'Utilizador criado',
	'A986UserExists':'e-mail já existe',
	'A987UserDeleted':'Usuário excluído',
	'A988IncludedInReport':'incluído no relatório',
	'A989IncludedInHistogram':'incluído como histograma no relatório',
	'A990DateFormat':'DD / MM / YYYY',
	'A991MachineTime':'Tempo da máquina',
	'A992LocalTime':'Hora do computador',
	'A993NoMachines':'Nenhuma máquina para mostrar',
	'A994Imprint':'Imprimir',
	'A995PrivacyPolicy':'Política de Privacidade',
	'A996Chinese':'中文',
	'A997Portuguese':'Português',
	'A998Vietnamese':'Tiếng Việt ',
	'A999CompanyNameExists':'O nome da empresa já existe.',
	'B100ActivatingReports':'A ativação enviará relatórios regulares para os seguintes IDs de email:',
	'B101EnterValidEmailIds':'Insira os códigos de e-mail válidos',
	'B101ValidFrom':'Válido de',
	'B102AtleastOneEmailIdRequired':'Pelo menos um ID de email requerido',
	'B102ValidTo':'Válido até',
	'B103MaximumLimitReached':'Limite máximo atingido',
	'C001FetchingWait':'Buscando dados. Por favor, espere.',
	'C002PreparingWait':'Preparando dados. Por favor, espere.',
	'C003RenderingWait':'Gráfico de renderização. Por favor, espere.',
	'C004PixelScale':'1 pixel ≈ {{intervalo de tempo}} segundos.',
	'C005ChartError':'Erro ocorreu!',
	'C006ChartOk':'Está bem',
	'D001PortalType':'Portal',
	'D002CompanyDomain':'Olhar e Sentir',
	'E001AlertSettingSavedSuccessfully':'Alerta configurações salvas com sucesso.',
	'E002SelectAtLeastOneAlertTypeToActivateAlerting':'Selecione pelo menos um tipo de alerta para ativar o alerta.',
	'E003ErrorNumberMustBePositive':'O número do erro deve ser positivo',
	'E004AlertEmailNeedsToBeActive':'E-mail de alerta precisa estar ativo',
	'E005AnErrorHasOccured':'Ocorreu um erro.',
	'E005ErrorMessageAlreadyExists':'A mensagem já existe',
	'E005OperationSuccesful':'Operação bem sucedida',
	'E006ErrorMessageSave':'Erro ocorreu. Mensagem não salva',
	'E006ErrorOccurredSettingsWasNotSaved':'Ocorreu um erro. As configurações não foram salvas',
	'E007ReportSettingsSuccessfullySaved':'Configurações de relatório salvas com sucesso',
	'E008ErrorSettingsDoNotExists':'Erro. Configurações não existem',
	'E009ReportSettingsSuccessfullyUpdated':'Configurações de relatório atualizadas com sucesso.',
	'E010InvalidPassword':'Senha inválida',
	'E011UserUpdated':'Usuário atualizado',
	'E012InvalidPassword':'A senha não satisfaz os critérios da senha ',
	'E013UserUpdated':'O usuário foi atualizado com sucesso',
	'E015CompanyMaxUsersLowerThanActiveUsers':'A empresa já tem mais usuários ativosdo que o máximo de usuários definido',
	'E016PasswordChangedSuccessfully':'A senha foi alterada com sucesso ',
	'F001_CurrentOrder':'Ordem atual',
	'F002_ProductID':'ID do produto',
	'F003_BatchStart':'Início do lote',
	'F004_Targetweight':'Peso alvo',
	'F005_RemainingFill':'Preenchimentos restantes',
	'F006_CurrentProduction':'Produção atual',
	'F007_FillStatistic':'preencher estatísticas',
	'F010_Sum':'Soma',
	'F011_Centering':'Centrar',
	'F012_Debunging':'Debunging',
	'F013_Inert':'Inerte',
	'F014_Filling_1':'Preenchimento 1',
	'F015_Filling_2':'Preenchimento 2',
	'F016_Filling_3':'Preenchimento 3',
	'F017_Filling_4':'Preenchimento 4',
	'F018_Bunging':'Bunging',
	'F019_Sealing':'Vedação',
	'F020_Position':'Posição',
	'F021_TransportCycle':'Ciclo de transporte',
	'F022_CheckWeigher':'Verificar pesador',
	'F023_Closing':'Encerramento',
	'F024_Palletizing':'Paletização',
	'F025_EmptyPaletMagazin':'Paleta VaziaMagazin',
	'F026_DePalletizing':'De paletização',
	'F027_Transport':'Transporte',
	'F028_PailSeperator':'Separador de balde',
	'F029_HangUpCap':'Hang Up Cap',
	'F030_PushInCap':'Empurre a tampa',
	'F031_TestConsoles':'Consoles de teste',
	'F050_TU2':'TU 2',
	'F051_TU1':'TU 1',
	'F052_LowerTol':'Sob tolerância',
	'F053_InTol':'Em tolerância',
	'F054_UpperTol':'Sobre tolerância',
	'F055_TO1':'TO 1',
	'F056_TO2':'TO 2',
	'F060_ProductInfeed':'Produto no feed',
	'F061_EmptyTruss':'Treliça vazia',
	'F062DetailedDateFormat':'DD / MM / YYYY HH:mm:ss',
	'F090_Minuten':'Minutos',
	'F091_Stunden':'Horas',
	'F092_Woche':'Semana',
	'F100_Eichung':'Próxima calibração',
	'F101_TransKette':'Corrente transportadora',
	'F102_Hubtor':'Portões de elevação',
	'F103_ZentRollen':'Rolos de centragem',
	'F104_SpundSuch':'Localizador Bunghole',
	'F105_Aushub':'Elevação de contêiner',
	'F106_WerkSchlitten':'Carrinho de ferramentas',
	'F107_Aufschrauber':'Runtime de-bunging',
	'F108_VakuSys':'Desobstrução do sistema de vácuo',
	'F109_HubAufSchraub':'Cilindro desobstruído',
	'F110_OelAufSchraub':'Desobstrução de lubrificação',
	'F111_Inertgas':'Inerte',
	'F112_Aushub_F1':'Elevação de contêiner',
	'F113_HE_F1':'Unidade de elevação',
	'F114_TFE_F1':'Colher de gotejamento',
	'F115_Aushub_F2':'Elevação de contêiner',
	'F116_HE_F2':'Unidade de elevação',
	'F117_TFE_F2':'Colher de gotejamento',
	'F118_ZentDorn':'Mandril de centragem',
	'F119_Schiebetisch':'Mesa deslizante',
	'F120_Kappenband':'Tapete transportador',
	'F121_VakuKap':'Separação do plugue do sistema de vácuo',
	'F122_KapSort':'Pote de classificação',
	'F123_VerKap':'Separação de plugue',
	'F124_KapZu':'Plug feed',
	'F125_Schrauber':'Bunging',
	'F126_VakuSchrauber':'Bunging do sistema de vácuo',
	'F127_HubSchrauber':'Cilindro de Bloqueio',
	'F128_OelZuSchraub':'Bunging de lubrificação',
	'F129_Dichtung':'Cabeça de vedação',
	'F130_HubClinch':'Cilindro selador',
	'F131_Clinchen':'Selagem',
	'F132_OelClincher':'Vedação de lubrificação',
	'F133_VakuClincher':'Vedação do sistema de vácuo',
	'F140_Startphase':'Fase de início',
	'F141_Grobstromphase':'Fase de preenchimento grosso',
	'F142_Feinstromphase':'Fase de preenchimento fino',
	'F143_DurchflussF1':'Taxa de fluxo F1',
	'F144_DurchflussF2':'Taxa de fluxo F1',
	'G001BatchReport':'Relatórios em lote',
	'G002ActivateBatchReport':'Ativar relatórios em lote',
	'Hülsen MIN-Melder':'Hull MIN-Sensor',
	'IVT-Simulation':'INTEGRA® ',
	'Kabel Sattelhöhenv':'Cable Saddle Height Adjustment',
	'MACHINE_AVAILABILITY_RED_ZONE':'Machine availability in red zone',
	'MACHINE_AVAILABILITY_YELLOW_ZONE':'Machine availability in yellow zone',
	'Maint Interval 0':'Maint Interval 0',
	'Maint Interval 1':'Maint Interval 1',
	'Maint Interval 10':'Maint Interval 10',
	'Maint Interval 11':'Maint Interval 11',
	'Maint Interval 12':'Maint Interval 12',
	'Maint Interval 13':'Maint Interval 13',
	'Maint Interval 14':'Maint Interval 14',
	'Maint Interval 2':'Maint Interval 2',
	'Maint Interval 3':'Maint Interval 3',
	'Maint Interval 4':'Maint Interval 4',
	'Maint Interval 5':'Maint Interval 5',
	'Maint Interval 6':'Maint Interval 6',
	'Maint Interval 7':'Maint Interval 7',
	'Maint Interval 8':'Maint Interval 8',
	'Maint Interval 9':'Maint Interval 9',
	'miniADAMS-Messe':'ROTO-PACKER®',
	'N042BrazAlertGlobalAccL0':'Aceleração global cruzada L0',
	'N042BrazAlertGlobalAccL1':'Aceleração global cruzada L1',
	'N042BrazAlertGlobalAccL2':'Aceleração global cruzada L2',
	'N042BrazAlertGlobalAccL3':'Aceleração global cruzada L3',
	'N042BrazAlertGlobalAccL4':'Aceleração global cruzada L4',
	'N042BrazAlertGlobalAmplL0':'Amplitude global cruzada L0',
	'N042BrazAlertGlobalAmplL1':'Amplitude global cruzada L1',
	'N042BrazAlertGlobalAmplL2':'Amplitude global cruzada L2',
	'N042BrazAlertGlobalAmplL3':'Amplitude global cruzada L3',
	'N042BrazAlertGlobalAmplL4':'Amplitude global cruzada L4',
	'N042BrazAlertMaxRmsAccL0':'Aceleração rms máxima dos sensores LB, CB e RB cruzada L0',
	'N042BrazAlertMaxRmsAccL1':'Aceleração rms máxima dos sensores LB, CB e RB cruzada L1',
	'N042BrazAlertMaxRmsAccL2':'Aceleração rms máxima dos sensores LB, CB e RB cruzada L2',
	'N042BrazAlertMaxTempBearingSensL0':'Temperatura máxima dos sensores de mancal cruzada L0',
	'N042BrazAlertMaxTempBearingSensL1':'Temperatura máxima dos sensores de mancal cruzada L1',
	'N042BrazAlertMaxTempBearingSensL2':'Temperatura máxima dos sensores de mancal cruzada L2',
	'N042BrazAlertMaxZAxisSensorsL0':'Aceleração máxima do eixo z de lfs, rfs lds e sensores vermelhos cruzados L0',
	'N042BrazAlertMaxZAxisSensorsL1':'Aceleração máxima do eixo z de lfs, rfs lds e sensores vermelhos cruzados L1',
	'N042BrazAlertMaxZAxisSensorsL2':'Aceleração máxima do eixo z de lfs, rfs lds e sensores vermelhos cruzados L2',
	'N042BrazAlertRotFreqL0':'Frequência de rotação cruzada L0',
	'N042BrazAlertRotFreqL1':'Frequência de rotação cruzada L1',
	'N042BrazAlertRotFreqL2':'Frequência de rotação cruzada L2',
	'N042BrazAlertRotFreqL3':'Frequência de rotação cruzada L3',
	'N042BrazAlertRotFreqL4':'Frequência de rotação cruzada L4',
	'N042BrazAlertType':'Alerta brasileiro',
	'N042GlobalAcceleration':'Aceleração global ',
	'N042GlobalAmplitude':'Amplitude Global',
	'N042GlobalFrequency':'Frequência Global',
	'N043CardCtxMenuShowConfigScreen':'Configurar cartão',
	'N043ConfigSaveFailure':'A atualização da configuração falhou',
	'N043ConfigSaveSuccess':'Configuração atualizada com sucesso',
	'N044BrazilAlertMailSubject':'Pulse CM Alert : MACHINE_NAME',
	'N045AlertWhenThresholdCrossed':'Avise-me quando este limite for ultrapassado',
	'N046RawDataTab':'Dados não tratados',
	'N047SensorL1':'Sensor L 1',
	'N047SensorL4':'Sensor L 4',
	'N047SensorR1':'Sensor R 1',
	'N047SensorR4':'Sensor R 4',
	'N048Acceleration':'Aceleração',
	'N048AccelerationLocal':'Aceleração local',
	'N048AccelerationX':'Aceleração X',
	'N048AccelerationY':'Aceleração Y',
	'N048AccelerationZ':'Aceleração Z',
	'N048Amplitude':'Amplitude',
	'N048AmplitudeLocal':'Amplitude Local',
	'N048AmplitudeX':'Amplitude X',
	'N048AmplitudeY':'Amplitude Y',
	'N048AmplitudeZ':'Amplitude Z',
	'N048ChooseEndDate':'Escolha uma data de término',
	'N048ChooseStartDate':'Escolha uma data de início',
	'N048Constant':'Constante',
	'N048CourseAngle':'Ângulo do Curso',
	'N048DeltaTemperature':'Temperatura Delta',
	'N048Frequency':'Frequência',
	'N048LastSeen':'Visto pela última vez',
	'N048MovementAngle':'Ângulo de Movimento',
	'N048OffsetX':'X Deslocamento',
	'N048OffsetY':'Y Deslocamento',
	'N048OffsetZ':'Z Deslocamento',
	'N048PhaseAngle':'Ângulo de fase',
	'N048MotionAngle':'Ângulo de movimento',
	'N048RMS':'RMS',
	'N048Rpm':'RPM',
	'N048SensorDescription':'Descrição do Sensor',
	'N048SensorId':'Id do sensor',
	'N048SensorTemperature':'Temperatura do Sensor',
	'N048Side':'Lado',
	'N048SiteName':'Nome do site',
	'N048UpdatedTime':'Hora atualizada',
	'N049accelFDCompLeft':'Accel.FDComp.Left',
	'N049accelFDCompRight':'Accel.FDComp.Right',
	'N049accelLRCompDisch':'Accel.LRComp.Disch',
	'N049accelLRCompFeed':'Accel.LRComp.Feed',
	'N049Date':'Encontro',
	'N049ErrorFetchingData':'Erro ao buscar dados',
	'N049FrequencyDaily':'Dia',
	'N049FrequencyHourly':'Horas',
	'N049FrequencyWeekly':'Semana',
	'N049Length':'comprimento',
	'N049ModelName':'Nome do modelo',
	'N049pitch':'Pitch',
	'N049roll':'Lista',
	'N049Rpm':'Rpm',
	'N049strokeDeparture':'Partida de AVC',
	'N049TuningWizard':'Assistente de ajuste',
	'N049twistG':'Twist.G',
	'N049twistXG':'Twist.XG',
	'N049twistYG':'Twist.YG',
	'N049twistZG':'Twist.ZG',
	'N049Width':'Largura',
	'N049xgFDCompLeft':'X.G.FDComp.Left',
	'N049xgFDCompRight':'X.G.FDComp.Right',
	'N049xgLrCompDisch':'X.G.LRComp.Disch',
	'N049xgLrCompFeed':'X.G.LRComp.Feed',
	'N049ygFDCompLeft':'Y.G.FDComp.Left',
	'N049ygFDCompRight':'Y.G.FDComp.Right',
	'N049ygLrCompDisch':'Y.G.LRComp.Disch',
	'N049ygLrCompFeed':'Y.G.LRComp.Feed',
	'N049zgFDCompLeft':'Z.G.FDComp.Left',
	'N049zgFDCompRight':'Z.G.FDComp.Right',
	'N049zgLrCompDisch':'Z.G.LRComp.Disch',
	'N049zgLrCompFeed':'Z.G.LRComp.Feed',
	'N049zgMax':'ZG.Max',
	'N050AnalystCommentary':'Comentário do analista',
	'N050mAvgLong':'mavg long',
	'N050mAvgShort':'mavg curto',
	'N050TrendAnalysis':'Análise de tendências',
	'N051SensorL1':'Sensor L1',
	'N051SensorL2':'Sensor L2',
	'N051SensorL3':'Sensor L3',
	'N051SensorL4':'Sensor L4',
	'N051SensorMovement':'Movimento do sensor',
	'N051SensorR1':'Sensor R1',
	'N051SensorR2':'Sensor R2',
	'N051SensorR3':'Sensor R3',
	'N051SensorR4':'Sensor R4',
	'N052Anomaly':'Anomalia',
	'N052AnomalyDetectionTab':'Detecção de anomalia',
	'N052BrazAlertTypeSL1LocalAmplitudeL0':'Sensor L1 amplitude local L0',
	'N052BrazAlertTypeSL1LocalAmplitudeL1':'Sensor L1 amplitude local L1',
	'N052BrazAlertTypeSL1LocalAmplitudeL2':'Sensor L1 amplitude local L2',
	'N052BrazAlertTypeSL1LocalAmplitudeL3':'Sensor L1 amplitude local L3',
	'N052BrazAlertTypeSL1LocalAmplitudeL4':'Sensor L1 amplitude local L4',
	'N052BrazAlertTypeSL1LocalAmplitudeL5':'Sensor L1 amplitude local L5',
	'N052BrazAlertTypeSL1SensorTempL0':'Sensor L1 sensor de temperatura cruzada L0',
	'N052BrazAlertTypeSL1SensorTempL1':'Sensor L1 sensor de temperatura cruzada L1',
	'N052BrazAlertTypeSL1SensorTempL2':'Sensor L1 sensor de temperatura cruzada L2',
	'N052BrazAlertTypeSL1SensorTempL3':'Sensor L1 sensor de temperatura cruzada L3',
	'N052ObservedValue':'Observada',
	'N053Angle':'Ângulo (0)',
	'N053BrazAlertTypeSL1LocalAccelerationL0':'Aceleração local do sensor L1 cruzada L0',
	'N053BrazAlertTypeSL1LocalAccelerationL1':'Aceleração local do sensor L1 cruzada L1',
	'N053BrazAlertTypeSL1LocalAccelerationL2':'Aceleração local do sensor L1 cruzada L2',
	'N053BrazAlertTypeSL1LocalAccelerationL3':'Aceleração local do sensor L1 cruzada L3',
	'N053BrazAlertTypeSL1LocalAccelerationL4':'Aceleração local do sensor L1 cruzada L4',
	'N053BrazAlertTypeSL1LocalAccelerationL5':'Aceleração local do sensor L1 cruzada L5',
	'N053BrazAlertTypeSl1RotationFreqL0':'Sensor L1 frequência de rotação local L0',
	'N053BrazAlertTypeSl1RotationFreqL1':'Sensor L1 frequência de rotação local L1',
	'N053BrazAlertTypeSl1RotationFreqL2':'Sensor L1 frequência de rotação local L2',
	'N053BrazAlertTypeSl1RotationFreqL3':'Sensor L1 frequência de rotação local L3',
	'N053BrazAlertTypeSl1RotationFreqL4':'Sensor L1 frequência de rotação local L4',
	'N053BrazAlertTypeSl1RotationFreqL5':'Sensor L1 frequência de rotação local L5',
	'N053BrazAlertTypeSL1ZAxisL0':'Aceleração do eixo z do sensor L1 cruzado L0',
	'N053BrazAlertTypeSL1ZAxisL1':'Aceleração do eixo z do sensor L1 cruzado L1',
	'N053BrazAlertTypeSL1ZAxisL2':'Aceleração do eixo z do sensor L1 cruzado L2',
	'N053BrazAlertTypeSL1ZAxisL3':'Aceleração do eixo z do sensor L1 cruzado L3',
	'N053Displacement':'Deslocamento',
	'N053G':'g',
	'N053LocalAmplitude':'Amplitude Local',
	'N053LocalRotationFrequency':'Rotação local (frequência)',
	'N053MM':'mm',
	'N053XAxis':'X-Axis',
	'N053YAxis':'Y-Axis',
	'N053ZAxis':'Z-Axis',
	'N053ZAxisAcceleration':'Aceleração do eixo Z',
	'N054Higher':'Superiora',
	'N054Lower':'Mais baixo',
	'N054StructuralAnalysis':'Análise estrutural',
	'N054TWvarsForecasts':'Previsões TW vars',
	'N054TWvarsFull':'TW vars full',
	'N054Value':'Valor',
	'N0551On0Off':'1 = LIGADO: 0 = DESLIGADO',
	'N055BrazTabGeneral':'Visão Geral',
	'N055ChooseStartEndDate':'Escolha a data de início e a data de término',
	'N055Close':'Fechar',
	'N055Custom':'Personalizada',
	'N055MaterialFlow':'Fluxo de material',
	'N055MAxBearingTemperature':'Temperatura máxima do mancal',
	'N055MaxRmsAccelBearingSensors':'Aceleração máxima de Rms dos sensores de rolamento',
	'N055MazAccelZAxis':'Aceleração máxima do eixo Z dos sensores de estrutura',
	'N055Off':'DESLIGADA',
	'N055On':'SOBRE',
	'N055RDCenter':'Visão da máquina',
	'N055ShowHistory':'Mostrar história',
	'N055StartDateCannotBeAfterEndDate':'A data de início não pode ser posterior à data de término',
	'N056Commentary':'Comentário',
	'N056EvaluateEquipment':'Condições para avaliar no equipamento',
	'N056High80':'High 80',
	'N056High95':'High 95',
	'N056Importance':'importância',
	'N056Low80':'Low 80',
	'N056Low95':'Low 95',
	'N056MaxLatAccelZgMax':'Aceleração lateral máxima ZG.Max',
	'N056Pred':'Pred',
	'N056Recommendations':'Recomendações',
	'N056Type':'Modelo',
	'N056Report':'Relatório',
	'N0571Year':'1 ano',
	'N057AccelGms':'Aceleração (g)',
	'N057AccelRms':'Aceleração RMS',
	'N057AccelSpectrum':'Espectro de aceleração',
	'N057AccelTimeWForm':'Forma de onda de tempo de aceleração',
	'N057Axial':'Axial',
	'N057CrestFactor':'Fator de crista',
	'N057DegreeCelcius':'°C',
	'N057FreqHz':'Frequência (Hz)',
	'N057FullSpectrum':'Espectro completo',
	'N057FundBearingDefectFreq':'Frequências de fundo, rolamento, defeito',
	'N057HighHarBearFreq':'Frequências de rolamento harmônicas mais altas',
	'N057HighRes':'Alta Resolução',
	'N057HistoricTemperature':'Temperatura histórica',
	'N057Horizontal':'Horizontal',
	'N057Latest':'Mais recente',
	'N057LowHarBearFreq':'Freqüências de rolamento harmônicas mais baixas',
	'N057mms':'mm/s',
	'N057PeakToPeak':'Pico a pico',
	'N057PeakToPeakMg':'Peak to peak(mg)',
	'N057PowerBands':'Faixas de Potência',
	'N057RedAlert':'Alerta vermelho',
	'N057Rmsmg':'Rms(mg)',
	'N057Rmsmms':'Rms(mm/s)',
	'N057SensorLB1':'Sensor LB1',
	'N057SensorLB2':'Sensor LB2',
	'N057SensorRB1':'Sensor RB1',
	'N057SensorRB2':'Sensor RB2',
	'N057SensorCB1':'Sensor CB1',
	'N057SensorCB2':'Sensor CB2',
	'N057Spectrum':'Espectro',
	'N057Temperature':'Temperatura',
	'N057TimeMs':'Tempo (ms)',
	'N057Trending':'Tendência',
	'N057VelocityRms':'Velocidade RMS',
	'N057Vertical':'Vertical',
	'N057X1Rpm':'1X Rpm',
	'N057YellowAlert':'Alerta amarelo',
	'N058ImageUploadedFailure':'Falha no upload da imagem',
	'N058ImageUploadedSuccesfully':'Imagem carregada com sucesso',
	'N058UploadImage':'Enviar Imagem',
	'N059Print':'Impressão',
	'N060MachineHistoricalData':'Dados históricos da máquina',
	'N061HistoricalBearingData':'Dados históricos de direção',
	'N062HistoricalSpectrumData':'Dados históricos do espectro',
	'N063GlobalAcceleration':'Aceleração global',
	'N064RotationFrequency':'Rotação (frequência)',
	'N065GlobalBreadth':'Amplitude Global',
	'N066LocalAccelerationsEachStructureSensor':'Acelerações locais de cada sensor de estrutura',
	'N067MotionAngleEachStructureSensor':'Ângulo de movimento de cada sensor de estrutura',
	'N067PhaseAngleEachStructureSensor':'Ângulo de fase de cada sensor de estrutura',
	'N067LocalAmplitudesEachStructureSensor':'Amplitudes locais de cada sensor de estrutura',
	'N068LateralAccelerationsStructureSensor':'Acelerações laterais (eixo Z) de cada sensor de estrutura',
	'N069TemperatureEachBearingSensor':'Temperatura de cada sensor de rolamento',
	'N070PeakToPeakEachBearingSensor':'De pico a pico de cada sensor de rolamento',
	'N071AccelerationRMS':'Aceleração RMS',
	'N072VelocityRMS':'Velocidade RMS',
	'N073CrestFactor':'Fator de crista',
	'N074PowerBandsSensorLB1':'Bandas de potência - Sensor LB1',
	'N075PowerBandsSensorRB1':'Bandas de potência - Sensor RB1',
	'N076PowerBandsSensorLB2':'Bandas de potência - Sensor LB2',
	'N077PowerBandsSensorRB2':'Bandas de potência - Sensor RB2',
	'N077PowerBandsSensorCB1':'Bandas de potência - Sensor CB1',
	'N077PowerBandsSensorCB2':'Bandas de potência - Sensor CB2',
	'N078TimeWaveformDataAcceleration':'Forma de onda de tempo - aceleração',
	'N079FrequencyDomainAcceleration':'Domínio de frequência - aceleração',
	'N080Select':'Selecione',
	'N081SelectedPeriod':'Período selecionado',
	'N100Capacity':'Capacidade',
	'N101Product':'produtos',
	'N102Undersized':'Subdimensionado',
	'N103Oversized':'Superdimensionado',
	'N104Speed':'Velocidade',
	'N105Inclination':'Inclinação',
	'N106BoardHeigh':'Altura do tabuleiro',
	'N107Moisture':'Humidade',
	'N108WaterAdd':'Adição de água',
	'N109OpMode':'Modo de operação',
	'N110Auto':'Modo automático',
	'N110Hand':'Modo manual',
	'N111OpHours':'Horas de funcionamento',
	'N112Temp':'Temperatura',
	'N113BearingTemp1':'Temperatura do mancal 1',
	'N114BearingTemp2':'Temperatura do mancal 2',
	'N115DriveTemp':'Temperatura da unidade',
	'N116ConvTemp':'Temperatura do conversor',
	'N117PowerInput':'Entrada de energia',
	'New001BagTransArea':'Área de transporte de malas',
	'New002EmptyPalArea':'Área de palete vazio',
	'New003FullPalArea':'Área de palete cheio',
	'New004SheetDispArea':'Área do distribuidor de folhas',
	'New005PneuFalut':'Falha Pneumática',
	'New006ElectricFault':'Falha Elétrica',
	'New007Introduction':'Introdução',
	'New008Orientation':'Orientação',
	'New009PreGrouping':'Pré-Agrupamento',
	'New010Grouping':'Agrupamento',
	'New011RowPusher':'Row Pusher',
	'New012SlideTable':'Mesa deslizante e placa de conformação',
	'New013Elevator':'Elevador',
	'New014Gantry':'Pórtico',
	'New015Gripper':'Garra',
	'New016FilmDispenser':'Dispensador de filme',
	'New017FullPalTrans':'Transporte completo de paletes',
	'New018EmpPalDisp':'Distribuidor de paletes vazio',
	'New019FilmDisp':'Dispensador de filme',
	'New020EmpCardDisp':'Dispensador de papelão',
	'New021EmpPalTrans':'Transporte de paletes vazias',
	'New022EjectingDev':'Dispositivo de ejeção',
	'New023MetDetector':'Detector de metal',
	'New024MarkingDev':'Dispositivo de Marcação',
	'New025LabellingDev':'Dispositivo de etiquetagem',
	'New026PalletStracker':'Pallet Stracker',
	'New027PalletPress':'Prensa de paletes',
	'New028Wrapper':'Dispositivo de acondicionamento / capa',
	'New029Fault':'Falha',
	'New030StandBy':'Espera',
	'New031BagProduction':'Produção de sacos',
	'New032WeightDetection':'Detecção de Peso',
	'New033LeakingDetection':'Detecção de Vazamento',
	'New034PrintingFault':'Falha de impressão',
	'New035BagProduced':'Saco produzido',
	'New036SamplingEjection':'Ejeção de Amostragem',
	'New037PalletProduction':'Produção de paletes',
	'New038PalletInfeed':'Pallet Infeed',
	'New039PalletOutfeed':'Pallet Outfeed',
	'New040PalletIncomplete':'Palete Incompleto',
	'New041Checkweigher':'Checkweigher ',
	'New041PalledProduced':'Palete produzido',
	'New042ProgramChngsToday':'Mudanças no programa hoje',
	'New042ProgramChngs':'Mudanças no programa',
	'New043EndofBatch':'Fim do lote',
	'New044ProductonTime':'Tempo Produzindo',
	'RVT8-Messe':'ROTO-PACKER® ',
	'RVT8-Simulation':'ROTO-PACKER® ',
	'TERAM2-Messe':'TERRAM®',
	'TERAM-Messe':'TERRAM®',
	'Typ84-Messe':'INTEGRA® ',
	'Waagenabgleich':'scale adjustment',
	'Zyklen':'cycles',
	'N082AlertMe':'Avise-me quando este cartão tiver algum alerta',
	'N083TimeFormat':'HH:mm',
	'N090Synced':'Sincronizado',
	'N091NotSynced':'Não sincronizado',
	'N084New':'Mais recentes',
	'N085ConfigSettingSavedSuccessfully':'Definições de configuração salvas com sucesso.',
	'N86ErrorOccurredSettingsWasNotSaved':'Erro ao salvar as configurações.',
	'A496OEEWeek':'a cada segunda semana',
	'A497OEEMonthly':'Por mês',
	'N87ServiceRequestUploadFiles':'Fazer upload de arquivos',
	'N88ServiceRequestFileSizeError':'Arquivo muito grande',
	'N89ServiceRequestFileTypeError':'Tipos de arquivo não são suportados',
	'N90FilesUploadedSuccesfully':'Arquivo carregado com sucesso',
	'N91FilesUploadedFailure':'Erro ao enviar arquivos',
	'N932IncludeExcelReport':'Incluir relatórios do Excel',
	'N94ServiceRequestFileLimitError':'Arquivo acima do limite',
	'N055MaxHAxisRmsAccelBearingSensors':'Aceleração RMS Máx. Horiz.',
	'N055MaxAxialRmsAccelBearingSensors':'Aceleração RMS Máx. Axial',
	'N053MG':'mg',
	'N056DummyMachine':'Dummy machines',
	'N057NiagaraAlertYellow':'Alerta Amarelo',
	'N058NiagaraAlertOrange':'Alerta Laranja',
	'N059NiagaraAlertRed':'Alerta Vermelho',
	'N060AddDummyMachine':'Adicionar Máquina Dummy',
	'N96ConfigureValue':'Valor de configuração',
	'N97NoDataAvailable':'Sem dados disponíveis',
	'N98TimeStamp':'Carimbos de data/hora',
	'N99Documentation':'Documentação',
	'N100ErrorFileHeader':'Mensagens de erro',
	'N101DocumentationHeader':'Cabeçalho da documentação',
	'N102DownloadMachineTypeErrorMessageFile':'Tipo de máquina padrão',
	'N102DownloadMachineErrorMessageFile':'Arquivo de erros da máquina',
	'N103ErrorInRetrievingErrorMessages':'Erro ao tentar recuperar as mensagens de erro',
	'E011MaintIntervalSubmitSuccess':'Intervalo de manutenção salvo com sucesso',
	'A368OpenPartsShop':'Abrir o catálogo de peças de reposição',
	'N104ExtSparePartCatalog':'Catálogo de peças de reposição da Haver',
	'N105OpenExtSparePartCatalog':'Abrir o catálogo de peças da Haver',
	'N106LperH':'l/h',
	'N107Degree':'°',
	'N107DegreeText':'° (Degree)',
	'N108UseRelativeValues':'Usar valores relativos',
	'N109PartsShopUsername':'Usuário do catálogo de peças de reposição',
	'N110Tons':'t',
	'AX_VibratingBodyCondition':'Condição do corpo vibratório',
	'AX_BearingCondition':'Condição dos rolamentos',
	'AX_AlarmsCount':'Contador de Alarmes',
	'AX_Last24Hours':'Nas últimas 24 horas',
	'D001TransBearingCondition':'Bom',
	'D002TransBearingCondition':'Bom | Diminuindo',
	'D003TransBearingCondition':'Bom | Estável',
	'D004TransBearingCondition':'Bom | Aumentando',
	'D005TransBearingCondition':'Aceitável',
	'D006TransBearingCondition':'Aceitável | Diminuindo',
	'D007TransBearingCondition':'Aceitável | Estável',
	'D008TransBearingCondition':'Aceitável | Aumentando',
	'D009TransBearingCondition':'Sério',
	'D010TransBearingCondition':'Sério | Diminuindo',
	'D011TransBearingCondition':'Sério | Estável',
	'D012TransBearingCondition':'Sério | Aumentando',
	'D013TransBearingCondition':'Crítico',
	'D014TransBearingCondition':'Crítico | Diminuindo',
	'D015TransBearingCondition':'Crítico | Estável',
	'D016TransBearingCondition':'Crítico | Aumentando',
	'D001TransVBCondition':'Bom',
	'D002TransVBCondition':'Bom | Diminuindo',
	'D003TransVBCondition':'Bom | Estável',
	'D004TransVBCondition':'Bom | Aumentando',
	'D005TransVBCondition':'Aceitável',
	'D006TransVBCondition':'Aceitável | Diminuindo',
	'D007TransVBCondition':'Aceitável | Estável',
	'D008TransVBCondition':'Aceitável | Aumentando',
	'D009TransVBCondition':'Sério',
	'D010TransVBCondition':'Sério | Diminuindo',
	'D011TransVBCondition':'Sério | Estável',
	'D012TransVBCondition':'Sério | Aumentando',
	'D013TransVBCondition':'Crítico',
	'D014TransVBCondition':'Crítico | Diminuindo',
	'D015TransVBCondition':'Crítico | Estável',
	'D016TransVBCondition':'Crítico | Aumentando',
	'F01CardExpProduction':'***Coming Soon***',
	'F01CardExpAvailability':'***Coming Soon***',
	'F01CardExpMaintenance':'***Coming Soon***',
	'F01CardExpProductionTime':'***Coming Soon***',
	'F01CardExpProductionTimeBaumit':'***Coming Soon***',
	'F01CardExpProductionToday':'***Coming Soon***',
	'F01CardExpRemProduction':'***Coming Soon***',
	'F01CardExpLastReset':'***Coming Soon***',
	'F01CardExpFaults_C':'***Coming Soon***',
	'F01CardExpFaults_M':'***Coming Soon***',
	'F01CardExpEnergy':'***Coming Soon***',
	'F01CardExpFillingTime':'***Coming Soon***',
	'F01CardExpBagManufacture':'***Coming Soon***',
	'F01CardExpWeightData':'***Coming Soon***',
	'F01CardExpStdDevData':'***Coming Soon***',
	'F01CardExpIncorrectWeight':'***Coming Soon***',
	'F01CardExpInterrupts':'***Coming Soon***',
	'F01CardExpTypeChange':'***Coming Soon***',
	'F01CardExpBagCounterPerFS':'***Coming Soon***',
	'F01CardExpNotPlacedBags':'***Coming Soon***',
	'F01CardExpEmptyBagMagazineToday':'***Coming Soon***',
	'F01CardExpRejectsToday':'***Coming Soon***',
	'F01CardExpCollectiveFaultsToday':'***Coming Soon***',
	'F01CardExpGoodWeights':'***Coming Soon***',
	'F01CardExpFltCompAir':'***Coming Soon***',
	'F01CardExpStrokesPump':'***Coming Soon***',
	'F01CardExpMaxStrokeDur':'***Coming Soon***',
	'F01CardExpAvgStrokes':'***Coming Soon***',
	'F01CardExpInternalPressure':'***Coming Soon***',
	'F01CardExpFillings':'***Coming Soon***',
	'F01CardExpStartOfOrder':'***Coming Soon***',
	'F01CardExpProductID':'***Coming Soon***',
	'F01CardExpTargetWeight':'***Coming Soon***',
	'F01CardExpFillingCount':'***Coming Soon***',
	'F01CardExpRemainingNumberFillings':'***Coming Soon***',
	'F01CardExpCycleTime':'***Coming Soon***',
	'F01CardExpFaultLength':'***Coming Soon***',
	'F01CardExpExtFaultLength':'***Coming Soon***',
	'F01CardExpWithinTolerance':'***Coming Soon***',
	'F01CardExpOutsideTolerance':'***Coming Soon***',
	'F01CardExpCurrentProduction':'***Coming Soon***',
	'F01CardExpFillStatistic':'***Coming Soon***',
	'F01CardExpRDCenter':'***Coming Soon***',
	'F01CardExpGlobalAcceleration':'***Coming Soon***',
	'F01CardExpGlobalFrequency':'***Coming Soon***',
	'F01CardExpGlobalAmplitude':'***Coming Soon***',
	'F01CardExpMazAccelZAxis':'***Coming Soon***',
	'F01CardExpMaxHAxisRmsAccelBearingSensors':'***Coming Soon***',
	'F01CardExpMaxRmsAccelBearingSensors':'***Coming Soon***',
	'F01CardExpMaxAxialRmsAccelBearingSensors':'***Coming Soon***',
	'F01CardExpMAxBearingTemperature':'***Coming Soon***',
	'F01CardExpSensorMovement':'***Coming Soon***',
	'F01CardExpAccelerationLocal':'***Coming Soon***',
	'F01CardExpAcceleration':'***Coming Soon***',
	'F01CardExpAxisAcceleration':'***Coming Soon***',
	'F01CardExpLocalRotationFrequency':'***Coming Soon***',
	'F01CardExpLocalAmplitude':'***Coming Soon***',
	'F01CardExpDisplacement':'***Coming Soon***',
	'F01CardExpPhaseAngle':'***Coming Soon***',
	'F01CardExpMotionAngle':'***Coming Soon***',
	'F01CardExpSensorTemperature':'***Coming Soon***',
	'F01CardExpBagQuality':'***Coming Soon***',
	'F01CardExpFaults':'***Coming Soon***',
	'F01CardExpBunChangeAbs':'***Coming Soon***',
	'F01CardExpBunChangeToday':'***Coming Soon***',
	'F01CardExpBagsPerBundle':'***Coming Soon***',
	'F01CardExpTypeChanges':'***Coming Soon***',
	'F01CardExpSortChangeToday':'***Coming Soon***',
	'F01CardExpCyclesToday':'***Coming Soon***',
	'F01CardExpMetalDetectorToday':'***Coming Soon***',
	'F01CardExpIncorrectBags':'***Coming Soon***',
	'F01CardExpOpenBags':'***Coming Soon***',
	'F01CardExpSampleBags':'***Coming Soon***',
	'F01CardExpRejectionsToday':'***Coming Soon***',
	'F01CardExpContainer':'***Coming Soon***',
	'F01CardExpEmptyRejects':'***Coming Soon***',
	'F01CardExpFullRejects':'***Coming Soon***',
	'F01CardExpProductionRate':'***Coming Soon***',
	'F01CardExpDailyProduction':'***Coming Soon***',
	'F01CardExpFillingStation':'***Coming Soon***',
	'F01CardExpPalletizer':'***Coming Soon***',
	'F01CardExpEmptyPalArea':'***Coming Soon***',
	'F01CardExpBagTransArea':'***Coming Soon***',
	'F01CardExpFullPalArea':'***Coming Soon***',
	'F01CardExpSheetDispArea':'***Coming Soon***',
	'F01CardExpProductonTime':'***Coming Soon***',
	'F01CardExpProgramChngsToday':'***Coming Soon***',
	'F01CardExpEndofBatch':'***Coming Soon***',
	'F01CardExpEmptyPallets':'***Coming Soon***',
	'F01CardExpBagProduction':'***Coming Soon***',
	'F01CardExpPalletProduction':'***Coming Soon***',
	'F01CardExpFSDifference':'***Coming Soon***',
	'F01CardExpProduct':'***Coming Soon***',
	'F01CardExpCapacity':'***Coming Soon***',
	'F01CardExpUndersized':'***Coming Soon***',
	'F01CardExpOversized':'***Coming Soon***',
	'F01CardExpSpeed':'***Coming Soon***',
	'F01CardExpInclination':'***Coming Soon***',
	'F01CardExpBoardHeigh':'***Coming Soon***',
	'F01CardExpMoisture':'***Coming Soon***',
	'F01CardExpWaterAdd':'***Coming Soon***',
	'F01CardExpOpMode':'***Coming Soon***',
	'F01CardExpOpHours':'***Coming Soon***',
	'F01CardExpTemp':'***Coming Soon***',
	'F01CardExpPowerInput':'***Coming Soon***',
	'F01CardExpFullPalletCount':'***Coming Soon***',
	'F01CardExpLoadEmptyPallets':'***Coming Soon***',
	'F01CardExpOeeValue':'***Coming Soon***',
	'F01CardExpOeeAvailable':'***Coming Soon***',
	'F01CardExpOeePerformance':'***Coming Soon***',
	'F01CardExpOeeQuality':'***Coming Soon***',
	'F01CardExpOeeDowntime':'***Coming Soon***',
	'F01CardExpOperatingFactor':'***Coming Soon***',
	'F01CardExpThroughput':'***Coming Soon***',
	'F01CardExpOeeRejects':'***Coming Soon***',
	'F01CardExpMachineDetailPage':'***Coming Soon***',
	'F01CardExpReportSettingsPage':'***Coming Soon***',
	'F01CardExpReportOverviewPage':'***Coming Soon***',
	'F01CardExpCompanyOverviewPage':'***Coming Soon***',
	'F01CardExpAlertSettingsPage':'***Coming Soon***',
	'F01CardExpMsgAdminPage':'***Coming Soon***',
	'F01CardExpUserRolesPage':'***Coming Soon***',
	'F01CardExpCompaniesPage':'***Coming Soon***',
	'F01CardExpDummyMachinePage':'***Coming Soon***',
	'F01CardExpPermissionsPage':'***Coming Soon***',
	'F01CardExpMaintenanceAidPage':'***Coming Soon***',
	'F01CardExpMachinePage':'***Coming Soon***',
	'F01CardExpLogBookTab':'***Coming Soon***',
	'F01CardExpServiceRequestTab':'***Coming Soon***',
	'F01CardExpMessagesTab':'***Coming Soon***',
	'F01CardExpDocumentSTab':'***Coming Soon***',
	'F01CardExpOeeTab':'***Coming Soon***',
	'F01CardExpMaintenanceTab':'***Coming Soon***',
	'F01CardExpMachineSpecificTab':'***Coming Soon***',
	'F01CardExpGeneralTab':'***Coming Soon***',
	'F01CardExpGoodPerformance':'***Coming Soon***',
	'F01CardExpWrongApplBags':'***Coming Soon***',
	'F01CardExpReelChanges':'***Coming Soon***',
	'F01CardExpTypeCurrent':'***Coming Soon***',
	'F01CardExpInterruptsFeigeGeneral':'***Coming Soon***',
	'F01CardExpInterruptsAdams':'***Coming Soon***',
	'F01CardExpInterruptsISF':'***Coming Soon***',
	'F01CardExpInterruptsIVT':'***Coming Soon***',
	'F01CardExpInterruptsMiniAdams':'***Coming Soon***',
	'F01CardExpInterruptsRVT':'***Coming Soon***',
	'F01CardExpInterruptsNewtecTerram':'***Coming Soon***',
	'A-AlertTip-main_acc-ORANGE':'Alerta Principal de Aceleração Laranja',
	'A-AlertTip-main_acc-YELLOW':'Alerta Amarelo de Aceleração Principal',
	'A-AlertTip-main_acc-RED':'Alerta Vermelho de Aceleração Principal',
	'A-AlertTip-u8MachAvail-AVAIL':'Alerta de disponibilidade',
	'A905MachineNameNotAllowedCharacter':'Os seguintes caracteres não são permitidos: !, @, #, $, %, ^, &, *, (, ), [, ], {, }, |, ~, \\, =, +, ,, ;, <, >, ?, /',
	'A1000ReportSystemMessage':'undefined',
	'A1001ReportSystemMessageOffline':'undefined',
	'A1002ReportOverviewHeaderOrder':'undefined',
	'A1003ReportSystemMessageCardNotForReportType':'undefined',
};